import { gql } from 'apollo-angular';

import { Query } from './index';
import { Document } from './document';
import { User } from './user';
import { Project } from './project';
import { Contact } from './contact';

// same content as in fragment UserData on User
const companyUser = `
  id
  address
  address2
  firstName
  lastName
  email
  phone
  mobilePhone
  costTypeId
  city
  cityCode
  type
  color
  relative1name
  relative1phone
  relative2name
  relative2phone
`;

export const refetchQueries = {
  companyUser,
};

// GQL FRAGMENTS
const COMPANY_COMMON = gql`
  fragment CompanyCommon on Company {
    id
    address
    address2
    betalningsvillkor
    city
    cityCode
    faxNr
    interest
    mail
    name
    orgNr
    phone
    plusGiro
    postGiro
    iban
    bicSwift
    webb
  }
`;

/** Export GQL Fragments on Company */
export const Company = {
  fragments: {
    common: COMPANY_COMMON,
  },
};

// GQL Queries

const COMPANY_CUSTOMER_STATUS = {
  query: gql`
    query fetchCompanyCustomerStatus {
      company {
        id
        foretagsfakta {
          id
          status
        }
      }
    }
  `,
};

const COMPANY_DOCUMENTS = {
  query: gql`
    query fetchUserDocuments($status: [Int]) {
      company {
        id
        documents(status: $status) {
          edges {
            node {
              ...DocumentMetadata
              documentFolder {
                id
                name
                color
              }
            }
          }
        }
      }
    }
    ${Document.fragments.metadata}
  `,
  testVariables: {},
};

const COMPANY_APP_SETTINGS = {
  query: gql`
    query fetchCompanyAppSettings {
      company {
        id
        showProjectPicturesForAllUsersInApp
      }
    }
  `,
};

const COMPANY_APP_PARAMS = {
  query: gql`
    query fetchAppParrams {
      appParams {
        isDev
        newROTHandlingInvoiceId
        isAccountCreationAllowed
        country
        viewPrefix
        name
        appURLLogin
        appURL
        appURLText
        appURLCostumer
        appURLCostumerText
        supportPhone
        supportMail
        appLogoPath
        country
        getenvStruqturMode
        hyperionHomePage
        videoFastPresentation
      }
    }
  `,
};

const COMPANY_ATTENDANCE_TYPES = {
  query: gql`
    query fetchCompanyAttendanceTypes {
      company {
        id
        attendanceTypes_PreCompanyTypeHyperion {
          edges {
            node {
              code
              name
            }
          }
        }
      }
    }
  `,
};

const COMPANY_COST_TYPE = {
  query: gql`
    query fetchCompanyCostTypesQuery {
      company {
        id
        companyCostTypes {
          edges {
            node {
              id
              active
              isMaterial
              isUEmaterial
              isUEwork
              name
              procent
              unit
              accountingPlanAccount {
                id
                number
                description
                active
              }
              reverseVatAccountingPlanAccountId
            }
          }
        }
      }
    }
  `,
};

const COMPANY_FUNCTIONS = {
  query: gql`
    query companyFunctions {
      company {
        id
        functions {
          edges {
            node {
              function
            }
          }
        }
      }
    }
  `,
};

const COMPANY_INFO = {
  query: gql`
    query fetchCompanyInfo {
      company {
        ...CompanyCommon
        created
        startupSettingsAreDone
        remainingUsersInPlan
        showProductSearchPrices
        isMissingDeromeProductIntegration
        showProductExternalConnectInterface
        hasDeromeWebshopActivated
        hasBookkeepingIntegration
        bookkeepingIntegrationName
      }
    }
    ${Company.fragments.common}
  `,
};

const COMPANY_FORTNOX_SETTINGS = {
  query: gql`
    query fetchCompanyFortnoxSettings {
      company {
        ...CompanyCommon
        useFortnox_PreCompanyTypeHyperion
        fortnox {
          id
          fortnoxToken
        }
      }
    }
    ${Company.fragments.common}
  `,
};

const COMPANY_NOTICES = {
  query: gql`
    query fetchCompanyNotices {
      company {
        id
        companyUserGlobalMessages(last: 50) {
          edges {
            node {
              id
              body
              closedByUserId
              created
              sourceName_PreCompanyUserGlobalMessageTypeHyperion
              type
            }
          }
        }
      }
    }
  `,
};

const APPLICATION_MESSAGES = {
  query: gql`
    query fetchApplicationMessages($type: String, $userId: [Int]) {
      company {
        id
        companyUserGlobalMessages(type: $type, userId: $userId) {
          edges {
            node {
              id
              body
              closedByUserId
              messageRaw
              type
              userId
            }
          }
        }
      }
    }
  `,
  testVariables: {
    userId: [2],
    type: null,
  },
};

const COMPANY_INVOICE = {
  query: gql`
    query fetchInvoices($last: Int, $offset: Int) {
      company {
        ...CompanyCommon
        invoices(last: $last, offset: $offset) {
          totalCount
          edges {
            node {
              id
              contact_id
              fakturaNr
              projectId
              created
              status
              statusRot
              totalBox
              ffdatum
            }
          }
        }
      }
    }
    ${Company.fragments.common}
  `,
  testVariables: {
    last: 5,
    offset: 0,
  },
};

const COMPANY_PROJECTS: Query = {
  get query() {
    return gql`
      query fetchProjectsQuery($status: [Int]) {
        company {
          id
          projects(status: $status) {
            totalCount
            edges {
              node {
                ...ProjectBase
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: Project.fragments.base,
  testVariables: {
    status: [1],
  },
};

const COMPANY_PROJECT_WITH_DELETED = {
  query: gql`
    query fetchProjectsWithDeletedQuery($status: [Int]) {
      company {
        id
        projectsWithDeleted(status: $status) {
          totalCount
          edges {
            node {
              id
              status
              trueId
              created
              mark
              shortMessage
              offerId
              offertId
              color
              constructionSiteNumber
              invoiceData_PreProjectTypeHyperion {
                supplier {
                  rowsCountMoved
                  rowsCountUnMoved
                }
              }
              notInvoicedDaysCount
              endDate
              typeId
              daysFromActiveness_PreProjectTypeHyperion
              client
              establishment
              establishmentContact {
                id
                name
                address
              }
              clientContact {
                ...ClientContactBase
              }
              projectBookmarks {
                edges {
                  node {
                    id
                    userId
                    projectId
                  }
                }
              }
              todos {
                totalCount
                edges {
                  node {
                    id
                    projectId
                    description
                    done
                    usersTodoRelation {
                      edges {
                        node {
                          id
                          user {
                            ...UserNameFull
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {},
};

const COMPANY_ALL_PROJECTS = {
  query: gql`
    query fetchAllProjects {
      company {
        id
        projects {
          edges {
            node {
              id
              trueId
              mark
              status
            }
          }
        }
      }
    }
  `,
};

const COMPANY_DIVISION = {
  query: gql`
    query fetchCompanyDivisions {
      company {
        id
        divisions {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
};

const COMPANY_PROJECT_TYPE = {
  query: gql`
    query fetchCompanyProjectTypesQuery {
      company {
        id
        projectTypes {
          edges {
            node {
              id
              name
              prefix
              addToMeny
            }
          }
        }
      }
    }
  `,
};

const COMPANY_USER_COST_TYPE = {
  query: gql`
    query fetchCompanyUserCostTypesQuery {
      company {
        id
        useInternalCostAsDefaultProjectCost
        userCostTypes {
          edges {
            node {
              id
              active
              companyId
              cost
              internalCost
              name
              type
              parentId
              childCount
              showInApp
              accountingPlanAccount {
                id
                number
                description
                active
              }
              reverseVatAccountingPlanAccountId
            }
          }
        }
      }
    }
  `,
};

const COMPANY_NOTICEBOARDS = {
  query: gql`
    query fetchCompanyNoticeboards {
      company {
        id
        companyNotices_PreCompanyTypeHyperion(first: 50) {
          edges {
            node {
              head
              text
              created
              userId
              user {
                ...UserNameFull
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
};

const COMPANY_SETTINGS = {
  query: gql`
    query fetchCompanySettings {
      company {
        address
        address2
        city
        cityCode
        companyHourlyRateForCostumer
        companyMilageForCostumer
        companyMileCost
        companyPrivMileCost
        faxNr
        id
        mail
        mobilePhone
        name
        orgNr
        phone
        webb
      }
    }
  `,
};

const COMPANY_ALL_SETTINGS = {
  query: gql`
    query fetchCompanyAllSettings {
      company {
        ...CompanyCommon
        companyHourlyOverhead
        companyHourlyRateForCostumer
        companyInsurance
        companyMilageForCostumer
        companyMileCost
        companyPrivMileCost
        fTax
        mobilePhone
        skra
        socialInsurance
        standardInvoiceMailText
        standardOfferMailText
        trakDayPrice
        trakHalfDayPrice
        trakNightPrice
        weekendHourProcent
      }
    }
    ${Company.fragments.common}
  `,
};

const COMPANY_QUALITY_STAMP = {
  query: gql`
    query fetchCompanyQualityStamps {
      company {
        id
        qualityStamps {
          edges {
            node {
              id
              file
              url
              offer
              invoice
            }
          }
        }
      }
    }
  `,
};

const COMPANY_INVOICE_OFFER_SETTINGS = {
  query: gql`
    query fetchCompanyInvoiceOfferSettings {
      company {
        id
        betalningsvillkor
        interest
        plusGiro
        postGiro
        iban
        bicSwift
        companyInsurance
        fTax
        skra
        standardInvoiceMailText
        standardOfferMailText
        socialInsurance
        trakHalfDayPrice
        trakDayPrice
        trakNightPrice
        weekendHourProcent
      }
    }
  `,
};

const COMPANY_START_FIRST_SECTION = {
  query: gql`
    query fetchCompanyStartFirstSection {
      company {
        name
        logoUrl
        id
        showProjectPicturesForAllUsers
        startupSettingsAreDone
        companyNotices_PreCompanyTypeHyperion {
          edges {
            node {
              head
              text
              created
              id
              userId
              user {
                ...UserNameFull
              }
            }
          }
        }
        rssNewsFeed_PreCompanyTypeHyperion {
          edges {
            node {
              date
              permalink
              title
            }
          }
        }
        upcommingActivities_PreCompanyTypeHyperion {
          edges {
            node {
              date
              msg
              link
              other
              color
              trueProjectId
            }
          }
        }
        projectsCommets(last: 30) {
          edges {
            node {
              comment
              user {
                ...UserNameFull
              }
              created
              project {
                id
                trueId
              }
            }
          }
        }
        pictures(last: 30) {
          edges {
            node {
              id
              created
              user {
                ...UserNameFull
              }
              project {
                trueId
                id
              }
            }
          }
        }
        days(last: 30) {
          edges {
            node {
              user {
                ...UserNameFull
              }
              date
              hours
              doneWork
            }
          }
        }
      }
      me {
        id
      }
    }
    ${User.fragments.fullName}
  `,
};

const COMPANY_PRODUCTS = {
  query: gql`
    query fetchCompanyProducts($last: Int, $offset: Int, $filterParam: String) {
      company {
        id
        productsCount
        products(last: $last, offset: $offset, filterParam: $filterParam) {
          edges {
            node {
              id
              companyCostTypeId
              artnr
              benamning
              enhet
              avtalspris
              avtalsprisCost
              leverantor
              top
            }
          }
        }
      }
    }
  `,
  testVariables: {
    last: 5,
    offset: 0,
    filterParam: '',
  },
};

const COMPANY_PROJECT_PRODUCT = {
  query: gql`
    query fetchCompanyProduct($productId: Int) {
      company {
        id
        projectProducts_PreCompanyTypeHyperion(productId: $productId) {
          edges {
            node {
              id
              artnr
              benamning
              enhet
              antal
              avtalspris
              avtalsprisCost
              totalAmount
              leverantor
              created
              date
              source
              sourceId
              user {
                ...UserNameFull
              }
              project {
                id
                trueId
                mark
                clientContact {
                  ...ClientContactBase
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {},
};

const COMPANY_USERS: Query = {
  get query() {
    return gql`
      query fetchCompanyUsers {
        company {
          id
          users {
            totalCount
            edges {
              node {
                ...UserData
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: User.fragments.data,
};

const COMPANY_PICTURES = {
  query: gql`
    query fetchCompanyPictures {
      company {
        id
        pictures(last: 50) {
          totalCount
          edges {
            node {
              created
              id
              projectId
              picture
              url
              project {
                id
                trueId
              }
              user {
                ...UserNameFull
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
};

const COMPANY_SHOW_PICTURES = {
  query: gql`
    query fetchShowPictures {
      company {
        id
        showProjectPicturesForAllUsers
      }
    }
  `,
};

const COMPANY_START_MAILS = {
  query: gql`
    query fetchCompanyMails($userId: [Int]) {
      company {
        id
        mails(last: 15, userId: $userId) {
          edges {
            node {
              id
              created
              display
              email
              mandrillInfo
              message
              replyEmail
              translatedState
              userId
            }
          }
        }
      }
    }
  `,
  testVariables: {
    userId: [2],
  },
};

const COMPANY_TODO_TOPICS = {
  query: gql`
    query fetchCompanyTodoTopics {
      company {
        id
        todotopics {
          edges {
            node {
              id
              Name
              active
            }
          }
        }
      }
    }
  `,
};

const COMPANY_LOGS = {
  query: gql`
    query companyLogs {
      company {
        id
        companyLogs {
          edges {
            node {
              id
              model
              created
              message
              user {
                ...UserNameFull
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
};

const SINGLE_COMPANY_CONTACT = {
  query: gql`
    query fetchSingleCompanyContacts($id: Int) {
      company {
        id
        contacts(id: $id) {
          edges {
            node {
              id
              trueId
              orderBuisnessName
              orgNr
              name
              address
              address2
              cityCode
              city
              phone
              mobilePhone
              mail
              mailInvoice
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

/** EXPORTS */

export const queries = {
  applicationMessages: APPLICATION_MESSAGES,
  companyAllProjects: COMPANY_ALL_PROJECTS,
  companyAllSettings: COMPANY_ALL_SETTINGS,
  companyAppParams: COMPANY_APP_PARAMS,
  companyAppSettings: COMPANY_APP_SETTINGS,
  companyAttendanceTypes: COMPANY_ATTENDANCE_TYPES,
  companyCostType: COMPANY_COST_TYPE,
  companyCustomerStatus: COMPANY_CUSTOMER_STATUS,
  companyDivision: COMPANY_DIVISION,
  companyDocuments: COMPANY_DOCUMENTS,
  companyFortnoxSettings: COMPANY_FORTNOX_SETTINGS,
  companyFunctions: COMPANY_FUNCTIONS,
  companyInfo: COMPANY_INFO,
  companyInvoice: COMPANY_INVOICE,
  companyInvoiceOfferSettings: COMPANY_INVOICE_OFFER_SETTINGS,
  companyLogs: COMPANY_LOGS,
  companyNoticeboard: COMPANY_NOTICEBOARDS,
  companyNotices: COMPANY_NOTICES,
  companyPictures: COMPANY_PICTURES,
  companyProducts: COMPANY_PRODUCTS,
  companyProjectProduct: COMPANY_PROJECT_PRODUCT,
  companyProjects: COMPANY_PROJECTS,
  companyProjectsWithDeleted: COMPANY_PROJECT_WITH_DELETED,
  companyProjectType: COMPANY_PROJECT_TYPE,
  companyQualitystamp: COMPANY_QUALITY_STAMP,
  companySettings: COMPANY_SETTINGS,
  companyShowPictures: COMPANY_SHOW_PICTURES,
  companyStartFirstSection: COMPANY_START_FIRST_SECTION,
  companyStartMails: COMPANY_START_MAILS,
  companyTodoTopics: COMPANY_TODO_TOPICS,
  companyUserCostType: COMPANY_USER_COST_TYPE,
  companyUsers: COMPANY_USERS,
  singleCompanyContact: SINGLE_COMPANY_CONTACT,
};
