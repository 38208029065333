import { EventTypeEnum, ExportPaperSizeEnum } from './planner-module-enums';
import { ProjectTodo } from './services/planner-query-types';

export interface SchedulerEvent {
  id?: number | string;
  start_date: string | Date;
  end_date: string | Date;
  realStartDate?: string;
  realEndDate?: string;
  text: string;
  color: string | null;
  projectColor?: string | null;
  textColor: string | null;
  progress?: number;
  duration?: number;
  type?: string;
  eventType: EventTypeEnum;
  render?: string;
  open?: boolean;
  projectId?: number;

  event_pid?: number | string;
  event_length?: number | string;
  rec_type?: string;
}

export interface SchedulerEventPlannedTime extends SchedulerEvent {
  projectId: number;
  userId: number;
  todoId: number;
  parent?: number;
}

export interface SchedulerEventProject extends SchedulerEvent {
  trueId: string;
}

export interface SchedulerEventWorkTask extends SchedulerEvent {
  parent: number;
  projectId: number;
  headerId: number;
  estimatedTime: number;
  done: number;
}

export interface LightboxDropdown {
  value: number | string;
  label: number | string;
}

export interface ProjectTodoMaper {
  projectId: number;
  todos: ProjectTodo[];
}

export interface ContextMenuEvent {
  id: number;
  schedulerEvent: SchedulerEventProject | SchedulerEventPlannedTime;
  event: any;
}

export interface UpdateColorEvent {
  id: number;
  color: string;
  isProject: boolean;
}

export interface GanttData {
  data: SchedulerEvent[];
  links: GanttLink[];
}

export interface GanttLink {
  id: number;
  source: number;
  target: number;
  type: string;
}

export enum ExportFileFormat {
  PDF = 'PDF',
  PNG = 'PNG',
}

export interface ExportProjectPlannerEvent {
  start: string;
  end: string;
  format: ExportPaperSizeEnum;
  landscape: boolean;
  fileFormat: ExportFileFormat;
}

export interface ResourcesData {
  projects: any[];
  users: any[];
  plannedWork: any[];
  todos: any[];
  data: any[];
}
