<div class="page-container p-grid">
  <h1 class="p-col-12 p-m-0">ROT-uppgifter</h1>
  <h3 class="p-col-12 p-m-0">
    Uppgifterna importeras till faktura samt underlag till skatteverket
  </h3>
  <p-card
    *ngFor="let form of forms; let index = index"
    [header]="'Beställare ' + (index + 1)"
    class="p-xxl-4 p-lg-6 p-col-12"
  >
    <form class="p-grid p-mt-2" [formGroup]="form" (submit)="submit(form)">
      <input [hidden]="true" formControlName="id" />
      <span class="p-col-6">
        <label>Namn</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-user"></i>
          </span>
          <input #name pInputText type="text" formControlName="name" />
        </span>
        <label
          *ngIf="!form.controls.name.valid && form.controls.name.touched"
          class="p-danger p-mt-1"
          >Krävs</label
        >
      </span>
      <span class="p-col-6">
        <label>Personnummer</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-hashtag"></i>
          </span>
          <input pInputText type="text" formControlName="personalNumber" />
        </span>
        <label
          *ngIf="
            !form.controls.personalNumber.valid &&
            form.controls.personalNumber.touched
          "
          class="p-danger p-mt-1"
          >Ange personnummer med 10 eller 12 siffror</label
        >
      </span>
      <span class="p-col-6">
        <label>Fastighetsbeteckning</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-home"></i>
          </span>
          <input pInputText type="text" formControlName="nameOfProperty" />
        </span>
      </span>
      <span class="p-col-6">
        <label>BRF OrgNr</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-briefcase"></i>
          </span>
          <input
            pInputText
            type="text"
            formControlName="housingAssociationOrgNumber"
          />
        </span>
        <label
          *ngIf="
            !form.controls.housingAssociationOrgNumber.valid &&
            form.controls.housingAssociationOrgNumber.touched
          "
          class="p-danger p-mt-1"
          >Ange OrgNr med 10 tecken till 11 tecken</label
        >
      </span>
      <span class="p-col-6">
        <label>Lägenhetens beteckning</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-key"></i>
          </span>
          <input
            pInputText
            type="text"
            formControlName="apartmentDesignation"
          />
        </span>
      </span>
      <span class="p-col-6">
        <label>Maximal skattereduktion</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-wallet"></i>
          </span>
          <input
            pInputText
            type="number"
            formControlName="customerWantedWorkAmount"
          />
        </span>
        <label
          *ngIf="
            !form.controls.customerWantedWorkAmount.valid &&
            form.controls.customerWantedWorkAmount.touched
          "
          class="p-danger p-mt-1"
          >Max 50 000 kr för ROT, 75 000 kr för RUT</label
        >
      </span>
      <span class="p-col-12 p-d-flex p-jc-end">
        <button
          pButton
          class="p-button-primary-hyperion"
          icon=""
          [icon]="form.value.id ? 'pi pi-save' : 'pi pi-plus'"
          [label]="form.value.id ? 'Spara' : 'Lägg till'"
          type="submit"
        ></button>
        <button
          (click)="submit(form, true)"
          type="button"
          pButton
          class="p-button-icon-danger p-ml-2"
          icon="pi pi-trash"
        ></button>
      </span>
    </form>
  </p-card>
</div>
