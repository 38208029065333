<p-dialog
  [(visible)]="showProjectDialog"
  header="Hantera projekttyper"
  [modal]="true"
  [dismissableMask]="true"
>
  <app-project-type-form
    (projectTypesUpdateEvent)="onProjectTypesUpdate($event)"
  ></app-project-type-form>
</p-dialog>
<p-dialog
  [(visible)]="showCreateClientDialog"
  header="Skapa kund"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [dismissableMask]="true"
>
  <app-contact-form
    [context]="contactFormContext.ProjectClient"
    (contactUpdatedEvent)="onClientCreated($event)"
  >
  </app-contact-form>
</p-dialog>
<p-dialog
  [(visible)]="showCreateEstablishmentDialog"
  header="Arbetsställe"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [dismissableMask]="true"
>
  <app-contact-form
    [context]="contactFormContext.ProjectEstablishment"
    (contactUpdatedEvent)="onEstablishmentCreated($event)"
  >
  </app-contact-form>
</p-dialog>

<h1 *ngIf="!isEdit && !parentId">Skapa projekt</h1>
<h1 *ngIf="!isEdit && parentId">Skapa underprojekt</h1>
<h1 *ngIf="isEdit">Redigera projekt</h1>
<form [formGroup]="projectForm" class="p-grid" (ngSubmit)="onSubmit()">
  <div [class]="isProjectColorEnabled ? 'p-col-10' : 'p-col-12'">
    <label>Märkning</label>
    <div id="mark" class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-home"></i>
      </span>
      <input
        type="text"
        pInputText
        placeholder="Renovering"
        [formControl]="projectForm.controls['mark']"
      />
    </div>
  </div>

  <div *ngIf="isProjectColorEnabled" class="p-col-2">
    <label>Projektfärg</label>
    <p-dropdown
      name="projectColor"
      [options]="projectColors"
      [formControl]="projectForm.controls['projectColor']"
      optionLabel="value"
      optionValue="value"
      placeholder="Välj färg"
      ><ng-template let-option pTemplate="item">
        <app-project-color
          [color]="option.value"
          style="width: 2rem"
        ></app-project-color>
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
        <app-project-color
          [color]="option.value"
          style="width: 2rem"
        ></app-project-color>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="useProjectTypes" class="p-col-6">
    <label>Typ</label>
    <div class="p-inputgroup">
      <p-dropdown
        name="projectType"
        [options]="projectTypesDropdownOptions"
        optionLabel="name"
        optionValue="value"
        [formControl]="projectForm.controls['projectType']"
      ></p-dropdown>
      <button
        (click)="editTypes()"
        class="p-inputgroup-addon"
        type="button"
        pButton
        icon="pi pi-pencil"
      ></button>
    </div>
  </div>

  <div
    *ngIf="projectForm.controls['status'].value !== PROJECT_STATUS.INHOUSE"
    [class]="useProjectTypes ? 'p-col-6' : 'p-col-12'"
  >
    <label>Status</label>
    <p-dropdown
      name="status"
      [formControl]="projectForm.controls['status']"
      [options]="projectStatus"
    ></p-dropdown>
  </div>

  <div class="p-col-12" *ngIf="!isEdit">
    <label>Kund</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-search"></i>
      </span>
      <app-contact-select
        name="customer"
        ngDefaultControl
        [selectedContact]="projectForm.value.clientContact"
        (selectedContactEvent)="onSelectedClientContact($event)"
      >
      </app-contact-select>
      <button
        type="button"
        (click)="createClientDialog()"
        class="p-inputgroup-addon p-button-primary-hyperion"
        pButton
        icon="pi pi-plus"
      ></button>
    </span>
  </div>

  <div class="p-col-12" *ngIf="!isEdit">
    <label>Arbetsställe</label>
    <span class="p-inputgroup">
      <span
        class="p-inputgroup-addon"
        [ngClass]="copyCustomer ? 'disabled' : ''"
      >
        <i class="pi pi-search"></i>
      </span>
      <app-contact-select
        ngDefaultControl
        [selectedContact]="
          copyCustomer
            ? projectForm.value.clientContact
            : projectForm.value.establishmentContact
        "
        (selectedContactEvent)="onSelectedEstablishmentContact($event)"
        [disabled]="copyCustomer"
      ></app-contact-select>
      <button
        type="button"
        (click)="createEstablishmentDialog()"
        class="p-inputgroup-addon p-button-primary-hyperion"
        [disabled]="copyCustomer"
        pButton
        icon="pi pi-plus"
      ></button>
    </span>
  </div>

  <div class="p-col-12" *ngIf="!isEdit">
    <p-inputSwitch
      name="copyCustomer"
      class="p-mr-2"
      [(ngModel)]="copyCustomer"
      [ngModelOptions]="{ standalone: true }"
    ></p-inputSwitch>
    <label>Kopiera arbetsställe från kund</label>
  </div>

  <p-divider></p-divider>

  <div class="p-col-6">
    <label>Startdatum</label>
    <p-calendar
      [formControl]="projectForm.controls['startDate']"
      firstDayOfWeek="1"
      dateFormat="yy-mm-dd"
      dataType="string"
      [showIcon]="true"
      inputId="icon"
      [showWeek]="true"
    ></p-calendar>
  </div>
  <div class="p-col-6">
    <label>Slutdatum</label>
    <p-calendar
      [formControl]="projectForm.controls['endDate']"
      firstDayOfWeek="1"
      dateFormat="yy-mm-dd"
      dataType="string"
      [showIcon]="true"
      inputId="icon"
      [showWeek]="true"
    ></p-calendar>
  </div>

  <div class="p-col-6">
    <label>Offererat Arbete</label>
    <div class="p-inputgroup">
      <input
        type="number"
        pInputText
        [formControl]="projectForm.controls['offertSumWork']"
        placeholder="0"
      />
      <span class="p-inputgroup-addon"> kr </span>
    </div>
  </div>

  <div class="p-col-6">
    <label>Offererat Material</label>
    <div class="p-inputgroup">
      <input
        type="number"
        pInputText
        [formControl]="projectForm.controls['offertSum']"
        placeholder="0"
      />
      <span class="p-inputgroup-addon"> kr </span>
    </div>
  </div>

  <div class="p-col-12">
    <label>Knyt till offert</label>
    <p-dropdown
      filter="true"
      filterBy="trueId,concerning"
      [options]="offers"
      optionValue="id"
      [formControl]="projectForm.controls['offerId']"
      placeholder="Välj offert"
    >
      <ng-template pTemplate="item" let-item>
        {{ item.trueId }}
        {{ item.concerning ? ' ' + item.concerning : '' }}
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item>
        {{ item.trueId }}
        {{ item.concerning ? ' ' + item.concerning : '' }}
      </ng-template>
    </p-dropdown>
  </div>

  <div class="p-col-12" *ngIf="parentId">
    <label>Markera som ÄTA</label>
    <p-inputSwitch
      name="isAta"
      class="p-mr-2"
      formControlName="isAta"
    ></p-inputSwitch>
  </div>

  <div class="p-col-12">
    <p-accordion expandIcon="pi pi-fw pi-plus" collapseIcon="pi pi-fw pi-minus">
      <p-accordionTab [(selected)]="moreIsExpanded" header="Mer">
        <div class="p-mt-3">
          <label>Vår Referens</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <p-dropdown
              placeholder="Välj användare"
              [options]="userOptions"
              [formControl]="projectForm.controls['madeBy']"
              [editable]="true"
            ></p-dropdown>
          </div>
        </div>

        <div class="p-mt-3">
          <app-project-external-dropdown
            *ngIf="
              showProductExternalConnectInterface &&
              userFlags &&
              (userFlags.isAdmin || userFlags.isForeman)
            "
            [disableOnSelect]="true"
            [projectId]="projectData.id"
            [preSelectedExternalProject]="currentExternalProject"
            [projectExternalSource]="'derome-api'"
            [type]="'simple'"
            (externalProjectConnectionChanged)="setExternalProject($event)"
          >
          </app-project-external-dropdown>
        </div>

        <div class="p-mt-3">
          <label>Byggarbetsplats ID</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-home"></i>
            </span>
            <input
              type="text"
              [formControl]="projectForm.controls['constructionSiteNumber']"
              pInputText
              placeholder=""
            />
          </div>
        </div>

        <div class="p-mt-3">
          <label>Kundens Ordernr</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-shopping-bag"></i>
            </span>
            <input
              type="text"
              [formControl]="projectForm.controls['orderNumber']"
              pInputText
              placeholder=""
            />
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div class="p-col-12" *ngIf="!isEdit">
    <p-accordion expandIcon="pi pi-fw pi-plus" collapseIcon="pi pi-fw pi-minus">
      <p-accordionTab
        [(selected)]="defaultTodoIsExpanded"
        header="Standardarbetsmoment"
      >
        <p-dialog
          [(visible)]="showCreateDefaultTodo"
          modal="true"
          dismissableMask="true"
          header="Skapa standardmoment"
        >
          <app-project-todo-form
            style="max-width: 50vw"
            class="p-d-block p-p-2"
            [isCreateDefault]="true"
            (updateEmit)="getDefaultTodos(); showCreateDefaultTodo = false"
          ></app-project-todo-form>
        </p-dialog>
        <p-dialog
          [(visible)]="showEditDefaultTodo"
          modal="true"
          dismissableMask="true"
          header="Redigera standardmoment"
        >
          <app-project-todo-form
            style="max-width: 50vw"
            class="p-d-block p-p-2"
            [todoIdAsync]="defaultTodoId"
            [isEditAsync]="defaultTodoEdit"
            [isCreateDefault]="true"
            (updateEmit)="getDefaultTodos(); showEditDefaultTodo = false"
          ></app-project-todo-form>
        </p-dialog>
        <p-table
          [value]="defaultTodos"
          rowGroupMode="subheader"
          groupRowsBy="topic.Name"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Beskrivning</th>
              <th [style.width]="'6rem'"></th>
              <th [style.width]="'4rem'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="groupheader" let-todo>
            <tr pRowGroupHeader>
              <td colspan="3" class="p-text-bold">{{ todo.topic.Name }}</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-todo>
            <tr>
              <td class="p-text-truncate">{{ todo.description }}</td>
              <td class="p-text-center">
                <span class="p-d-flex p-jc-between">
                  <button
                    pButton
                    class="p-button-text legacy-rounded-button"
                    icon="pi pi-pencil"
                    type="button"
                    (click)="editDefaultTodo(todo.id)"
                  ></button>
                  <button
                    pButton
                    class="p-button-text legacy-rounded-button p-button-icon-danger"
                    icon="pi pi-trash"
                    type="button"
                    (click)="deleteDefaultTodo(todo.id)"
                  ></button>
                </span>
              </td>
              <td class="p-text-center">
                <p-checkbox
                  [disabled]="todo.defaultForAllProjects === 1"
                  [formControl]="projectForm.controls['defaultTodos']"
                  [value]="todo.id"
                  [pTooltip]="
                    todo.defaultForAllProjects === 1
                      ? 'Standard för alla projekt'
                      : ''
                  "
                ></p-checkbox>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <span class="p-d-flex p-jc-between">
          <button
            pButton
            class="p-button-primary-hyperion"
            label="Skapa"
            icon="pi pi-plus"
            type="button"
            (click)="showCreateDefaultTodo = true"
          ></button>
        </span>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div class="p-d-flex p-jc-end p-col-12">
    <button
      *ngIf="!isEdit"
      pButton
      class="p-button-primary-hyperion"
      label="Skapa"
      [icon]="!loading ? 'pi pi-plus' : 'pi pi-spin pi-spinner'"
      type="submit"
      [disabled]="loading"
    ></button>
    <button
      *ngIf="isEdit"
      pButton
      class="p-button-primary-hyperion"
      label="Spara"
      [icon]="!loading ? 'pi pi-save' : 'pi pi-spin pi-spinner'"
      type="submit"
      [disabled]="loading"
    ></button>
  </div>
</form>
