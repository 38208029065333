import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ActiveItemLabelService } from 'app/header/active-item-label.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  auditTime,
  BehaviorSubject,
  map,
  filter,
  Subject,
  Subscription,
} from 'rxjs';
import { ConfigService } from 'app/shared/config/config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public title = new BehaviorSubject<string>('');
  public icon: string;
  public appName: string;
  public supportMail: string;
  public supportPhone: string;
  public invertButton: boolean;
  private activeItem$: Subscription;
  private lastPosition = 0;
  public headerTextAsync = new BehaviorSubject<string>('');
  private newPosition = new Subject<number>();
  private newPosition$: Subscription;
  private handleTitleChangeOnReusableComponent$: Subscription;

  @Input()
  public set headerText(text: string) {
    this.headerTextAsync.next(text);
  }

  @Input() headerTemplate!: TemplateRef<any>;

  constructor(
    public activeItemLabelService: ActiveItemLabelService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private titleService: Title
  ) {}

  @HostBinding('class') public classlist = 'scroll-up';

  @HostListener('window:scroll', ['$event'])
  public onScroll(): void {
    this.newPosition.next(window.scrollY);
  }

  public ngOnInit(): void {
    const config = this.configService.getConfig();
    this.appName = config.title;
    this.supportPhone = config.supportPhone;
    this.supportMail = config.supportMail;
    this.invertButton = config.theme.invert;

    const titleSuffix = ` | ${this.appName}`;

    this.title.subscribe(title =>
      this.titleService.setTitle(title + titleSuffix)
    );
    this.headerTextAsync.subscribe(text =>
      this.titleService.setTitle(text + titleSuffix)
    );

    const { label, icon } = this.route.snapshot.data;
    const { label: parentLabel, icon: parentIcon } =
      this.route.snapshot.parent.data;

    this.title.next(parentLabel || label);

    this.activeItem$ = this.activeItemLabelService.activeItem.subscribe(
      data => {
        this.title.next(
          data ? `${data.id} ${data.name ?? ''}` : parentLabel || label
        );
      }
    );

    this.icon = parentIcon || icon;

    this.newPosition$ = this.newPosition
      .pipe(
        map(pos => (pos < 0 ? 0 : pos)),
        auditTime(100)
      )
      .subscribe(position => {
        if (position === 0) {
          this.classlist = 'scroll-up';
        } else if (this.lastPosition > position) {
          this.classlist = 'scroll-up';
        } else if (this.lastPosition < position) {
          this.classlist = 'scroll-down';
        }
        this.lastPosition = position;
      });

    this.handleTitleChangeOnReusableComponent$ =
      this.handleTitleChangeOnReusableComponent();
  }

  private handleTitleChangeOnReusableComponent(): Subscription {
    return this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const urlPath = event.url.split('/')[1];
        const [routerConfig] = this.router.config;
        const route = routerConfig?.children?.find(
          route => route.path === urlPath
        );

        if (!route || !route?.data?.label) {
          return;
        }

        const { label } = route.data;

        this.title.next(label);
      });
  }

  public ngOnDestroy(): void {
    this.activeItem$.unsubscribe();
    this.newPosition$.unsubscribe();
    this.handleTitleChangeOnReusableComponent$.unsubscribe();
  }
}
