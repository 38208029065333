import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAttendanceTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAttendanceTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, dayAttendanceTypesWithDeleted?: { __typename?: 'DayAttendanceTypeConnection', edges?: Array<{ __typename?: 'DayAttendanceTypeEdge', node?: { __typename?: 'DayAttendanceType', id: string, code?: string | null, description?: string | null, name?: string | null, showInApp?: boolean | null, isDeleted?: boolean | null, userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export type DayAttendanceTypeFragment = { __typename?: 'DayAttendanceType', id: string, code?: string | null, description?: string | null, name?: string | null, showInApp?: boolean | null, isDeleted?: boolean | null, userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string } | null } | null> | null } | null };

export type GetCompanyCostTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyCostTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string, name?: string | null, active?: number | null, parentId?: number | null } | null } | null> | null } | null } | null };

export const DayAttendanceTypeFragmentDoc = gql`
    fragment DayAttendanceType on DayAttendanceType {
  id
  code
  description
  name
  description
  showInApp
  isDeleted
  userCostTypes {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const GetAttendanceTypesDocument = gql`
    query getAttendanceTypes {
  company {
    id
    dayAttendanceTypesWithDeleted {
      edges {
        node {
          ...DayAttendanceType
        }
      }
    }
  }
}
    ${DayAttendanceTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAttendanceTypesGQL extends Apollo.Query<GetAttendanceTypesQuery, GetAttendanceTypesQueryVariables> {
    document = GetAttendanceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompanyCostTypesDocument = gql`
    query getCompanyCostTypes {
  company {
    id
    userCostTypes {
      edges {
        node {
          id
          name
          active
          parentId
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanyCostTypesGQL extends Apollo.Query<GetCompanyCostTypesQuery, GetCompanyCostTypesQueryVariables> {
    document = GetCompanyCostTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }