/*
 *
object structure
   formFields = {
    model: 'Person',
    nestedForm: true, // optional only when its form array
    attributes: {
      name: null,
      address: [{name: '', age: ''}] // array
    },
    attrToExcludeFromRulesAndLabel: [] // optional
  }


  1- CREATE FORM
      - Group form (both basic form and form with form array is put into considration)
      - Set value to form if values are included
      - Check if the LABELS & RULES should be appended to the form
        - if LABELS & RULES proceed to CLIENT VALIDATION
        - if NOT return Grouped Form

  2- CLIENT VALIDATION
      - Get LABEL & RULES from server (or chached 99.9% of the times)
      - Append form with LABEL & RULES
      - Subscribe to changes on form and display error when field is invalid

  3- SUBMISSION
      - CLIENT CHECK
        - Check error on client with setErrorFlag() method (Incase any error message is missed)
          - Show error message on client through the fields and global message service
        - If no error go ahead to server check

      - Response CHECK
        - Data is sent to server
        -  If there are errors from the server then showServerErrors() method is called to display the errors
 *
 */

import { Injectable, OnDestroy } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  AbstractControl,
  FormControl,
  ValidatorFn,
} from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';

import { LabelsService } from '../labels/labels.service';
import { HyperionErrorHandler } from 'app/shared/errors';
import { ButtonsAsync } from './buttons-async';
import { ExtendedFormGroup } from './extended-form-group';

@Injectable({
  providedIn: 'root',
})
export class FormHandlerService implements OnDestroy {
  constructor(
    protected labelsService: LabelsService,
    protected formBuilder: FormBuilder,
    private hyperionErrorHandler: HyperionErrorHandler
  ) {}

  formSubs: any = null;
  public apendToForm = ['labels', 'rules'];
  labels = {
    create: 'Skapa',
    read: 'Läs in',
    update: 'Uppdatera',
    delete: 'Ta bort',
  };

  icons = {
    create: 'fa-plus',
    read: 'fa-eye',
    update: 'fa-floppy-o',
    delete: 'fa-trash-o',
  };

  loadingClasses = {
    isLoading: false,
    hasSuccess: false,
    hasError: false,
    startCount: [],
  };

  protected componentDestroyed: Subject<void> = new Subject();

  public getClientValidationMessages(): any {
    return {
      required: 'Fältet är obligatoriskt.',
      minlength: ['Värdet är för kort. Måste vara minst ', ' tecken.'],
      maxlength: ['Värdet är för långt. Måste vara max ', ' tecken.'],
      max: ['Värdet är för stort. Får inte vara mer än ', '.'],
      pattern: 'Felaktigt värde. Måste bestå av siffror',
    };
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  public formValid(formArray: FormGroup[]) {
    return this.checkFormsValidity(formArray);
  }

  public resetForm(form: FormGroup) {
    form.reset();
    this.clearErrors(form);
  }

  public resetErrors(form: FormGroup): void {
    form.reset(form.value);
    this.clearErrors(form);
  }

  private clearErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      form.controls[key].setErrors(null);
    });
  }

  public groupedFormSimple(formStructure: any): FormGroup {
    if (formStructure['nestedForm']) {
      return this.groupedFormWithArray(formStructure);
    }

    return this.formBuilder.group(formStructure['attributes']);
  }

  private groupedFormWithArray(formStructure: any) {
    const fieldToset = JSON.parse(JSON.stringify(formStructure));

    const attributes = fieldToset['attributes'];
    for (const i in attributes) {
      if (Array.isArray(attributes[i])) {
        const newField = { ...fieldToset };
        newField['attributes'] = attributes[i][0];
        const _form = this.formBuilder.group(newField['attributes']);
        this.setRulesAndLabelsNew(_form, newField);
        fieldToset['attributes'][i] = this.formBuilder.array([_form]);
      } // Set form attributes for use
    }
    return this.formBuilder.group(fieldToset['attributes']);
  }

  public groupSetLabelsRules(
    formStructure,
    form?: FormGroup,
    data: any = null
  ) {
    const objectCopy = { ...formStructure };
    const groupedForm: FormGroup = form || this.groupedFormSimple(objectCopy);
    const gqlQueryString = objectCopy['model'];
    const attributes = objectCopy['attributes'];

    // check if there is attrToExcludeFromRulesAndLabel is added
    let queryAttributes = { ...formStructure['attributes'] };
    if (formStructure['attrToExcludeFromRulesAndLabel']) {
      queryAttributes = this.deleteAttributesFromList(
        queryAttributes,
        formStructure['attrToExcludeFromRulesAndLabel']
      );
    }

    this.appendAttrToForm(attributes, groupedForm);
    data !== null && this.appendForm(attributes, data, groupedForm); // Append data to if provided

    return this.setLabelsAndRulesToControls(
      gqlQueryString,
      groupedForm,
      formStructure['attributes'],
      queryAttributes
    );
  }

  private appendForm(attributesToAppend, data, form: FormGroup): void {
    const fieldsToSet = Object.keys(attributesToAppend);
    const formFields = {};
    if (data !== null) {
      // If data is passed in append form value with data
      for (const index in fieldsToSet) {
        formFields[fieldsToSet[index]] = data[fieldsToSet[index]];
      }
      form.patchValue(formFields);
    }
  }

  private appendAttrToForm(attributesToAppend, form: FormGroup): void {
    const fieldsToSet = Object.keys(attributesToAppend);
    fieldsToSet.forEach(attr => {
      form.controls[attr]['name'] = attr;
    });
  }

  public addItemToFormArray(
    form: FormGroup,
    formFields: any,
    key: string,
    attributes: any
  ): void {
    // grab model name from form-structure and attributes
    const fieldToset = { ...formFields };
    const controls = form.get(key) as FormArray;

    fieldToset['attributes'] = attributes;
    const _form: FormGroup = this.formBuilder.group(fieldToset['attributes']);
    controls.push(_form);
    this.setRulesAndLabelsNew(_form, fieldToset);
  }

  public setRulesAndLabelsNew(formGroup: FormGroup, formStructure: any) {
    const gqlQueryString = formStructure['model'];
    // check if there is attrToExcludeFromRulesAndLabel is added
    let queryAttributes = { ...formStructure['attributes'] };
    if (formStructure['attrToExcludeFromRulesAndLabel']) {
      queryAttributes = this.deleteAttributesFromList(
        queryAttributes,
        formStructure['attrToExcludeFromRulesAndLabel']
      );
    }

    setTimeout(() => {
      return this.getSetLabelsAndRulesArray(
        gqlQueryString,
        formGroup,
        formStructure['attributes'],
        queryAttributes
      );
    }, 0);
  }

  private deleteAttributesFromList(source: any, list: string[]): any {
    list.forEach(key => {
      delete source[key as any];
    });
    return source;
  }

  private getSetLabelsAndRulesArray(
    modelName: string,
    form: FormGroup,
    attributes,
    queryAttributes
  ): ExtendedFormGroup {
    this.labelsService
      .getSingleModel(modelName)
      .pipe(takeUntil(this.componentDestroyed), take(1))
      .subscribe(({ labels, rules, hints }) => {
        this.setLabelsToForm(form, labels, hints);
        this.appendRulesToFormGroup(form as FormGroup, attributes, rules);
      });
    return form;
  }

  private setLabelsAndRulesToControls(
    modelName: string,
    form: FormGroup,
    attributes,
    queryAttributes
  ): Promise<any> {
    // FIXME: Refactor me: What are we trying to achieve? Does this even make sense?
    const isAttributesZeroAnArray = Array.isArray(Object.values(attributes)[0]);
    if (isAttributesZeroAnArray) {
      return Promise.resolve(form);
    }

    return this.labelsService
      .getSingleModel(modelName)
      .pipe(
        map(({ labels, rules, hints }) => {
          this.setLabelsToForm(form, labels, hints);
          this.appendRulesToFormGroup(form as FormGroup, attributes, rules);
          return form;
        })
      )
      .toPromise();
  }

  private setLabelsToForm(formGroup: FormGroup, labels, hints): void {
    for (const field in formGroup.controls) {
      labels[field] && (formGroup.controls[field]['label'] = labels[field]);
      hints[field] && (formGroup.controls[field]['hints'] = hints[field]);
    }
  }

  /**
   * This takes a set of validation rules from the backend and applies them to the form-group.
   * @param _form the relevant form object to add validators to
   * @param fields the fields on the _form
   * @param rulesArray These are the validator fields from the backend
   */
  private appendRulesToFormGroup(_form: FormGroup, fields, rulesArray): void {
    rulesArray.forEach(rule => {
      const validatorType: string = rule[1];
      // Use only fields where the attribute is defined in the form
      const fieldsArray: string[] = rule[0]
        .split(',')
        .map((field: string) => field.trim())
        .filter(field => typeof fields[field] !== 'undefined');

      fieldsArray.forEach((field: string) => {
        const fieldRule = {
          field: field,
          rule: validatorType,
          ruleComplete: rule,
        };
        const formValidatorsToAdd: ValidatorFn[] = [];

        switch (fieldRule.rule) {
          case 'required':
            formValidatorsToAdd.push(
              this.setRequiredFieldRules(_form.controls[field])
            );
            break;
          case 'numerical':
            formValidatorsToAdd.push(...this.setNumericalFieldRules(fieldRule));
            break;
          case 'length':
            formValidatorsToAdd.push(...this.setLengthFieldRules(fieldRule));
            break;
        }
        _form.controls[field]['showErrors'] = false;
        _form.controls[field].setValidators(formValidatorsToAdd);
        this.subscribeToFormStatus(_form.controls[field]);
      }); // const field in fieldsArray
    });
  }

  private setNumericalFieldRules(fieldRule): ValidatorFn[] {
    /**
     * Checks for positive/negative integers or decimal values (with `,` or `.` as separator)
     * And also allows for `true` or `false` values (for the checkboxes)
     * Does *not* validate on empty strings.
     *
     * TODO: revisit the checkbox validation
     *
     * see https://regexr.com/5i8ue for the test suite over this pattern.
     */
    const decimalOrTrueOrFalse = '^((-?[0-9]+([.,]{1}[0-9]+)?)|true|false)$';

    /**
     * Checks for positive/negative integers.
     * And also allows for `true` or `false` values (for the checkboxes).
     * Does *not* validate on empty strings.
     *
     * TODO: revisit the checkbox validation
     *
     * see https://regexr.com/5i8ue for the test suite over this pattern.
     */
    const integerOrTrueOrFalse = '^((-?[0-9]+)|true|false)$';

    const formValidatorsToAdd = [];

    if (fieldRule.ruleComplete.integerOnly) {
      formValidatorsToAdd.push(Validators.pattern(integerOrTrueOrFalse));
    } else {
      formValidatorsToAdd.push(Validators.pattern(decimalOrTrueOrFalse));
    }

    if (fieldRule.ruleComplete.max) {
      formValidatorsToAdd.push(Validators.max(fieldRule.ruleComplete.max));
    }
    return formValidatorsToAdd;
  }

  private setRequiredFieldRules(abstractControl): ValidatorFn {
    abstractControl['label'] = '*' + abstractControl['label'];
    return Validators.required;
  }

  private setLengthFieldRules(fieldRule): ValidatorFn[] {
    const formValidatorsToAdd = [];
    if (typeof fieldRule.ruleComplete.max !== 'undefined') {
      formValidatorsToAdd.push(
        Validators.maxLength(fieldRule.ruleComplete.max)
      );
    }
    if (typeof fieldRule.ruleComplete.min !== 'undefined') {
      formValidatorsToAdd.push(
        Validators.minLength(fieldRule.ruleComplete.min)
      );
    }
    return formValidatorsToAdd;
  }

  private subscribeToFormStatus(formControl) {
    this.formSubs = formControl.statusChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        // Update error flag
        formControl['showErrors'] = !formControl['valid'];
        formControl['hasClientErrors'] = !formControl['valid'];

        // Errors are kept as array, reset them everytime flush out old errors
        formControl['mergedErrors'] = [];
        formControl['serverErrors'] = null;
        formControl['hasServerErrors'] = false;
        // shows CLIENT validation errors if there is any
        if (formControl['hasClientErrors']) {
          this.displayError(formControl);
        }
      });
  }

  /*********************************  END CREATION OF FORM ***********************************************/

  private resetMergedErrors(formControl: AbstractControl): void {
    formControl['mergedErrors'] = [];
  }
  private getClientsErrorsFormated(formControl: AbstractControl): void {
    formControl['hasClientErrors'] && this.displayError(formControl);
  }
  protected displayError(formControl: AbstractControl): void {
    // Read error types on form & set appropriate message to error array (mergedErrors on form control)
    const clientValidationMessages = this.getClientValidationMessages();

    for (const error in formControl.errors) {
      let minLengthError: string;
      let requiredError;
      let patternError: string;
      let defaultError: string;
      switch (error) {
        case 'required':
          requiredError = clientValidationMessages[error];
          formControl['mergedErrors'].push(requiredError);
          break;
        case 'minlength':
          minLengthError =
            clientValidationMessages[error][0] +
            formControl.errors[error].requiredLength +
            clientValidationMessages[error][1];
          formControl['mergedErrors'].push(minLengthError);
          break;
        case 'maxlength':
          minLengthError =
            clientValidationMessages[error][0] +
            formControl.errors[error].requiredLength +
            clientValidationMessages[error][1];
          formControl['mergedErrors'].push(minLengthError);
          break;
        case 'pattern':
          patternError = clientValidationMessages[error];
          formControl['mergedErrors'].push(patternError);
          break;
        case 'max':
          const maxError =
            clientValidationMessages[error][0] +
            formControl.errors[error].max +
            clientValidationMessages[error][1];
          formControl['mergedErrors'].push(maxError);
          break;

        default:
          defaultError = '';
          break;
      }
    }
  }

  // Trigger validity on each form
  public checkFormsValidity(formGroup: any[]) {
    const mode = 'submitted';
    let retVal = true;
    formGroup.forEach(form => {
      const controlKey = Object.keys(form['controls']);
      if (form['controls'][controlKey[0]]['controls']) {
        controlKey.forEach(index => {
          this.setErrorFlag(false, form['controls'][index], mode);
        });
      } else {
        this.setErrorFlag(false, form, mode);
      }

      retVal = retVal && form.valid;
    });

    return retVal;
  }

  public getMergedErrors(formGroup: any[]) {
    const errors = [];
    formGroup.forEach(form => {
      const controlKeys = Object.keys(form['controls']);
      controlKeys.forEach(index => {
        if (form['controls'][index] instanceof FormArray) {
          errors.push(
            ...this.getMergedErrors(form['controls'][index]['controls'])
          );
        } else {
          if (form['controls'][index]['mergedErrors']) {
            form['controls'][index]['mergedErrors'].forEach(error => {
              errors.push({
                field: index,
                label: form['controls'][index].label?.replace('*', ''),
                error: error,
              });
            });
          }
        }
      });
    });

    return errors;
  }

  // controls whether to show client and/or server errors
  public setErrorFlag(
    forceServer = false,
    formGroup: any,
    mode = 'unsubmitted',
    responseForFormGroup?
  ) {
    if (forceServer) {
      responseForFormGroup
        ? this.appendServerErrorsOnControlsNew(formGroup, responseForFormGroup)
        : this.appendServerErrorsOnControlsNew(formGroup);
    }

    // When the control is completed we must make a decision based on the above.
    // Loop over the fields
    for (const j in formGroup.controls) {
      this.appendClientErrorsOnControls(formGroup.controls[j], mode);
      formGroup.controls[j]['showErrors'] =
        formGroup.controls[j]['hasServerErrors'] ||
        formGroup.controls[j]['hasClientErrors'];
      // Reset mergedErrors
      this.resetMergedErrors(formGroup.controls[j]);
      this.getClientsErrorsFormated(formGroup.controls[j]);
    }
    this.getServerErrorsFormated(formGroup);
  }

  private appendClientErrorsOnControls(
    controls: AbstractControl,
    mode: string,
    showErrorsIfTheseRulesApply = ['invalid', 'dirty']
  ) {
    let hasClientErrors = false;
    mode === 'submitted' && controls.markAsDirty();
    // Loop through form showErrorsIfTheseRulesApply.
    for (const j in showErrorsIfTheseRulesApply) {
      if (controls[showErrorsIfTheseRulesApply[j]]) {
        hasClientErrors = true;
      } else {
        hasClientErrors = false;
        break;
      }
    }
    controls['hasClientErrors'] = hasClientErrors;
  }

  private appendServerErrorsOnControlsNew(
    formGroup,
    mutationResponse?,
    showErrorsIfTheseRulesApply = ['invalid', 'serverErrors']
  ) {
    // resets all controls
    for (const j in formGroup.controls) {
      formGroup.controls[j]['_status'] = 'VALID';
      formGroup.controls[j]['serverErrors'] = [];
    }

    if (mutationResponse) {
      // If the response is sent, run through all error and append these to controls.

      for (const i in mutationResponse.modelErrorsMsgs) {
        let myObj;
        try {
          myObj = JSON.parse(mutationResponse.modelErrorsMsgs[i]);

          for (const j in formGroup.controls) {
            if (myObj.hasOwnProperty(j)) {
              // Has error

              formGroup.controls[j]['serverErrors'] = myObj[j];

              formGroup.controls[j]['_status'] = 'INVALID';
              formGroup.controls[j]['status'] = 'INVALID';
              formGroup.controls[j]['mergedErrors'] = [];
            }
            let hasServerErrors = false;

            for (const k in showErrorsIfTheseRulesApply) {
              // Loops through the rules on every control and applies showErrors if true
              if (formGroup.controls[j][showErrorsIfTheseRulesApply[k]]) {
                hasServerErrors = true;
              } else {
                hasServerErrors = false;
                break;
              }
            }
            formGroup.controls[j]['hasServerErrors'] = hasServerErrors;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  private getServerErrorsFormated(formGroup: FormGroup) {
    // Formats and sends to mergedErrors
    for (const key in formGroup.controls) {
      if (formGroup.controls[key]['hasServerErrors']) {
        for (const error in formGroup.controls[key]['serverErrors']) {
          const pushError = formGroup.controls[key]['serverErrors'][error];
          formGroup.controls[key]['mergedErrors'].push(pushError);
        }
      }
    }
  }

  // check response from server and show errors to users if it exist
  public showServerErrorsOnAttributes(
    response: any,
    form: any,
    validate?,
    showLong = false
  ) {
    // Changed name from serverValidate
    // Check if form is in array and call appropriate methods
    if (Array.isArray(form)) {
      return this.serverValidateOnMultipleForms(
        response,
        form,
        validate,
        showLong
      );
    } else {
      return this.serverValidateSingleForm(response, form);
    }
  }

  private serverValidateSingleForm(dataResponse, formGroup: FormGroup) {
    const validateResult = this.checkForErrorsInResponse(dataResponse);

    if (validateResult.errors) {
      this.setErrorFlag(true, formGroup, 'unsubmitted', dataResponse);
    } else {
      this.setErrorFlag(true, formGroup, 'unsubmitted');
    }

    return validateResult;
  }

  private serverValidateOnMultipleForms(
    dataResponse,
    forms: any[],
    validate?,
    showLong = false
  ) {
    let showMsg = validate ? !validate : true;

    const validateResult = this.checkForErrorsInResponse(dataResponse);
    let errorNum = 0;

    for (const i in forms) {
      if (validateResult.errors) {
        for (const j in dataResponse.mutationDetails) {
          if (
            dataResponse.mutationDetails[j]['argument'] === forms[i]['argument']
          ) {
            if (
              showMsg &&
              !dataResponse.mutationDetails[j]['mutationSucceeded']
            ) {
              showMsg = false;
            }

            this.setErrorFlag(
              true,
              forms[i]['form'],
              'unsubmitted',
              dataResponse.mutationDetails[j]
            );

            Object.keys(forms[i]['form']['controls']).forEach(key => {
              const form = forms[i]['form'].get(key);
              if (form['controls']) {
                form['controls'].forEach((formG, index) => {
                  if (index === errorNum) {
                    console.log(dataResponse.mutationDetails[j]);
                    this.setErrorFlag(
                      true,
                      formG,
                      'unsubmitted',
                      dataResponse.mutationDetails[j]
                    );
                  }
                });

                errorNum++;
              }
            });
          }
        }
      } else {
        // checks for clientErrors and erases earlier server errors that does not longer exist
        this.setErrorFlag(true, forms[i]['form'], 'unsubmitted'); // mode maybe should be "submitted"
        for (const j in dataResponse.mutationDetails) {
          if (
            dataResponse.mutationDetails[j]['argument'] ===
              forms[i]['argument'] &&
            showMsg &&
            !dataResponse.mutationDetails[j]['mutationSucceeded']
          ) {
            showMsg = false;
          }
        }
      }
    }
    return validateResult;
  }

  checkForErrorsInResponse(response) {
    let result = {
      text: 'handleResponse(): Det finns INGA fel. Okej att köra saveRequest()',
      errors: false,
    };
    for (const details of response.mutationDetails) {
      if (details.modelErrorsMsgs && details.modelErrorsMsgs.length !== 0) {
        result = {
          text: 'handleResponse(): Det finns fel. Kör validateRequest() igen',
          errors: details.modelErrorsMsgs,
        };
        break;
      }
    }
    return result;
  }

  lockButtons(buttons, isDelete?) {
    const returnButtons = buttons;
    for (const button in buttons) {
      if (button === 'delete') {
        if (isDelete) {
          returnButtons[button]['savedLabel'] = buttons[button]['label'];
          returnButtons[button]['label'] = 'Laddar';
          returnButtons[button]['savedIcon'] = buttons[button]['icon'];
          returnButtons[button]['icon'] =
            'fa fa-lg fa-spin fa-hourglass-half fa-fw';
        }
        returnButtons[button]['disabled'] = true;
      } else {
        if (isDelete) {
          returnButtons[button]['disabled'] = true;
        } else {
          returnButtons[button]['savedLabel'] = buttons[button]['label'];
          returnButtons[button]['label'] = 'Laddar';
          returnButtons[button]['savedIcon'] = buttons[button]['icon'];
          returnButtons[button]['icon'] =
            'fa fa-lg fa-spin fa-hourglass-half fa-fw';
          returnButtons[button]['disabled'] = true;
        }
      }
    }
    return returnButtons;
  }

  unlockButtons(buttons, isDelete?) {
    const returnButtons = buttons;
    for (const button in buttons) {
      if (button === 'delete') {
        if (isDelete) {
          returnButtons[button]['label'] = buttons[button]['savedLabel'];
          returnButtons[button]['icon'] = buttons[button]['savedIcon'];
        }
        returnButtons[button]['disabled'] = false;
      } else {
        if (isDelete) {
          returnButtons[button]['disabled'] = false;
        } else {
          returnButtons[button]['label'] = buttons[button]['savedLabel'];
          returnButtons[button]['icon'] = buttons[button]['savedIcon'];
          returnButtons[button]['disabled'] = false;
        }
      }
    }
    return returnButtons;
  }

  getButtonValues(buttons) {
    // Add data model handling
    const buttonsReturn = {};
    const labels = { ...this.labels };
    const icons = { ...this.icons };

    for (const button in buttons) {
      const labelVar = labels[button];
      const iconVar = 'fa fa-lg ' + icons[button];
      buttonsReturn[button] = {
        label: labelVar,
        icon: iconVar,
        disabled: buttons[button]['disabled']
          ? buttons[button]['disabled']
          : false,
      };
    }
    return buttonsReturn;
  }

  public getButtonValuesAsync(buttons): ButtonsAsync {
    // Lägg till datamodel hantering
    const buttonsReturn = {};
    const labels = { ...this.labels };
    const icons = { ...this.icons };

    for (const index in buttons) {
      const action = buttons[index];
      const labelVar = labels[action];
      const iconVar = 'fa fa-lg ' + icons[action];

      buttonsReturn[action] = {
        label: new BehaviorSubject(labelVar),
        icon: new BehaviorSubject(iconVar),
        disabled: new BehaviorSubject(false),
      };
    }

    return buttonsReturn;
  }

  lockButtonsAsync(buttons: ButtonsAsync, isDelete?) {
    for (const button in buttons) {
      if (button === 'delete') {
        if (isDelete) {
          buttons[button]['savedLabel'] = buttons[button]['label'].value;
          buttons[button]['label'].next('Laddar');
          buttons[button]['savedIcon'] = buttons[button]['icon'].value;
          buttons[button]['icon'].next(
            'fa fa-lg fa-spin fa-hourglass-half fa-fw'
          );
        }
        buttons[button]['disabled'].next(true);
      } else {
        if (isDelete) {
          buttons[button]['disabled'].next(true);
        } else {
          buttons[button]['savedLabel'] = buttons[button]['label'].value;
          buttons[button]['label'].next('Laddar');
          buttons[button]['savedIcon'] = buttons[button]['icon'].value;
          buttons[button]['icon'].next(
            'fa fa-lg fa-spin fa-hourglass-half fa-fw'
          );
          buttons[button]['disabled'].next(true);
        }
      }
    }
  }

  unlockButtonsAsync(buttons: ButtonsAsync, isDelete?) {
    for (const button in buttons) {
      if (button === 'delete') {
        if (isDelete) {
          buttons[button]['label'].next(buttons[button]['savedLabel']);
          buttons[button]['icon'].next(buttons[button]['savedIcon']);
        }
        buttons[button]['disabled'].next(false);
      } else {
        if (isDelete) {
          buttons[button]['disabled'].next(false);
        } else {
          buttons[button]['label'].next(buttons[button]['savedLabel']);
          buttons[button]['icon'].next(buttons[button]['savedIcon']);
          buttons[button]['disabled'].next(false);
        }
      }
    }
  }

  public getCleanedMutationVariable(form: FormGroup) {
    const value = { ...form.value };
    const deleteArr = [
      '__typename',
      'mutationDetails',
      'validateOnHyperionMutation',
      'mutationSucceededAllArguments',
    ];

    for (const item of deleteArr) {
      if (value.hasOwnProperty(item)) {
        delete value[item];
      }
    }

    value.hasOwnProperty('id') && (value['id'] = Number(value['id']));
    return value;
  }

  setLoadingClasses(mode?) {
    const classes = this.loadingClasses;
    switch (mode) {
      case 'start':
        if (classes['startCount'].length === 0) {
          // If there is no start then start one
          classes['isLoading'] = true;
        } else {
          classes['isLoading'] = false;
          classes['hasSuccess'] = false;
          classes['hasError'] = false;
          this.loadingClasses = classes;
          setTimeout(() => {
            classes['isLoading'] = true;
            this.loadingClasses = classes;
          }, 500);
        }
        // om det pågår någon över huvudettaget, starta inte
        classes['startCount'].push(Math.random());

        break;
      case 'success':
        classes['startCount'].splice(classes['startCount'].length - 1, 1);

        if (classes['startCount'].length === 0) {
          classes['isLoading'] = false;
          classes['hasSuccess'] = true;
          classes['hasError'] = false;
        }
        break;
      case 'error':
        classes['isLoading'] = false;
        classes['hasSuccess'] = false;
        classes['hasError'] = true;
        break;
      default:
        classes['startCount'].splice(classes['startCount'].length - 1, 1);

        if (classes['startCount'].length === 0) {
          // Nolla om den inte håller på
          classes['isLoading'] = false;
          classes['hasSuccess'] = false;
          classes['hasError'] = false;
        }
        break;
    }
    this.loadingClasses = classes;
  }
}
