<div [class.page-container]="!isModal">
  <p-card [class.no-style]="isModal" class="p-mb-4 p-d-block">
    <spinner *ngIf="loading"></spinner>
    <div class="p-grid">
      <div class="p-grid p-col-12 show-on-large-device p-g-nopad">
        <div class="p-col-1 col-fixed" style="width: 80px">Visa i app</div>
        <div class="p-col-1">Närvarokod</div>
        <div class="p-col-2">Namn</div>
        <div class="p-col-4">Beskrivning</div>
        <div class="p-col-2" *ngIf="showAdvancedUseCostTypes">
          Koppla yrkestyper
        </div>
      </div>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let attendanceType of attendanceTypes"
      >
        <div>
          <CompanyAttendanceType-edit
            [attendanceType]="attendanceType"
            (updated)="onRowEdit()"
            [costTypes]="userCostTypes"
            [showAdvancedUseCostTypes]="showAdvancedUseCostTypes"
          ></CompanyAttendanceType-edit>
        </div>
      </div>
    </div>

    <div *ngIf="deletedAttendanceTypes.length" class="p-grid">
      <hr class="line-separator" />
      <h2>Inaktiverade närvarotyper</h2>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let attendanceType of deletedAttendanceTypes"
      >
        <div>
          <CompanyAttendanceType-edit
            [attendanceType]="attendanceType"
            (updated)="onRowEdit()"
            [costTypes]="userCostTypes"
            [showAdvancedUseCostTypes]="showAdvancedUseCostTypes"
          ></CompanyAttendanceType-edit>
        </div>
      </div>
    </div>

    <div *ngIf="creatableTypes.length">
      <hr class="line-separator" />
      <h2>Lägg till närvarotyp</h2>
      <div class="p-col-12 p-g-nopad p-grid">
        <div class="p-col-8">
          <p-dropdown
            [options]="creatableTypes"
            [(ngModel)]="selectedCreateType"
            optionLabel="name"
            optionValue="code"
            placeholder="Välj närvarokod"
          ></p-dropdown>
        </div>
        <div class="p-col-4">
          <button
            pButton
            class="p-button-primary-hyperion"
            [disabled]="addButtonDisabled"
            (click)="addNewAttendanceType()"
            label="Lägg till"
            icon="pi pi-plus"
          ></button>
        </div>
      </div>
    </div>
  </p-card>
</div>
