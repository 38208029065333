import { CompanyFunctionsService } from 'app/shared/company/companyFunctions.service';
import { GlobalService } from '../../../shared/global/index';
import { ApolloQueryService } from '../../../shared/apollo';
import { HelperService } from 'app/shared/helpers';
// import { UpdateCompanySettingsGQL } from '../company-update-settings/graphql/company-update-settings.generated';
import { MessageService } from 'app/shared/message';
import { Component, OnInit } from '@angular/core';
import {
  GetAttendanceTypesGQL,
  GetCompanyCostTypesGQL,
} from './graphql/get-attendance-types.generated';
import { map } from 'rxjs';
import { AddAttendanceTypeGQL } from './graphql/edit-attendance-type.generated';

@Component({
  selector: 'CompanyAttendanceType-settings',
  templateUrl: 'company-attendance-type-settings.component.html',
})
export class CompanyAttendanceTypeSettingsComponent implements OnInit {
  // componentPrefix;
  loading = true;
  isModal = false;
  public attendanceTypes: {
    id: number;
    code: string;
    description: string;
    name: string;
    showInApp: boolean;
    isDeleted: boolean;
    selectedCostTypes: number[];
  }[] = [];
  public deletedAttendanceTypes = [];
  public creatableTypes: {
    name: string;
    code: string;
  }[] = [];
  public userCostTypes: {
    id: number;
    name: string;
  }[] = [];

  public selectedCreateType;
  public addButtonDisabled = false;
  public showAdvancedUseCostTypes = false;

  private VALID_ATTENDANCE_TYPES = {
    'ARB': 'Timlön',
    'BE2': 'Beredskapstid 2',
    'BER': 'Beredskapstid',
    'FLX': 'Flextid +/-',
    'HLG': 'Helglön',
    'JO2': 'Jourtid 2',
    'JOR': 'Jourtid',
    'MER': 'Mertid',
    'OB1': 'OB-ersättning 1',
    'OB2': 'OB-ersättning 2',
    'OB3': 'OB-ersättning 3',
    'OB4': 'OB-ersättning 4',
    'OB5': 'OB-ersättning 5',
    'OK0': 'Extratid - Komptid',
    'OK1': 'Övertid 1 - Komptid',
    'OK2': 'Övertid 2 - Komptid',
    'OK3': 'Övertid 3 - Komptid',
    'OK4': 'Övertid 4 - Komptid',
    'OK5': 'Övertid 5 - Komptid',
    'OT1': 'Övertid 1 - Betalning',
    'OT2': 'Övertid 2 - Betalning',
    'OT3': 'Övertid 3 - Betalning',
    'OT4': 'Övertid 4 - Betalning',
    'OT5': 'Övertid 5 - Betalning',
    'RES': 'Restid',
    'TID': 'Arbetstid',
    'X:XBT': 'Extratid - Betalning',
  };

  constructor(
    private companyFunctionsService: CompanyFunctionsService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService,
    private messageService: MessageService,
    private attendanceTypesService: GetAttendanceTypesGQL,
    private addAttendanceTypeService: AddAttendanceTypeGQL,
    private costTypesService: GetCompanyCostTypesGQL
  ) {}

  ngOnInit() {
    this.loading = true;
    this.companyFunctionsService
      .hasCompanyFunction('useAdvancedUserCostTypes')
      .subscribe(isEnabled => (this.showAdvancedUseCostTypes = isEnabled));

    this.loadCostTypes();
    this.loadData();
  }

  private loadData() {
    const createTypes = this.VALID_ATTENDANCE_TYPES;
    this.attendanceTypesService
      .fetch()
      .pipe(
        map(res =>
          res.data.company.dayAttendanceTypesWithDeleted.edges.map(
            node => node.node
          )
        )
      )
      .subscribe(data => {
        // Replace data in place to avoid flickering
        let activeCount = 0;
        let deletedCount = 0;
        data.forEach(element => {
          const selectedCostTypes = element.userCostTypes.edges.map(
            costType => {
              return +costType.node.id;
            }
          );
          if (element.isDeleted) {
            this.deletedAttendanceTypes[deletedCount] = element;
            deletedCount++;
          } else {
            this.attendanceTypes[activeCount] = {
              id: +element.id,
              code: element.code,
              description: element.description,
              name: element.name,
              showInApp: element.showInApp,
              isDeleted: element.isDeleted,
              selectedCostTypes: selectedCostTypes,
            };
            activeCount++;
          }
          if (createTypes[element.code]) {
            delete createTypes[element.code];
          }
        });
        for (
          activeCount;
          activeCount < this.attendanceTypes.length;
          activeCount++
        ) {
          this.attendanceTypes.pop();
        }
        for (
          deletedCount;
          deletedCount < this.deletedAttendanceTypes.length;
          deletedCount++
        ) {
          this.deletedAttendanceTypes.pop();
        }
        this.creatableTypes = Object.keys(createTypes).map(key => ({
          code: key,
          name: createTypes[key],
        }));
        this.loading = false;
      });
  }

  private loadCostTypes() {
    this.costTypesService
      .fetch()
      .pipe(
        map(res => res.data.company.userCostTypes.edges.map(node => node.node))
      )
      .subscribe(data => {
        data.forEach(costType => {
          if (costType.active && costType.parentId) {
            this.userCostTypes.push({
              id: +costType.id,
              name: costType.name,
            });
          }
        });
      });
  }

  public onRowEdit() {
    this.loadData();
  }

  public addNewAttendanceType() {
    this.addButtonDisabled = true;
    this.loading = true;
    this.addAttendanceTypeService
      .mutate({
        code: this.selectedCreateType,
        name: this.VALID_ATTENDANCE_TYPES[this.selectedCreateType],
      })
      .subscribe(res => {
        this.addButtonDisabled = false;
        this.loading = false;
        this.messageService.insertDataFromMutation(
          res.data.dayAttendanceTypeTypeHyperionMutation
        );
        this.onRowEdit();
      });
  }
}
