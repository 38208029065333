<div>
  <form
    *ngIf="componentMainForm"
    [formGroup]="componentMainForm"
    class="p-grid"
  >
    <div class="p-col-12 col-fixed p-d-flex" style="width: 80px">
      <p-checkbox
        [binary]="true"
        formControlName="showInApp"
        [id]="'companyAttendanceType_showInApp_' + attendanceType.id"
      ></p-checkbox>
    </div>

    <div class="p-col-12 p-lg-1">
      <input
        pInputText
        formControlName="code"
        [id]="'companyAttendanceType_code_' + attendanceType.id"
      />
    </div>
    <div class="p-col-12 p-lg-2">
      <input
        pInputText
        formControlName="name"
        [id]="'companyAttendanceType_name_' + attendanceType.id"
      />
    </div>
    <div class="p-col-12 p-lg-4">
      <input
        pInputText
        formControlName="description"
        [id]="'companyAttendanceType_description_' + attendanceType.id"
      />
    </div>
    <div class="p-col-12 p-lg-2" *ngIf="showAdvancedUseCostTypes">
      <p-multiSelect
        [options]="costTypes"
        formControlName="selectedCostTypes"
        optionLabel="name"
        optionValue="id"
        [id]="'companyAttendanceType_costTypes_' + attendanceType.id"
      ></p-multiSelect>
    </div>
    <div class="p-col-12 p-lg-1">
      <button
        *ngIf="!attendanceType.isDeleted"
        type="button"
        pButton
        class="p-button-icon-danger"
        (click)="confirmActionDelete()"
        icon="pi pi-trash"
        [disabled]="buttons.delete.disabled"
      ></button>
      <button
        *ngIf="attendanceType.isDeleted"
        type="button"
        pButton
        (click)="actionRestore()"
        label="Aktivera"
        [disabled]="buttons.restore.disabled"
      ></button>
    </div>
    <div class="p-col-12 p-lg-1">
      <button
        *ngIf="!attendanceType.isDeleted"
        type="submit"
        pButton
        class="p-button-primary-hyperion"
        (click)="actionUpdate()"
        icon="pi pi-save"
        [disabled]="buttons.update.disabled"
      ></button>
    </div>
  </form>
</div>
