import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MutationResult } from 'apollo-angular';
import { ProjectSystemTypeCode } from 'app/planner/planner-module-enums';
import { ConvertNumberService } from 'app/shared/convert-number.service';
import { DropdownSelectListItem } from 'app/shared/dropdown-select/dropdown-select';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { ProjectProductFormComponent } from 'app/shared/projectproduct/project-product-form/project-product-form.component';
import { UserLocalStorageService } from 'app/shared/user';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { Day, User } from 'generated/types';
import moment from 'moment';
import { first } from 'rxjs';
import {
  CreateDayMutationGQL,
  CreateDayMutationMutation,
  UpdateDayMutationGQL,
} from './graphql/day.mutation.generated';
import {
  FetchAttendanceTypesGQL,
  FetchCompanyUsersCostTypesGQL,
  FetchDaysGQL,
  FetchDaysUsersGQL,
  FetchProjectTodosGQL,
} from './graphql/day.query.generated';

type InputDay = {
  dayId: number | null;
  day: Day | null;
};

@Component({
  selector: 'app-day-form',
  templateUrl: './day-form.component.html',
  styleUrls: ['./day-form.component.scss'],
})
export class DayFormComponent implements OnInit {
  public dayForm: FormGroup;
  public isEdit = false;
  public maxDate = new Date();
  public usersDropDownOptions: {
    label: string;
    value: number;
    costTypeId: number;
  }[];
  public todosDropDownOptions: { label: string; value: number }[];
  public attendanceTypesDropDownOptions: { label: string; value: number }[];
  public usersCostTypesDropDownOptions: { label: string; value: number }[];
  public dayData: Day = { id: null };
  public dayId: number;
  public isFullDay = false;
  public canAddProducts = false;
  public hasMultipleDates = false;
  public isSelectedProjectInternal = false;

  @Output() public dayUpdatedEvent = new EventEmitter<number>();
  @Input() public productsEnabled = false;
  @Input() public storeLastUsedProject = true;
  @Input() public set day(dayData: InputDay) {
    if (dayData.day) {
      this.isEdit = true;

      this.setDayData(dayData.day);
      this.setDayForm();
      return;
    }

    this.dayId = Number(dayData.dayId);

    if (!this.dayId) {
      this.isEdit = false;

      this.setDayData({ id: null });
      this.resetForm();
      return;
    }

    this.isEdit = true;

    this.fetchDaysGQL
      .fetch({ daysId: this.dayId })
      .pipe(first())
      .subscribe(result => {
        this.setDayData(result.data.company.days.edges[0].node);
        this.setDayForm();
      });
  }

  private userFlags: UserFlags;
  public usePickUserCostTypeOnTimereport: boolean;
  public usePickOvertimeOnTimereport: boolean;
  public useUserCostType: boolean;
  public setMile: boolean;
  public setPrivMile: boolean;
  public dontAddPrivMileToMileToInvoice: boolean;
  public forceTodo: boolean;
  public useOnlyTimestampTimeReport: boolean;

  private defaultProjectId: number;
  @Input() public set projectId(projectId: number) {
    if (!projectId) {
      return;
    }
    this.defaultProjectId = projectId;
    this.setProjectId(projectId);
  }
  @Input() public set isExtra(isExtra: boolean) {
    this.dayData.extra = isExtra ? 1 : 0;
    this.initForm();
  }

  @ViewChild('projectProductForm')
  public projectProductForm: ProjectProductFormComponent;

  public isLoading = false;

  constructor(
    private createDayGQL: CreateDayMutationGQL,
    private updateDayGQL: UpdateDayMutationGQL,
    private userLocalStorageService: UserLocalStorageService,
    private fetchDaysGQL: FetchDaysGQL,
    private fetchDaysUsersGQL: FetchDaysUsersGQL,
    private fetchProjectTodosGQL: FetchProjectTodosGQL,
    private fetchAttendanceTypesGQL: FetchAttendanceTypesGQL,
    private fetchCompanyUsersCostTypes: FetchCompanyUsersCostTypesGQL,
    private messageService: MessageService,
    private userFlagsService: UserFlagsService,
    private convertNumber: ConvertNumberService
  ) {}

  public ngOnInit(): void {
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
        this.usePickOvertimeOnTimereport = this.userFlags.hasFlag(
          'usePickOvertimeOnTimereport'
        );
        this.usePickUserCostTypeOnTimereport = this.userFlags.hasFlag(
          'usePickUserCostTypeOnTimereport'
        );
        this.useUserCostType = this.userFlags.hasFlag('useUserCostType');
        this.setMile = this.userFlags.hasFlag('setMile');
        this.setPrivMile = this.userFlags.hasFlag('setPrivMile');
        this.dontAddPrivMileToMileToInvoice = this.userFlags.hasFlag(
          'dontAddPrivMileToMileToInvoice'
        );
        this.forceTodo = this.userFlags.hasFlag('forceTodo');
        this.useOnlyTimestampTimeReport = this.userFlags.hasFlag(
          'useOnlyTimestampTimeReport'
        );

        this.canAddProducts =
          this.userFlags.hasFlag('addProductsOnTimeSaveInApp') &&
          this.productsEnabled &&
          !this.isEdit;

        this.initForm();
      });
  }

  private initForm(): void {
    this.setUsersDropDownData();
    this.setAttendanceTypesDropDownData();
    this.setUsersCostTypesDropDownData();

    const meUser = this.userLocalStorageService.getMEUser();

    this.dayForm = new FormGroup({
      userId: new FormControl(this.dayData.userId || Number(meUser.id)),
      hours: new FormControl(this.dayData.hours),
      hoursToInvoice: new FormControl(this.dayData.hoursToInvoice),
      date: new FormControl(this.dayData.date || moment().format('YYYY-MM-DD')),
      mile: new FormControl(this.dayData.mile),
      mileToInvoice: new FormControl(this.dayData.mileToInvoice),
      privMile: new FormControl(this.dayData.privMile),
      doneWork: new FormControl(this.dayData.doneWork, [Validators.required]),
      extra: new FormControl(Boolean(this.dayData.extra)),
      subsistenceDay: new FormControl(Boolean(this.dayData.subsistenceDay)),
      subsistenceHalfDay: new FormControl(
        Boolean(this.dayData.subsistenceHalfDay)
      ),
      subsistenceNight: new FormControl(Boolean(this.dayData.subsistenceNight)),
      projectId: new FormControl(this.dayData.projectId),
      todoId: new FormControl(this.dayData.todoRelation?.todo_id),
      attendanceTypeId: new FormControl(this.dayData.attendanceTypeId),
      newCostTypeId: new FormControl(
        this.dayData.costTypeHyperion?.companyCostTypeId
      ),
      startTime: new FormControl(
        this.dayData.startTime || moment().format('YYYY-MM-DD HH:mm:ss')
      ),
      stopTime: new FormControl(
        this.dayData.stopTime || moment().format('YYYY-MM-DD HH:mm:ss')
      ),
    });
    if (this.useOnlyTimestampTimeReport) {
      this.dayForm.controls.hours.disable();
      this.dayForm.controls.hoursToInvoice.disable();
    }
  }

  private setDayData(day: Day): void {
    this.dayId = Number(day.id);
    this.dayData = {
      ...day,
      extra: day.extra || this.dayData.extra,
    };
    this.setProjectId(Number(day.project?.id) || this.dayData.projectId);

    if (this.dayData.userId) {
      this.isFullDay = false;
    } else {
      this.isFullDay = true;
    }
  }

  private resetForm(): void {
    if (!this.dayForm) {
      return;
    }

    this.dayForm.reset();
    const patchValue: any = {
      extra: Boolean(this.dayData.extra),
      userId: Number(this.userLocalStorageService.getMEUser().id),
      date: moment().format('YYYY-MM-DD'),
      startTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      stopTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    if (this.storeLastUsedProject) {
      patchValue.projectId = this.defaultProjectId;
    }
    this.dayForm.patchValue(patchValue);
    this.setMeUserCostType();

    if (this.projectProductForm) {
      this.projectProductForm.resetForm();
    }
  }

  private setDayForm(): void {
    if (!this.dayForm) {
      return;
    }

    this.resetForm();
    this.dayForm.patchValue({
      ...this.dayData,
      attendanceTypeId: +this.dayData.attendanceTypeId,
      extra: Boolean(this.dayData.extra),
      todoId: this.dayData.todoRelation?.todo_id,
      newCostTypeId: this.dayData.costTypeHyperion?.companyCostTypeId,
      subsistenceDay: Boolean(this.dayData.subsistenceDay),
      subsistenceHalfDay: Boolean(this.dayData.subsistenceHalfDay),
      subsistenceNight: Boolean(this.dayData.subsistenceNight),
    });
  }

  public onSubmit(): void {
    this.dayForm.markAllAsTouched();
    if (!this.dayForm.valid) {
      return;
    }

    if (this.useOnlyTimestampTimeReport) {
      this.dayForm.enable();
    }

    if (this.storeLastUsedProject) {
      this.defaultProjectId = this.dayForm.value.projectId;
    }

    if (this.isEdit) {
      this.updateDay();
      return;
    }

    if (this.useOnlyTimestampTimeReport) {
      this.createDay();
      return;
    }

    const dates = this.getDates();
    for (const date of dates) {
      this.createDay(date);
    }
  }

  private updateDay(): void {
    this.isLoading = true;
    const updateDay = {
      ...this.dayForm.value,
      id: Number(this.dayData.id),
    };

    this.updateDayGQL
      .mutate({ updateDay })
      .pipe(first())
      .subscribe({
        next: res => this.afterMutation(res),
        error: err => this.onMutationError(err),
      });
  }

  private createDay(date: string = null): void {
    this.isLoading = true;
    const mutationData = {
      createDay: {
        ...this.dayForm.value,
        date: date ?? this.dayForm.value.date,
      },
      createDayProducts: [],
    };

    if (this.canAddProducts) {
      mutationData.createDayProducts =
        this.projectProductForm.productsToAdd.map(product => {
          return {
            ...product,
            projectId: mutationData.createDay.projectId,
            extra: Number(mutationData.createDay.extra),
            date: mutationData.createDay.date,
            enhet: product.enhet ?? 'st',
          };
        });
    }

    this.createDayGQL
      .mutate(mutationData)
      .pipe(first())
      .subscribe({
        next: res => this.afterMutation(res),
        error: err => this.onMutationError(err),
      });
  }

  public onChangeTime(): void {
    const startTime = this.dayForm.value.startTime;
    const stopTime = this.dayForm.value.stopTime;

    const startDate = Date.parse(startTime);
    const stopDate = Date.parse(stopTime);
    const diff = ((stopDate - startDate) / 1000 / 3600).toFixed(2);
    this.dayForm.patchValue({ hours: diff, hoursToInvoice: diff });
  }

  public onChangeHours(): void {
    if (!this.dayForm.controls.hoursToInvoice.value) {
      this.dayForm.patchValue({
        hoursToInvoice: this.dayForm.controls.hours.value,
      });
    }
  }

  public onChangeMile(): void {
    const mile = this.convertNumber.fromString(
      this.dayForm.controls.mile.value
    );
    const privMile = this.convertNumber.fromString(
      this.dayForm.controls.privMile.value
    );

    this.dayForm.patchValue({
      mileToInvoice:
        mile + (this.dontAddPrivMileToMileToInvoice ? 0 : privMile),
    });
  }

  public onChangeProject(projectItem: DropdownSelectListItem): void {
    this.isSelectedProjectInternal =
      projectItem.metadata.systemTypeCode !== ProjectSystemTypeCode.PROJ;
    this.setProjectId(Number(projectItem.id));
    this.dayForm.patchValue({
      projectId: projectItem.id,
    });
  }

  private setProjectId(projectId: number): void {
    if (!projectId) {
      return;
    }
    this.dayData.projectId = Number(projectId);
    this.dayForm?.patchValue({ projectId: projectId });
    this.setProjectTodoDropDownData(projectId);
  }

  // Arbetsmoment
  private setProjectTodoDropDownData(projectId: number): void {
    this.fetchProjectTodosGQL
      .fetch({ id: projectId })
      .pipe(first())
      .subscribe(({ data }) => {
        const todosData = data.project.todos.edges.map(({ node }) => node);

        if (this.forceTodo && todosData.length !== 0) {
          this.dayForm.controls.todoId.setValidators([Validators.required]);
        }

        this.todosDropDownOptions = todosData.map(todo => ({
          label: `${todo.topic.Name} - ${todo.description}`,
          value: Number(todo.id),
        }));
        this.todosDropDownOptions.push({
          label: 'Välj Arbetsmoment',
          value: null,
        });
      });
  }

  // Närvarotyp
  private setAttendanceTypesDropDownData(): void {
    this.fetchAttendanceTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        const attendanceTypes = data.company.dayAttendanceTypes.edges;
        this.attendanceTypesDropDownOptions = attendanceTypes.map(
          ({ node }) => ({
            value: +node.id,
            label: node.name,
          })
        );
      });
  }

  // Yrkestyp
  private setUsersCostTypesDropDownData(): void {
    this.fetchCompanyUsersCostTypes
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        const companyUsersCostTypes = data.company.userCostTypes.edges;
        this.usersCostTypesDropDownOptions = companyUsersCostTypes
          .map(({ node }) => node)
          .filter(companyUserCostType => companyUserCostType.active)
          .map(companyUserCostType => ({
            label: companyUserCostType.name,
            value: Number(companyUserCostType.id),
          }));
      });
  }

  private setUsersDropDownData(): void {
    this.fetchDaysUsersGQL
      .fetch()
      .pipe(first())
      .subscribe(result => {
        let usersData: User[] = result.data.company.users.edges.map(
          ({ node }) => node
        );

        if (this.userFlags.isWorker) {
          usersData = usersData.filter(
            user => user.id === this.userLocalStorageService.getMEUser().id
          );
        }
        this.usersDropDownOptions = usersData.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          value: Number(user.id),
          costTypeId: user.costTypeId,
        }));

        if (this.useUserCostType && !this.dayData.userId) {
          this.setMeUserCostType();
        }
      });
  }

  private afterMutation(
    result: MutationResult<CreateDayMutationMutation>
  ): void {
    this.isLoading = false;
    const mutationData = result?.data?.dayTypeHyperionMutation;
    if (!mutationData) {
      return;
    }
    if (this.useOnlyTimestampTimeReport) {
      this.dayForm.controls.hours.disable();
      this.dayForm.controls.hoursToInvoice.disable();
    }

    this.messageService.insertDataFromMutation(mutationData);

    const mutationDetails = mutationData?.mutationDetails[0];
    const isMutationSuccessful =
      mutationDetails?.errorsMsgs.length === 0 ?? true;

    if (isMutationSuccessful) {
      this.dayUpdatedEvent.emit(Number(mutationData.id));

      if (!this.isEdit) {
        this.resetForm();
      }
    }
  }

  private onMutationError(error: Error): void {
    this.isLoading = false;
    this.messageService.insertData(
      {
        textArray: [error.message],
        severity: ToastMessageSeverityType.ERROR,
      } as ToastMessage,
      true
    );
  }

  private setMeUserCostType(): void {
    const meUser = this.userLocalStorageService.getMEUser();
    this.setUserCostTypeFromUserId(Number(meUser.id));
  }

  public setUserCostTypeFromUserId(userId: number): void {
    if (!this.useUserCostType) {
      return;
    }
    if (!this.usersDropDownOptions) {
      return;
    }

    const costTypeId = this.usersDropDownOptions.find(
      user => user.value === userId
    )?.costTypeId;

    this.dayData.newCostTypeId = costTypeId;
    this.dayForm.patchValue({ newCostTypeId: costTypeId }, { emitEvent: true });
  }

  public onDateFocus(): void {
    const dates = this.getDates();
    const today = moment().format('YYYY-MM-DD');
    if (dates.length === 1 && dates[0] === today) {
      this.dayData.date = null;
      this.dayForm.patchValue({
        date: '',
      });
    }
  }

  public onDateClose(): void {
    const dates = this.getDates();
    this.hasMultipleDates = dates.length > 1;
    if (dates.length > 0) {
      return;
    }

    const today = moment().format('YYYY-MM-DD');
    this.dayData.date = today;
    this.dayForm.patchValue({
      date: today,
    });
  }

  private getDates(): string[] {
    const dateValue = this.dayForm.value.date;
    if (!dateValue) {
      return [];
    }

    const dates = typeof dateValue === 'string' ? [dateValue] : dateValue;
    return dates;
  }
}
