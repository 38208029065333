import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';

import { ConfirmationService } from 'primeng/api';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from 'app/settings/company/services/accounting-plan.types';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';
import { UpdateProjectCostsFromCompanyUserCostTypeCostsGQL } from './graphql/update-project-costs.mutation.generated';
import { first } from 'rxjs';
import { MessageService } from 'app/shared/message';

@Component({
  selector: 'companyUserCostType-edit',
  templateUrl: 'company-user-cost-type-edit.component.html',
  styleUrls: ['company-user-cost-type-edit.component.scss'],
  providers: [FormHandlerService],
})
export class EditUserCostTypeComponent implements OnInit, OnChanges {
  @Input() public userCostType;
  @Input() public hasAccountingPlanFunction: boolean;
  @Input() public accountingPlanAccounts: AccountingPlanAccount[] = [];
  @Input() public parentTypes: { id: number | null; name: string }[] = [];
  @Input() public showAdvancedUseCostTypes = false;

  componentMainForm: FormGroup;
  dataModel = 'companyUserCostType';
  dataModelCapitalized = 'CompanyUserCostType';
  formSettings;
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      showInApp: true,
      id: '',
      name: '',
      cost: '',
      internalCost: '',
      accountingPlanAccount: null,
      reverseVatAccountingPlanAccount: null,
      parentId: null,
    },
  };
  buttons;
  componentPrefix;
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;
  private internalCost: number;
  private cost: number;
  private fieldsChanged: string[] = [];

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private accountingPlanService: AccountingPlanService,
    private updateProjectCostsFromCompanyUserCostTypesGQL: UpdateProjectCostsFromCompanyUserCostTypeCostsGQL,
    private messageService: MessageService
  ) {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.formSettings = this.globalService.getFormSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['userCostType'].previousValue &&
      changes['userCostType'].currentValue['childCount'] !=
        changes['userCostType'].previousValue['childCount']
    ) {
      this.setParentDropdownEnabled();
    }
    this.parentTypes = this.parentTypes.filter(
      x => x.id !== this.userCostType.id
    );
  }

  ngOnInit() {
    this.initForm();
    this.componentPrefix = this.globalService.generateComponentPrefix();

    this.accountingPlanAccountsDropdownParams =
      this.accountingPlanService.getAccountsDropdownParams(
        this.accountingPlanAccounts
      );
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler.groupSetLabelsRules(
      this.formFields,
      this.componentMainForm,
      this.userCostType
    );

    this.internalCost = Number(
      this.componentMainForm.controls.internalCost.value
    );
    this.cost = Number(this.componentMainForm.controls.cost.value);
    this.setParentDropdownEnabled();
  }

  private setParentDropdownEnabled() {
    if (
      this.userCostType.type === 'isWork' ||
      this.parentTypes.length === 0 ||
      this.userCostType.childCount > 0
    ) {
      this.componentMainForm.controls.parentId.disable();
    } else {
      this.componentMainForm.controls.parentId.enable();
    }
  }

  private getDialogDetails(): { message: string; header: string } {
    let message: string;
    let header: string;

    switch (true) {
      case this.fieldsChanged.includes('internalCost') &&
        this.fieldsChanged.includes('cost'):
        header = 'Uppdatera timpris & internkostnad för yrkestyp';
        message =
          'Genom att bekräfta uppdateras timpriset och internkostnaden för denna yrkestyp i samtliga planerade, pågående, och interna projekt. Om du har angett specifika timpriser för denna yrkestyp i projekt kommer detta värde att skrivas över med ditt nya pris. Vill du göra detta? Om du klickar på Nej slår din förändring endast igenom i nya projekt.';
        break;
      case this.fieldsChanged.includes('internalCost'):
        header = 'Uppdatera internkostnad för yrkestyp';
        message =
          'Genom att bekräfta uppdateras internkostnaden för denna yrkestyp i samtliga planerade, pågående, och interna projekt. Detta påverkar kostnadsuträkningen i projektet. Vill du göra detta? Om du klickar på Nej slår din förändring endast igenom i nya projekt.';
        break;
      case this.fieldsChanged.includes('cost'):
        header = 'Uppdatera timpris för yrkestyp';
        message =
          'Genom att bekräfta uppdateras timpriset för denna yrkestyp i samtliga planerade, pågående, och interna projekt. Om du har angett specifika timpriser för denna yrkestyp i projekt kommer detta värde att skrivas över med ditt nya pris. Vill du göra detta? Om du klickar på Nej slår din förändring endast igenom i nya projekt.';
        break;
      default:
        header = '';
        message = '';
    }

    return { message, header };
  }

  private showDialogUpdateProjectPrices() {
    if (this.fieldsChanged.length === 0) {
      return;
    }

    const { message, header } = this.getDialogDetails();

    this.confirmationService.confirm({
      message: message,
      header: header,
      accept: () => {
        this.updateProjectPricesMutation();
      },
      acceptLabel: 'Ja, uppdatera',
      rejectLabel: 'Nej',
    });
  }

  private updateProjectPricesMutation() {
    const updateData: {
      companyUserCostTypeId: number;
      internalCost: number;
      cost: number;
    } = {
      companyUserCostTypeId: this.userCostType.id,
      internalCost: null,
      cost: null,
    };

    if (this.fieldsChanged.includes('internalCost')) {
      updateData.internalCost = Number(
        this.componentMainForm.controls.internalCost.value
      );
    }

    if (this.fieldsChanged.includes('cost')) {
      updateData.cost = Number(this.componentMainForm.controls.cost.value);
    }

    this.updateProjectCostsFromCompanyUserCostTypesGQL
      .mutate(updateData)
      .pipe(first())
      .subscribe(({ data }) => {
        const mutationDetails =
          data.updateProjectCostsFromCompanyUserCostTypeCostsMutation
            .mutationDetails[0];

        if (mutationDetails.mutationSucceeded) {
          this.messageService.insertData(
            { textArray: [mutationDetails.msg], time: 3000, type: 'success' },
            false
          );
        } else {
          this.messageService.insertData(
            {
              textArray: ['Något gick fel vid uppdatering av projektpriser'],
              time: 3000,
              type: 'error',
            },
            true
          );
        }
      });
  }

  actionUpdate(shouldActivate = false) {
    this.fieldsChanged = [];

    const internalCostValue = Number(
      this.componentMainForm.controls.internalCost.value
    );
    const costValue = Number(this.componentMainForm.controls.cost.value);

    if (this.internalCost !== internalCostValue) {
      this.fieldsChanged.push('internalCost');
    }

    if (this.cost !== costValue) {
      this.fieldsChanged.push('cost');
    }

    this.showDialogUpdateProjectPrices();

    const crudType = 'update';
    const dataToMutation = this.componentMainForm.value;
    dataToMutation.id = +dataToMutation.id;

    dataToMutation.accountingPlanAccountId =
      dataToMutation.accountingPlanAccount;
    delete dataToMutation.accountingPlanAccount;

    dataToMutation.reverseVatAccountingPlanAccountId =
      dataToMutation.reverseVatAccountingPlanAccount;
    delete dataToMutation.reverseVatAccountingPlanAccount;

    if (shouldActivate) {
      dataToMutation.active = 1;
    }

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.lockButtons(this.buttons);
      this.formHandler.setLoadingClasses('start');
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            let setLoadingClassesInput = 'error';
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
              this.buttons = this.formHandler.unlockButtons(this.buttons);
            }, 1000);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  actionActivate() {
    this.actionUpdate(true);
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort yrkestypen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete();
      },
      reject: () => {},
    });
  }

  private actionDelete() {
    this.formHandler.setLoadingClasses('start');
    this.buttons = this.formHandler.lockButtons(this.buttons, true);
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.userCostType['id']),
      active: 0,
    };
    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.mutationService.displayMutationStatus(data);
          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);
          if (data.mutationSucceededAllArguments) {
            this.formHandler.setLoadingClasses('success');
          } else {
            this.formHandler.setLoadingClasses('error');
          }
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }
}
