import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { combineLatest, first, map, mergeMap, of } from 'rxjs';
import {
  DropdownSelectListGroup,
  DropdownSelectListItem,
} from '../../shared/dropdown-select/dropdown-select.d';
import {
  LatestProjectsGQL,
  InternalAndAbsenceProjectsGQL,
  ProjectFragment,
  ProjectSearchGQL,
  SingleProjectGQL,
  CurrentProjectGQL,
  ProjectWithSubProjectFragment,
} from './graphql/project.generated';
import { ProjectFrecencyService } from 'app/project/project-frecency.service';
import { DropdownSelectComponent } from '../dropdown-select/dropdown-select.component';

@Component({
  selector: 'app-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
})
export class ProjectSelectComponent implements OnChanges, OnInit {
  @Input() public projectId: number;
  @Input() public disabled = false;
  @Input() public defaultAmount = 20;
  @Output() private selectedProjectEvent =
    new EventEmitter<DropdownSelectListItem>();

  @ViewChild('dropdown', { static: true })
  public dropdown: DropdownSelectComponent;

  public groups: DropdownSelectListGroup[] = [];
  public selectedItem: DropdownSelectListItem;
  public loading = false;
  private fallbackProjects: ProjectFragment[];
  private currentProject: ProjectWithSubProjectFragment;

  public constructor(
    private projectsGQL: ProjectSearchGQL,
    private singleProjectGQL: SingleProjectGQL,
    private currentProjectGQL: CurrentProjectGQL,
    private latestProjectsService: LatestProjectsGQL,
    private internalAndAbsenceProjectsService: InternalAndAbsenceProjectsGQL,
    private projectFrecencyService: ProjectFrecencyService
  ) {}

  public ngOnInit(): void {
    this.prePopulate();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.projectId.currentValue) {
      this.selectedItem = undefined;
      return;
    }
    if (changes.projectId.previousValue !== changes.projectId.currentValue) {
      this.currentProjectGQL
        .fetch({ projectId: this.projectId })
        .pipe(first())
        .subscribe(({ data }) => {
          this.selectedItem = this.getListItem(data.project);
          this.currentProject = data.project;
        });
    }
  }

  public onSelectedItemChanged(item: DropdownSelectListItem): void {
    if (!item?.id) {
      return;
    }

    this.prePopulate();
    this.selectedProjectEvent.emit(item);
  }

  public resetSelectedItem(): void {
    this.dropdown.resetSelectedItem();
  }

  public onSearch(query: string): void {
    this.fetchProjects(query);
  }

  private fetchProjects(query: string): void {
    this.loading = true;
    this.projectsGQL
      .watch({
        search: query,
        limit: this.defaultAmount,
      })
      .valueChanges.pipe(first())
      .subscribe(response => {
        const projects = response.data.company.projectSearch.edges.map(
          e => e.node
        );
        if (projects.length === 0) {
          this.setGroupItems(this.fallbackProjects);
          this.loading = false;
          return;
        }
        this.setGroupItems(projects);
        this.loading = false;
      });
  }

  private setGroupItems(
    projects: ProjectFragment[],
    recent?: ProjectFragment[],
    isPreload = false
  ): void {
    this.groups = [
      {
        label: 'Pågående' + (isPreload ? ' (20 senast skapade)' : ''),
        items: [],
      },
      { label: 'Planerade', items: [] },
      { label: 'Interna', items: [] },
      { label: 'Frånvaro', items: [] },
      { label: 'Arkiverade', items: [] },
      { label: 'Avslutade', items: [] },
    ];
    const groupStatusMap: Record<number, DropdownSelectListGroup> = {
      0: this.groups[1],
      1: this.groups[0],
      2: this.groups[5],
      3: this.groups[4],
      5: this.groups[3],
      6: this.groups[2],
    };

    if (!projects) {
      return;
    }

    projects.forEach(project => {
      if (!(project.status in groupStatusMap)) {
        return;
      }
      groupStatusMap[project.status].items.push(this.getListItem(project));
    });

    if (recent?.every(p => !!p)) {
      const recentOption = {
        label: 'Senast valda',
        items: recent.map(this.getListItem),
      };
      this.groups = [recentOption, ...this.groups];
    }

    if (this.selectedItem && this.currentProject) {
      if (this.currentProject.subProjectCount === 0) {
        return;
      }

      const subProjects = this.currentProject.subProjects.edges.map(
        e => e.node
      );

      const subProjectGroup = {
        label: 'Underprojekt',
        items: subProjects.map(this.getListItem),
      };

      this.groups = [subProjectGroup, ...this.groups];
    }
  }

  private getListItem(project: ProjectFragment): DropdownSelectListItem {
    return {
      id: project.id,
      displayId: project.trueId,
      label: project.mark?.trim(),
      metadata: {
        systemTypeCode: project?.systemTypeCode,
        clientName: project?.clientContact?.name,
        clientBusinessName: project?.clientContact?.orderBuisnessName,
        clientAddress: project?.clientContact?.address,
        clientAddress2: project?.clientContact?.address2,
        clientCity: project?.clientContact?.city,
        establishmentContactName: project?.establishmentContact?.name,
        establishmentAddress: project?.establishmentContact?.address,
        establishmentAddress2: project?.establishmentContact?.address2,
        establishmentCity: project?.establishmentContact?.city,
        projectColor: project?.projectColor,
      },
    };
  }

  public prePopulate(): void {
    const getFrecent = this.projectFrecencyService
      .getList()
      .pipe(
        first(),
        mergeMap(list => {
          return list.length !== 0
            ? combineLatest(
                list.map(id =>
                  this.singleProjectGQL
                    .fetch({ projectId: id }, { fetchPolicy: 'cache-first' })
                    .pipe(map(res => res.data.project))
                )
              )
            : of([]);
        })
      )
      .pipe(map(projects => projects.filter(p => !!p)));

    const getLatest = this.latestProjectsService
      .fetch({ amount: this.defaultAmount })
      .pipe(map(res => res.data.company.projects.edges.map(e => e.node)));

    const getInternalAndAbsence = this.internalAndAbsenceProjectsService
      .fetch()
      .pipe(map(res => res.data.company.projects.edges.map(e => e.node)));

    combineLatest([getLatest, getInternalAndAbsence, getFrecent])
      .pipe(first())
      .subscribe(
        ([latestProjects, internalAndAbsenceProjects, frecentProjects]) => {
          const projects = [
            ...latestProjects,
            ...internalAndAbsenceProjects,
          ].reduce(
            (prev, project) =>
              prev.some(p => p.id === project.id) ? prev : [...prev, project],
            []
          );
          this.fallbackProjects = projects;
          this.setGroupItems(projects, frecentProjects, true);
        }
      );
  }
}
