<app-header *ngIf="type !== 'ata'">
  <app-button
    *ngIf="status === 1"
    (click)="openOfferDataDialog()"
    [type]="'showSummation'"
  ></app-button>
  <app-button
    (click)="router.navigateByUrl('/offer/create')"
    [type]="'showCreate'"
    [model]="'Offer'"
  ></app-button>
</app-header>

<style>
  .help-tooltip {
    margin: 0 10px;
  }
</style>

<div class="page-container">
  <div class="p-grid index-page-header">
    <div class="p-col-8">
      <h2 *ngIf="projectInfo">Hantera ÄTA</h2>
    </div>

    <div class="p-col align-right">
      <button
        pButton
        *ngIf="projectInfo"
        (click)="actionExportExcel()"
        label="Exportera Excel"
        icon="pi pi-file-excel"
      ></button>
      <button
        pButton
        *ngIf="projectInfo"
        (click)="toggleCreateAtaDialog()"
        label="Skapa ÄTA"
        icon="pi pi-plus"
        class="p-button-primary-hyperion p-ml-2"
      ></button>
    </div>

    <div class="p-col-9 p-ai-center print-no-show">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <input
          type="text"
          pInputText
          [disabled]="loadingAsync | async"
          [(ngModel)]="globalSearchString"
          (input)="offerTable.filterGlobal($event.target.value, 'contains')"
          [placeholder]="'Sök ' + (statusText | async)"
        />
      </div>
    </div>
    <div class="p-col-3 align-right print-no-show">
      <app-button
        (click)="showAdvancedSearch = !showAdvancedSearch"
        [type]="'showExtendSearch'"
      ></app-button>
    </div>
  </div>
  <div class="p-grid" *ngIf="showAdvancedSearch">
    <div class="p-col-12">
      <p-accordion [multiple]="true">
        <p-accordionTab header="Utökad sökning" [selected]="true">
          <div class="p-grid">
            <div class="p-grid p-col-12 p-md-6">
              <span class="p-col-12">
                <label>Id</label>
                <input
                  pInputText
                  [ngModel]="''"
                  (change)="offerTable.filter($event, 'trueId', 'contains')"
                />
              </span>
              <span class="p-col-12">
                <label>Märkning</label>
                <input
                  pInputText
                  [ngModel]="''"
                  (change)="offerTable.filter($event, 'concerning', 'contains')"
                />
              </span>
              <span class="p-col-12">
                <label>Kund</label>
                <input
                  pInputText
                  [ngModel]="''"
                  (change)="
                    offerTable.filter($event, 'clientString', 'contains')
                  "
                />
              </span>
              <span class="p-col-12">
                <label>Skapad av</label>
                <app-users-multiselect
                  (selectedUsersEvent)="filterByUsers($event)"
                ></app-users-multiselect>
              </span>
            </div>

            <div class="p-grid p-col-12 p-md-6">
              <span class="p-col-12">
                <label>Status</label>
                <p-dropdown
                  [disabled]="status === 0"
                  [options]="searchstateDropdown"
                  (onChange)="
                    offerTable.filter($event.value, 'state', 'contains')
                  "
                >
                </p-dropdown>
              </span>
              <span class="p-col-12">
                <label>Skickad</label>
                <p-dropdown
                  [disabled]="status === 0"
                  [options]="sendedDropdown"
                  (onChange)="
                    offerTable.filter($event.value, 'sended', 'contains')
                  "
                >
                </p-dropdown>
              </span>
              <span class="p-col-12">
                <label>Skickad för digital signering</label>
                <p-dropdown
                  [disabled]="status === 0"
                  [options]="sendedDropdown"
                  (onChange)="filterBySentForSigning($event.value)"
                >
                </p-dropdown>
              </span>
              <span class="p-col-6">
                <label>Från</label>
                <p-calendar
                  dataType="string"
                  dateFormat="yy-mm-dd"
                  [showIcon]="true"
                  [(ngModel)]="startDate"
                  (ngModelChange)="filterDates(offerTable)"
                  [showWeek]="true"
                >
                </p-calendar>
              </span>
              <span class="p-col-6">
                <label>Till</label>
                <p-calendar
                  dataType="string"
                  dateFormat="yy-mm-dd"
                  [showIcon]="true"
                  [(ngModel)]="endDate"
                  (ngModelChange)="filterDates(offerTable)"
                  [showWeek]="true"
                >
                </p-calendar>
              </span>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div class="p-col-12">
    <spinner *ngIf="loadingAsync | async"></spinner>
  </div>
  <div
    class="p-col-12"
    [style.display]="(loadingAsync | async) ? 'none' : 'block'"
  >
    <p-table
      #offerTable
      dataKey="id"
      [rows]="50"
      [paginator]="true"
      paginatorPosition="both"
      styleClass="expand-rows"
      (onSort)="changeSort($event)"
      [columns]="offerTableColumns"
      [sortField]="sort.attribute"
      [sortOrder]="sort.ascDesc"
      [responsive]="true"
      [(selection)]="selectedOrders"
      [value]="dataSetAsync | async"
      [rowHover]="true"
      [rowTrackBy]="helperService.rowTrackBy"
      [rowsPerPageOptions]="[50, 100, 250, 500]"
      selectionMode="multiple"
      (onRowExpand)="openRowInTable($event.data.id)"
      [(first)]="first"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [style.width]="col.width"
            [style.text-align]="col.align"
            [pSortableColumnDisabled]="
              col.field === 'select' || col.field === 'menu'
            "
          >
            <span *ngIf="col.field !== 'select' && col.field !== 'menu'">
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon
            ></span>
            <p-checkbox
              [ngModel]="isAllSelected | async"
              (onChange)="selectToggleAll()"
              styleClass="multi-select-chk-box"
              [binary]="true"
              *ngIf="col.field === 'select'"
            ></p-checkbox>
          </th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rowData
        let-expanded="expanded"
        let-columns="columns"
      >
        <tr [pSelectableRow]="rowData">
          <td
            *ngFor="let col of columns"
            [pRowToggler]="rowData"
            [pRowTogglerDisabled]="
              col.field === 'state' ||
              col.field === 'menu' ||
              col.field === 'select' ||
              col.field === 'invoicedAmount'
            "
            [style.text-align]="col.align"
            class="p-text-truncate"
            [pEditableColumn]="col.field === 'invoicedAmount'"
          >
            <span class="p-column-title">{{ col.header }}</span>

            <div
              *ngIf="col.field === 'sum'"
              title="{{
                +rowData.sumWork + +rowData.sumMaterial | currency: 'SEK'
              }} ex. moms"
            >
              {{ +rowData.sumWork + +rowData.sumMaterial | currency: 'SEK' }}
            </div>

            <div *ngIf="col.field === 'invoicedAmount'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input
                    appDisableArrowNavigation
                    type="text"
                    [(ngModel)]="rowData.invoicedAmount"
                    (blur)="invoicedAmountChange(rowData)"
                    (keydown.enter)="invoicedAmountChange(rowData)"
                    pInputText
                  />
                </ng-template>
                <ng-template pTemplate="output">
                  <span
                    title="{{
                      rowData.invoicedAmount | comma | currency: 'SEK'
                    }}"
                  >
                    {{ rowData.invoicedAmount | comma | currency: 'SEK' }}
                    <span
                      *ngIf="
                        +rowData.invoicedAmount &&
                        (+rowData.sumWork || +rowData.sumMaterial)
                      "
                    >
                      ({{
                        rowData.invoicedAmount /
                          (+rowData.sumWork + +rowData.sumMaterial)
                          | percent: '1.0-2'
                      }})
                    </span>
                  </span>
                </ng-template>
              </p-cellEditor>
            </div>
            <div *ngIf="col.field === 'created'" class="open-row">
              <span
                [pTooltip]="formatTime(rowData[col.field])"
                tooltipPosition="top"
                class="open-row"
                >{{ formatDate(rowData[col.field]) }}</span
              >
            </div>

            <div
              *ngIf="col.field === 'trueId'"
              class="open-row"
              [attr.id]="rowData.id"
            >
              <span class="open-row">
                {{ rowData[col.field] }}
              </span>
            </div>
            <span
              *ngIf="
                col.field !== 'state' &&
                col.field !== 'menu' &&
                col.field !== 'created' &&
                col.field !== 'trueId' &&
                col.field !== 'invoicedAmount' &&
                col.field !== 'sum'
              "
            >
              {{ rowData[col.field] }}</span
            >
            <p-dropdown
              *ngIf="status !== 0 && col.field === 'state'"
              appendTo="body"
              [(ngModel)]="rowData.state"
              [ngModelOptions]="{ standalone: true }"
              [options]="stateDropdown"
              [styleClass]="
                'table-dropdown offer-status-dropdown state-dropdown-background-' +
                rowData.state
              "
              (ngModelChange)="stateChange($event, rowData)"
            >
              <ng-template let-item pTemplate="selectedItem">
                <div class="dot {{ item?.dotClass }}" *ngIf="item?.dot"></div>
                {{ item.label }}
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="dot {{ item?.dotClass }}" *ngIf="item?.dot"></div>
                <div>{{ item.label }}</div>
              </ng-template>
            </p-dropdown>

            <div class="p-d-flex p-jc-end" *ngIf="col.field === 'menu'">
              <i
                *ngIf="rowData.isDigitallySigned === '1'"
                pTooltip="Offerten är signerad med BankID"
                tooltipPosition="top"
                class="icon-bankid"
              ></i>
              <i
                *ngIf="rowData.sended"
                [pTooltip]="
                  'Offerten är skickad\n' +
                  (rowData.sentSigningEvent?.created ?? '')
                "
                tooltipPosition="top"
                class="pi pi-send"
              ></i>
              <i
                *ngIf="rowData.isLocked"
                pTooltip="Offerten är låst"
                tooltipPosition="top"
                class="pi pi-lock"
              ></i>
              <i
                class="pi pi-user"
                [pTooltip]="
                  'Skapad av: ' +
                  rowData.user?.firstName +
                  ' ' +
                  rowData.user?.lastName
                "
                tooltipPosition="top"
              ></i>
              <i
                class="p-element pi pi-ellipsis-v"
                (click)="menu.toggle($event)"
              ></i>
              <p-menu
                #menu
                appendTo="body"
                [popup]="true"
                [model]="optionsMenusMap[rowData.id]"
              ></p-menu>
            </div>

            <p-checkbox
              [value]="rowData"
              [(ngModel)]="selectedOffers"
              (onChange)="isAllSelected.next(false)"
              styleClass="multi-select-chk-box"
              *ngIf="col.field === 'select'"
            ></p-checkbox>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <appForm2-list-dropDown
          ngModel="null"
          (ngModelChange)="handleSelectedOffers($event)"
          [htmlAttributes]="{ label: { value: 'Projekt' } }"
          [dropDownParams]="{
            options: handleSelectedOffersDropdown,
            disabled: selectedOffers.length < 1
          }"
        ></appForm2-list-dropDown>
      </ng-template>

      <ng-template pTemplate="paginatorleft" let-state>
        <h3 class="p-ml-3">
          Visar
          {{
            offerTable.rows > (dataSetAsync | async).length
              ? (dataSetAsync | async).length
              : offerTable.rows
          }}
          av {{ (dataSetAsync | async).length }}
        </h3>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr class="p-table-expanded-row">
          <td [attr.colspan]="offerTableColumns.length">
            <app-single-offer
              [offerMode]="'created'"
              [offerVariable]="{ id: +rowData['id'], type: '' }"
              [projectInfo]="projectInfo"
              (sideNavActions)="actionsFromChildComponents($event)"
            ></app-single-offer>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length">
            <app-emptyState
              [model]="'Offer'"
              [whereToCreate]="'topRight'"
            ></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
