import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectColorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectColorsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectColors?: Array<string | null> | null } | null };

export type NextProjectColorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NextProjectColorQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', nextProjectColor?: string | null } | null };

export const ProjectColorsDocument = gql`
    query projectColors {
  company {
    projectColors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectColorsGQL extends Apollo.Query<ProjectColorsQuery, ProjectColorsQueryVariables> {
    document = ProjectColorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NextProjectColorDocument = gql`
    query nextProjectColor {
  company {
    nextProjectColor
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NextProjectColorGQL extends Apollo.Query<NextProjectColorQuery, NextProjectColorQueryVariables> {
    document = NextProjectColorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }