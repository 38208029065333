import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  debounceTime,
  filter,
  first,
  Observable,
  pairwise,
  Subject,
  Subscription,
} from 'rxjs';
import {
  ProjectsDeletedLazyGQL,
  ProjectsLazyGQL,
  ProjectsLazyQueryVariables,
} from './graphql/projects.generated';
import { ProjectTypesGQL } from './graphql/projectTypes.generated';
import PROJECT_STATUS, {
  PROJECT_STATUS_LABELS,
} from 'app/shared/global/project-status.enum';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { DropdownSelectListItem } from 'app/shared/dropdown-select/dropdown-select';
import { AppDialogService, ProjectDataComponent } from 'app/shared/dialogs';
import { Menu } from 'primeng/menu';
import { ProjectSelectComponent } from 'app/shared/project-select/project-select.component';
import { ProjectListService } from './project-list.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { TableSortSettingsService } from 'app/shared/helpers/table-sort-settings.service';
import { ProjectMoveService } from '../project-move.service';
import { Project } from 'generated/types';
import PROJECT_DONE_STATE, {
  projectDoneStateLabels,
} from 'app/shared/global/project-done-state.enum';
import { CreateProjectGQL } from '../project-form/graphql/project-create.mutation.generated';
import moment from 'moment';
import { SplitIoService } from 'app/shared/split-io.service';

export interface Column {
  field: string;
  header: string;
  width?: string;
  active?: boolean;
  isCurrency?: boolean;
  hideIfWidthLessThan?: number;
  tooltip?: string;
}

export interface KpiColumns {
  label: string;
  items: Column[];
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt') public listTable: Table;
  @ViewChild('projectSelect', { static: true })
  private projectSelect: ProjectSelectComponent;

  @Input() public status = 1;

  public showCreateSidebar = false;
  public showExtendedSearch = false;
  public showCreateInternalSidebar = false;

  public allProjects: Project[] = [];
  public projects: Subject<Project[]> = new Subject();

  public projectTypeMap = {};
  public projectTypeList: {
    formattedName: string;
    id: string;
    prefix?: string;
    name?: string;
  }[] = [{ formattedName: 'Alla Projekttyper', id: null }];

  public count: number;
  public loading = true;

  public userFlags: UserFlags;
  public useProjectTypes = false;

  public infoColumns: Column[];
  public kpiColumns: KpiColumns[];
  public allColumns: Column[];

  public filters: FormGroup;

  public PROJECT_DONE_STATE = PROJECT_DONE_STATE;
  public doneStateLabels = projectDoneStateLabels;

  public actions = [
    {
      label: `Flytta till ${
        PROJECT_STATUS_LABELS[PROJECT_STATUS.ONGOING].label
      }`,
      value: PROJECT_STATUS.ONGOING,
      icon: 'pi pi-play',
    },
    {
      label: `Flytta till ${
        PROJECT_STATUS_LABELS[PROJECT_STATUS.PLANNED].label
      }`,
      value: PROJECT_STATUS.PLANNED,
      icon: 'pi pi-calendar',
    },
    {
      label: `Flytta till ${
        PROJECT_STATUS_LABELS[PROJECT_STATUS.FINISHED].label
      }`,
      value: PROJECT_STATUS.FINISHED,
      icon: 'pi pi-history',
    },
    {
      label: `Flytta till ${
        PROJECT_STATUS_LABELS[PROJECT_STATUS.ARCHIVED].label
      }`,
      value: PROJECT_STATUS.ARCHIVED,
      icon: 'pi pi-inbox',
    },
    { label: 'Radera', value: PROJECT_STATUS.DELETED, icon: 'pi pi-trash' },
  ];

  private deletedActions = [
    {
      label: 'Återställ',
      value: PROJECT_STATUS.DELETED,
      icon: 'pi pi-inbox',
    },
  ];

  private kpis: KpiColumns[] = [
    {
      label: 'Kostnader',
      items: [
        {
          header: 'Totala kostnader (lönebaserad)',
          field: 'costsSalaryBasedAmount',
          tooltip:
            'Totala kostnader för arbetad tid (baserat på lön), privata mil, traktamenten, mil, material/övriga kostnader, och omkostnader',
        },
        {
          header: 'Kostnader arbete (lönebaserad)',
          field: 'costsSalaryBasedWorkAmount',
          tooltip:
            'Kostnaden för arbetad tid baserat på internkostnad/yrkestyp',
        },
        {
          header: 'Totala kostnader (yrkestypschablon)',
          field: 'costsUserCostTypesBasedAmount',
          tooltip:
            'Totala kostnader för arbetad tid (baserat på internkostnad/yrkestyp), mil, och material/övriga kostnader',
        },
        {
          header: 'Kostnader arbete (yrkestypschablon)',
          field: 'costsUserCostTypesBasedWorkAmount',
          tooltip:
            'Kostnaden för arbetad tid baserat på internkostnad/yrkestyp',
        },
        {
          header: 'Kostnad mil',
          field: 'costsMilesAmount',
          tooltip: 'Kostnaden för rapporterade mil',
        },
        {
          header: 'Kostnader material/övriga kostnader',
          field: 'costsProductsAmount',
          tooltip: 'Totala kostnaden för material/övriga kostnader',
        },
      ],
    },
    {
      label: 'Intäkter',
      items: [
        {
          header: 'Totalt beräknade intäkter',
          field: 'revenuesTotalAmount',
          tooltip: 'Den totala förväntade intäkten för projektet',
        },
        {
          header: 'Beräknade intäkter arbete',
          field: 'revenuesWorkAmount',
          tooltip: 'Intäkt för arbetstid (löpande)',
        },
        {
          header: 'Beräknade intäkter material/övriga kostnader',
          field: 'revenuesProductsAmount',
          tooltip: 'Intäkt för material/övriga kostnader ink. påslag',
        },
        {
          header: 'Beräknade intäkter mil',
          field: 'revenuesMilesAmount',
          tooltip: 'Intäkt för registrerade mil (löpande)',
        },
        {
          header: 'Offererat belopp',
          field: 'revenuesOfferedAmount',
          tooltip: 'Offererat belopp i projektet',
        },
        {
          header: 'Totalt ÄTA belopp',
          field: 'revenuesATAAmount',
          tooltip: 'Summan av alla skapade ÄTA',
        },
        {
          header: 'Totalt accepterat ÄTA belopp',
          field: 'revenuesATAAcceptedAmount',
          tooltip: 'Summan av alla accepterade ÄTA',
        },
        {
          header: 'Totalt fakturerat ÄTA belopp',
          field: 'revenuesATAInvoicedAmount',
          tooltip: 'Summan av alla fakturerade ÄTA',
        },
      ],
    },
    {
      label: 'Fakturerat',
      items: [
        {
          header: 'Totalt fakturerat',
          field: 'invoicesTotalAmount',
          tooltip: 'Summan av alla skapade fakturor i projektet',
        },
        {
          header: 'Återstående fakturering',
          field: 'invoicesRemainingAmount',
          tooltip:
            'Det som återstår att fakturera i projektet. Baseras på Totala beräknade intäkter - totalt fakturerat. (Om summan är negativ har ni fakturerat mer än era totala beräknade intäkter)',
        },
        {
          header: 'Summa betalda fakturor',
          field: 'invoicesPaidAmount',
          tooltip:
            'Summan av alla fakturor som blivit slutbetalda på projektet',
        },
        {
          header: 'Summa ej betalda fakturor',
          field: 'invoicesUnpaidAmount',
          tooltip:
            'Summan av alla fakturor som ej blivit slutbetalda på projektet',
        },
        {
          header: 'Senast skapade fakturadatum',
          field: 'invoicesLatestInvoiceDate',
        },
        {
          header: 'Summa attesterade leverantörsfakturor',
          field: 'invoicesAttestedInvoiceSuppliersAmount',
          tooltip: 'Summan av alla attesterade leverantörsfakturor',
        },
        {
          header: 'Summa oattesterade leverantörsfakturor',
          field: 'invoicesUnattestedSupplierInvoicesAmount',
          tooltip:
            'Summan av alla ej attesterade leverantörsfakturor (Denna KPI är för hela projektet och tar inte hänsyn till datumsfiltret)',
        },
        {
          header: 'Totalt fakturerade timmar',
          field: 'invoicesHoursInvoicedSum',
          tooltip: 'Antalet timmar som blivit fakturerade i projektet',
        },
        {
          header: 'Timmar kvar att fakturera',
          field: 'invoicesHoursNotInvoicedSum',
          tooltip: 'Antalet timmar som inte blivit fakturerade i projektet',
        },
      ],
    },
    {
      label: 'Resultat',
      items: [
        {
          header: 'Förväntat resultat (lönebaserad)',
          field: 'resultsExpectedSalaryBasedAmount',
          tooltip:
            'Baseras på Totala beräknade intäkter - Totala kostnader, där kostnaderna för arbetstiden baseras på lön',
        },
        {
          header: 'Nuvarande resultat (lönebaserad)',
          field: 'invoicesResultsSalaryBasedAmount',
          tooltip:
            'Baseras på totalt fakturerat - totala kostnader, där kostnaderna för arbetstiden baseras på lön',
        },
        {
          header: 'Täckningsgrad förväntat resultat (lönebaserad)',
          field: 'resultsCoverageExpectedOfferedSalaryBasedPercentage',
          tooltip:
            'Förväntat resultats täckningsgrad i %, där kostnaderna för arbetstiden baseras på lön',
        },
        {
          header: 'Täckningsgrad nuvarande resultat (lönebaserad)',
          field: 'resultsCoverageInvoicedSalaryBasedPercentage',
          tooltip:
            'Nuvarande resultats täckningsgrad i %, där kostnaderna för arbetstiden baseras på lön',
        },
        {
          header: 'Förvantat resultat (yrkestypschablon)',
          field: 'resultsExpectedUserCostTypesBasedAmount',
          tooltip:
            'Baseras på Totala beräknade intäkter - Totala kostnader, där kostnaderna för arbetstiden baseras yrkestypers internkostnad',
        },
        {
          header: 'Nuvarande resultat (yrkestypschablon)',
          field: 'invoicesResultsUserCostTypesBasedAmount',
          tooltip:
            'Baseras på totalt fakturerat - totala kostnader, där kostnaderna för arbetstiden baseras yrkestypers internkostnad',
        },
        {
          header: 'Täckningsgrad förväntat resultat (yrkestypschablon)',
          field: 'resultsCoverageExpectedOfferedUserCostTypesBasedPercentage',
          tooltip:
            'Förväntat resultats täckningsgrad i %, där kostnaderna för arbetstiden baseras yrkestypers internkostnad',
        },
        {
          header: 'Täckningsgrad nuvarande resultat (yrkestypschablon)',
          field: 'resultsCoverageInvoicedUserCostTypesBasedPercentage',
          tooltip:
            'Nuvarande resultats täckningsgrad i %, där kostnaderna för arbetstiden baseras yrkestypers internkostnad',
        },
      ],
    },
    {
      label: 'Övrigt',
      items: [
        {
          header: 'Totala antal timmar',
          field: 'hoursTotalSum',
          tooltip: 'Totala antalet registrerade timmar i projekt',
        },
        {
          header: 'Totala antal normala timmar',
          field: 'hoursSum',
          tooltip: 'Totala antalet normala timmar i projekt',
        },
        {
          header: 'Totala antal extra timmar',
          field: 'hoursExtraSum',
          tooltip: 'Totala antalet extra timmar i projekt',
        },
        {
          header: 'Totala antal mil',
          field: 'milesTotalSum',
          tooltip: 'Totala antalet registrerade mil i projekt',
        },
        {
          header: 'Totala antal normala mil',
          field: 'milesSum',
          tooltip: 'Totala antalet normala mil i projekt',
        },
        {
          header: 'Totala antal extra mil',
          field: 'milesExtraSum',
          tooltip: 'Totala antalet extra mil i projekt',
        },
      ],
    },
  ];

  public useKpiSearch: boolean;
  public allExpanded = false;
  private get supportedSortFieldsInLazyLoad() {
    return [
      'trueId',
      'mark',
      'clientInfo',
      'establishmentInfo',
      'projectTypeInfo',
      ...this.kpis
        .flatMap(c => c.items)
        .filter(i => i.active)
        .map(i => i.field),
    ];
  }

  public PROJECT_STATUS = PROJECT_STATUS;

  public actionMenuMap: { [key: string]: MenuItem[] } = {};
  public allDoneMap: { [key: number]: boolean } = {};
  public doneCountMap: { [key: string]: number } = {};
  public showTotals = false;
  public totals: Project;
  public projectTypeId: number = null;
  public searchSubject = new Subject<string>();
  public searchString: string;
  public isProjectColorEnabled = false;
  private projects$: Subscription;

  constructor(
    private fetchprojectTypes: ProjectTypesGQL,
    private fetchProjectsDeletedLazy: ProjectsDeletedLazyGQL,
    private fetchProjectsLazy: ProjectsLazyGQL,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userFlagService: UserFlagsService,
    private dialogService: AppDialogService,
    protected service: ProjectListService,
    private tableSortSettingsService: TableSortSettingsService,
    private projectMoveService: ProjectMoveService,
    private createProjectGQL: CreateProjectGQL,
    private projectListService: ProjectListService,
    private splitIoService: SplitIoService
  ) {}

  public ngOnInit(): void {
    this.projects$ = this.projects.subscribe(ps => {
      this.setProjectIconsColumnData(ps);
    });

    this.userFlagService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
        this.useProjectTypes = flags.hasFlag('useProjectTypes');

        this.setStatusFromUrl();
      });

    this.getSortSettings();
    this.getProjectTypes();
    this.handleSearch();
    this.setColumns();
    this.filters = this.createFilterForm();

    this.splitIoService
      .getTreatment('project_color')
      .pipe(first())
      .subscribe(treatment => {
        this.isProjectColorEnabled = treatment === 'on';
      });

    this.handleRouteChangeInView();
  }
  public ngOnDestroy(): void {
    this.projects$.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(_event?): void {
    const screenWidth = window.innerWidth;

    for (const col of this.infoColumns ?? []) {
      if (!col.hideIfWidthLessThan) {
        continue;
      }
      if (col.hideIfWidthLessThan > screenWidth) {
        col.active = false;
      } else {
        col.active = true;
      }
    }
  }

  public reset(): void {
    this.projectListService.resetStates();

    const currentUrl = this.router.url;
    this.router
      .navigateByUrl('/', {
        skipLocationChange: true,
      })
      .then(() => {
        this.router.navigate([currentUrl], {
          state: { disableReuse: true },
        });
      });
  }

  private handleSearch(): void {
    this.searchSubject.pipe(debounceTime(500)).subscribe(searchValue => {
      this.searchString = searchValue;
      this.getProjects(null);
    });
  }

  private setProjectIconsColumnData(projects: Project[]): void {
    projects.forEach(p => {
      this.actionMenuMap[p.id] = this.generateActionMenu(p);
      this.allDoneMap[Number(p.id)] = this.allDone(p);
      this.doneCountMap[p.id] = this.doneCount(p);
    });
  }

  private handleRouteChangeInView(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(
        ([previousEvent, currentEvent]: [NavigationEnd, NavigationEnd]) => {
          const urlSame =
            currentEvent.urlAfterRedirects === previousEvent.urlAfterRedirects;
          if (urlSame) {
            return;
          }

          const newUrlSegment = currentEvent.urlAfterRedirects.split('/').pop();
          const navigatedToStatus = this.getStatusFromUrl(newUrlSegment);

          if (this.status === navigatedToStatus) {
            this.service.statusMap = {};
            this.service.statusMap[this.status] = true;
            this.getProjects();
          }
        }
      );
  }

  public getProjects(event?: LazyLoadEvent): void {
    this.loading = true;

    if (this.useKpiSearch) {
      this.getProjectsWithKpis(event);
      return;
    }

    const status = this.service.getStatusList();

    if (event?.sortField) {
      this.service.userSortSetting.attribute = event.sortField;
    }

    const savedSortField = this.supportedSortFieldsInLazyLoad.includes(
      this.service.userSortSetting.attribute
    )
      ? this.service.userSortSetting.attribute
      : 'trueId';
    const savedSortOrder = this.service.userSortSetting.ascDesc;

    const fetchVariables: ProjectsLazyQueryVariables = {
      status,
      offset: 0,
      first: savedSortOrder > 0 ? 100 : null,
      last: savedSortOrder < 0 ? 100 : null,
      sortColumn: savedSortField,
      typeId: null,
      search: null,
    };

    if (event) {
      if (event.sortOrder > 0) {
        fetchVariables.offset = event.first;
        fetchVariables.first = event.rows;
        fetchVariables.last = null;
      } else {
        fetchVariables.offset = event.first;
        fetchVariables.last = event.rows;
        fetchVariables.first = null;
      }
    }

    if (this.projectTypeId) {
      fetchVariables.typeId = [this.projectTypeId];
    }

    if (this.searchString) {
      fetchVariables.search = this.searchString;
    } else {
      fetchVariables.search = null;
    }

    if (this.status === PROJECT_STATUS.DELETED) {
      this.fetchProjectsDeletedLazy
        .fetch(fetchVariables)
        .pipe(first())
        .subscribe(data => {
          this.allProjects = [];
          data.data.company.projectsWithDeleted.edges.forEach(project => {
            this.allProjects.push(project.node as Project);
          });

          if (!fetchVariables.offset) {
            this.count = data.data.company.projectsWithDeleted.totalCount;
          }

          this.updateProjects();
          this.loading = false;
        });
    } else {
      this.fetchProjectsLazy
        .fetch(fetchVariables)
        .pipe(first())
        .subscribe(data => {
          this.allProjects = [];
          data.data.company.projects.edges.forEach(project => {
            this.allProjects.push(project.node as Project);
          });

          if (!fetchVariables.offset) {
            this.count = data.data.company.projects.totalCount;
          }

          this.updateProjects();
          this.loading = false;
        });
    }
  }

  public openProjectDataDialog(): void {
    this.dialogService.layout = 'wide';
    this.dialogService.openComponent(ProjectDataComponent, 'Data för projekt');
  }

  public onSelectProjectType(projectTypeId: number): void {
    this.projectTypeId = projectTypeId;
    this.getProjects();
  }

  private setStatusFromUrl(): void {
    this.activatedRoute.url.pipe(first()).subscribe(data => {
      this.status = this.getStatusFromUrl(data.shift().path);
      this.service.statusMap = {};
      this.service.statusMap[this.status] = true;
      if (this.status === PROJECT_STATUS.DELETED) {
        this.actions = this.deletedActions;
      } else {
        this.actions = this.actions.filter(
          action => action.value !== this.status
        );
      }
    });
  }

  private getStatusFromUrl(urlSegment: string): PROJECT_STATUS {
    switch (urlSegment) {
      case 'current':
        return PROJECT_STATUS.ONGOING;
      case 'planned':
        return PROJECT_STATUS.PLANNED;
      case 'finished':
        return PROJECT_STATUS.FINISHED;
      case 'archive':
        return PROJECT_STATUS.ARCHIVED;
      case 'internal':
        return PROJECT_STATUS.INHOUSE;
      case 'deleted':
        return PROJECT_STATUS.DELETED;
      default:
        return PROJECT_STATUS.ONGOING;
    }
  }

  public onProjectCreatedEvent(projectId: number): void {
    this.showCreateInternalSidebar = false;
    this.showCreateSidebar = false;
    setTimeout(() => this.router.navigate(['project', projectId]), 0);
  }

  public updateProjects(): void {
    const projects = this.allProjects.map(p => this.addFields(p));
    this.projects.next(projects);
    if (this.showTotals) {
      this.totals = this.getTotals(projects);
    }
  }

  public getProjectsWithKpis(event?: LazyLoadEvent): void {
    this.loading = true;

    this.useKpiSearch = true;

    if (event?.sortOrder) {
      this.service.userSortSetting.ascDesc = event.sortOrder;
    }
    if (event?.sortField) {
      this.service.userSortSetting.attribute = event.sortField.replace(
        'kpis.',
        ''
      );
    }

    const fetchVariables = {
      offset: 0,
      limit: 100,
    };

    if (event) {
      fetchVariables.limit = event.rows;
      fetchVariables.offset = event.first;
    }

    if (this.projectTypeId) {
      this.service.typeIds = [this.projectTypeId];
    } else {
      this.service.typeIds = [];
    }

    if (this.searchString) {
      this.service.search = this.searchString;
    } else {
      this.service.search = null;
    }

    this.service
      .fetchProjectsWithKPIs(
        this.infoColumns,
        this.kpiColumns,
        this.filters.value,
        fetchVariables
      )
      .pipe(first())
      .subscribe(res => {
        const data = res.data.company.projectsWithKpis.edges.map(n =>
          this.convertProjectWithKpiData(n.node)
        );
        this.allProjects = data;
        this.count = res.data.company.projectsWithKpis.totalCount;
        this.updateColumns();
        this.updateProjects();
        this.updateTotalsWithKpis(
          res.data.company.projectsWithKpisSummary?.kpis
        );
        this.setProjectIconsColumnData(this.allProjects);
        this.loading = false;
      });
  }

  private updateTotalsWithKpis(kpis: { [key: string]: string }): void {
    if (!kpis) {
      this.showTotals = false;
      return;
    }
    this.showTotals = true;
    const totals = { id: null };
    Object.entries(kpis).forEach(([key, value]) => {
      totals[`kpis.${key}`] = Number(value);
    });
    this.totals = totals;
  }

  private convertProjectWithKpiData(projectNode: any) {
    return {
      ...projectNode,
      todoCount: Number(projectNode.kpis.todosCount),
      notInvoicedDaysCount: Number(
        projectNode.kpis.invoicesDaysNotInvoicedCount
      ),
      unMovedSupplierInvoiceRowsSTAT: Number(
        projectNode.kpis.invoicesSupplierInvoiceRowsNotMovedToCostsCount
      ),
      supplierInvoiceRowsSTAT: Number(
        projectNode.kpis.invoicesSupplierInvoiceRowsCount
      ),
      todos: {
        totalCount: projectNode.kpis.todosCount,
        edges: [
          ...new Array(projectNode.kpis.todosNotDoneCount).fill({
            node: {
              done: false,
            },
          }),
          ...new Array(projectNode.kpis.todosDoneCount).fill({
            node: {
              done: true,
            },
          }),
        ],
      },
      kpis: {
        __typename: projectNode.kpis.__typename,
        ...Object.entries(projectNode.kpis)
          .filter(value => value[0] !== '__typename')
          .map(([key, value]) => ({
            [key]: Number(value),
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      },
    };
  }

  private addFields(p: Project) {
    const fieldsMap: { [key: string]: any } = {};

    this.allColumns
      .map(ac => ac.field)
      .forEach(f => (fieldsMap[f] = this.getValueAtPath(f, p)));

    return {
      ...p,
      ...fieldsMap,
      clientInfo: p.clientInfo,
      establishmentInfo: p.establishmentInfo,
    };
  }

  private getProjectTypes(): void {
    if (!this.userFlags?.hasFlag('useProjectTypes')) {
      return;
    }
    this.fetchprojectTypes
      .fetch()
      .pipe(first())
      .subscribe(data =>
        data.data.company.projectTypes.edges.forEach(type => {
          const id = type.node.id;

          const projectType = {
            formattedName: type.node.formattedName,
            id,
            prefix: type.node.prefix,
            name: type.node.name,
          };

          if (!this.projectTypeList.find(p => p.id === projectType.id)) {
            this.projectTypeList.push(projectType);
          }

          this.projectTypeMap[id] = type.node.formattedName;
        })
      );
  }

  public allDone(project: Project): boolean {
    return project.todos.edges.every(t => t?.node.done);
  }

  public doneCount(project: Project): number {
    return project.todos.edges.filter(t => t?.node.done).length;
  }

  public moveProjectToStatus(
    project: Project,
    status: PROJECT_STATUS
  ): Observable<boolean> {
    return this.projectMoveService.moveProject(project as Project, status);
  }

  public generateActionMenu(project: Project): MenuItem[] {
    return this.actions.map(action => {
      return {
        label: action.label,
        icon: action.icon,
        command: () => {
          this.moveProjectToStatus(project, action.value)
            .pipe(first())
            .subscribe(update => {
              if (update) {
                this.getProjects();
              }
            });
        },
      };
    });
  }

  public handleTableSelection(table: Table, dropdown: Dropdown, event): void {
    if (table.selection.length === 0 || !event.value) {
      return;
    }
    this.applyAction(table.selection, event.value).subscribe(() => {
      this.getProjects();
      table.selection = [];
      dropdown.clear(event);
    });
  }

  private applyAction(
    projects: Project[],
    status: number
  ): Observable<boolean> {
    return this.projectMoveService.moveProjects(projects as Project[], status);
  }

  private getSortSettings(): void {
    const savedSortSettings =
      this.tableSortSettingsService.getSortSettings('project');

    this.service.userSortSetting = {
      object: 'project',
      attribute: savedSortSettings?.column ?? 'trueId',
      ascDesc: savedSortSettings?.order ?? -1,
    };
  }

  public setSortSettings(table: Table): void {
    if (table.sortField.startsWith('kpis.')) {
      return;
    }

    this.service.userSortSetting.attribute = table.sortField;
    this.service.userSortSetting.ascDesc = table.sortOrder;

    this.tableSortSettingsService.saveSortSettings(
      'project',
      table.sortField,
      table.sortOrder
    );
  }

  public getValueAtPath(path: string, obj: any): any {
    return path.split('.').reduce((acc, c) => acc && acc[c], obj);
  }

  private setColumns(): void {
    this.infoColumns = this.getInfoColums();
    this.kpiColumns = this.getKpiColumns();

    this.updateColumns();
  }

  private updateColumns(): void {
    this.allColumns = [
      ...this.infoColumns,
      ...this.kpiColumns.flatMap(c => c.items).filter(c => c.active),
    ];
  }

  private getInfoColums(): Column[] {
    const columns: Column[] = [
      {
        field: 'trueId',
        header: 'ID',
        width: '8.5rem',
        active: true,
      },
      {
        field: 'mark',
        header: 'Märkning',
        width: '16rem',
        active: true,
      },
      {
        field: 'clientInfo',
        header: 'Kund',
        width: '15rem',
        active: true,
        hideIfWidthLessThan: 900,
      },
      {
        field: 'establishmentInfo',
        header: 'Arbetsställe',
        width: '15rem',
        active: true,
        hideIfWidthLessThan: 1100,
      },
      {
        field: 'projectTypeInfo',
        header: 'Typ',
        width: '8rem',
        active: true,
        hideIfWidthLessThan: 1400,
      },
      {
        field: 'created',
        header: 'Skapad datum',
        width: '8rem',
        active: false,
      },
      {
        field: 'startDate',
        header: 'Startdatum',
        width: '8rem',
        active: false,
      },
      {
        field: 'endDate',
        header: 'Slutdatum',
        width: '8rem',
        active: false,
      },
    ];
    if (!this.userFlags.hasFlag('useProjectTypes')) {
      columns.splice(4, 1);
    }
    return columns;
  }

  private getKpiColumns(): KpiColumns[] {
    return this.kpis.map(c => {
      return {
        label: c.label,
        items: c.items.map(kpi => ({
          field: `kpis.${kpi.field}`,
          header: kpi.header,
          width: '16rem',
          active: false,
          isCurrency: kpi.field.endsWith('Amount'),
          tooltip: kpi.tooltip,
        })),
      };
    });
  }

  private getTotals(projects: Project[]): Project {
    const totals: Project = { id: null };
    this.kpiColumns
      .flatMap(c => c.items)
      .map(
        c =>
          (totals[c.field] = projects
            .map(p => p[c.field])
            .reduce((acc, val) => acc + Number(val), 0))
      );
    return totals;
  }

  public onSelectedProjectDropdown(projectItem: DropdownSelectListItem): void {
    if (!projectItem.id) {
      return;
    }

    this.projectSelect.resetSelectedItem();
    this.router.navigate(['/project/' + projectItem.id]);
  }

  public openMenu(menu: Menu, event): void {
    menu.toggle(event);
    this.router.events.pipe(first()).subscribe(() => {
      menu.container.remove();
    });
  }

  private createFilterForm(): FormGroup {
    return new FormGroup({
      trueId: new FormControl(''),
      mark: new FormControl(''),
      clientInfo: new FormControl(''),
      establishmentInfo: new FormControl(''),
      orderNumber: new FormControl(''),
      madeBy: new FormControl(''),
    });
  }

  public actionExpandAll(): void {
    this.allExpanded = !this.allExpanded;
    if (this.allExpanded) {
      this.allProjects.forEach(p => {
        this.listTable.expandedRowKeys[p.id] = true;
      });
    } else {
      this.listTable.expandedRowKeys = {};
    }
  }

  public actionCreateInternalProject(mark: string): void {
    this.createProjectGQL
      .mutate({
        createProject: {
          mark: mark,
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(1, 'months').format('YYYY-MM-DD'),
          status: PROJECT_STATUS.INHOUSE,
        },
        createProjectClientContact: {},
        createProjectEstablishmentContact: {},
      })
      .pipe(first())
      .subscribe(res => {
        this.onProjectCreatedEvent(
          Number(res.data.projectTypeHyperionMutation.id)
        );
      });
  }
}
