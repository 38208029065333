import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProjectQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProjectQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', id: string, mark?: string | null, projectColor?: string | null, parentId?: number | null, isAta?: number | null, typeId?: number | null, startDate?: string | null, endDate?: string | null, status?: number | null, orderNumber?: string | null, offertSum?: number | null, offertSumWork?: number | null, offerId?: number | null, constructionSiteNumber?: string | null, madeBy?: string | null, establishmentContact?: { __typename?: 'Contact', trueId?: string | null, id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, city?: string | null, cityCode?: string | null } | null, clientContact?: { __typename?: 'Contact', trueId?: string | null, id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, city?: string | null, cityCode?: string | null } | null, externalIds?: { __typename?: 'ProjectExternalIdConnection', edges?: Array<{ __typename?: 'ProjectExternalIdEdge', node?: { __typename?: 'ProjectExternalId', id: string, source?: string | null, sourceId?: string | null, name?: string | null } | null } | null> | null } | null } | null };

export const GetProjectDocument = gql`
    query getProject($id: Int) {
  project(id: $id) {
    id
    mark
    projectColor
    parentId
    isAta
    typeId
    startDate
    endDate
    status
    orderNumber
    offertSum
    offertSumWork
    offerId
    constructionSiteNumber
    madeBy
    establishmentContact {
      trueId
      id
      name
      orderBuisnessName
      address
      city
      cityCode
    }
    clientContact {
      trueId
      id
      name
      orderBuisnessName
      address
      city
      cityCode
    }
    externalIds {
      edges {
        node {
          id
          source
          sourceId
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectGQL extends Apollo.Query<GetProjectQuery, GetProjectQueryVariables> {
    document = GetProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }