<div class="dropdown-select">
  <button
    class="p-button dropdown-select-dropdown-toggle"
    type="button"
    (click)="toggleExpanded()"
    [ngClass]="{ 'selected-item': selectedItem }"
    [disabled]="disabled"
  >
    <span *ngIf="!selectedItem">Välj {{ entityLabel }}</span>
    <div *ngIf="selectedItem" class="dropdown-select-selected-item">
      <app-project-color
        *ngIf="isProjectColorEnabled && selectedItem.metadata.projectColor"
        [color]="selectedItem.metadata.projectColor"
      ></app-project-color>
      <app-initial-icon
        [text]="selectedItem.label + selectedItem.displayId"
      ></app-initial-icon>
      <div class="dropdown-select-item-text">
        <span *ngIf="selectedItem.displayId"
          >{{ selectedItem.displayId }}
        </span>
        {{ selectedItem.label }}
      </div>
    </div>
    <i class="pi pi-fw pi-chevron-down"></i>
  </button>

  <div class="dropdown-select-overlay" [ngClass]="{ expanded: isExpanded }">
    <div class="dropdown-select-filter">
      <div class="p-inputgroup dropdown-select-filter-input">
        <input
          type="text"
          pInputText
          placeholder="Sök efter {{ entityLabel }} ..."
          [(ngModel)]="filterText"
          (ngModelChange)="onFilterTextChanged($event)"
          #filterInput
          (keydown.enter)="selectTopItem()"
        />
        <span class="p-inputgroup-addon"
          ><i [ngClass]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-search'"></i
        ></span>
      </div>
    </div>
    <div class="dropdown-select-list">
      <div *ngFor="let group of groups">
        <div class="dropdown-select-list-group" *ngIf="group.items.length">
          <div class="dropdown-select-list-group-label">
            {{ group.label }}
          </div>
          <div *ngFor="let item of group.items">
            <div
              class="dropdown-select-list-item"
              (click)="setSelected(item)"
              *ngIf="item.id !== selectedItem?.id"
            >
              <app-project-color
                *ngIf="isProjectColorEnabled && item?.metadata?.projectColor"
                [color]="item.metadata.projectColor"
              ></app-project-color>

              <app-initial-icon
                [text]="item.label + item.displayId"
              ></app-initial-icon>

              <div class="dropdown-select-item-text">
                <span *ngIf="item.displayId">{{ item.displayId }} </span>
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
