import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-color',
  templateUrl: './project-color.component.html',
  styleUrls: ['./project-color.component.scss'],
})
export class ProjectColorComponent {
  @Input() color: string;
}
