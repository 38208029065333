<p-dialog
  [(visible)]="isVisible"
  [header]="title"
  [modal]="true"
  (onHide)="handleCloseLightboxEvent()"
>
  <div>
    <form *ngIf="form" [formGroup]="form">
      <div class="content">
        <div class="cell-2 project-row">
          <div>
            <div>
              <label>Projekt-ID</label>
            </div>
            <div>
              <input pInputText type="text" formControlName="trueId" />
            </div>
          </div>
          <div>
            <div>
              <label>Märkning</label>
            </div>
            <div>
              <input pInputText type="text" formControlName="mark" />
            </div>
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Startdatum</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="startDate"
              dateFormat="yy-mm-dd"
              appendTo="body"
              [panelStyleClass]="'edit-project-calendar'"
              [locale]="datePickerLocale"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Slutdatum</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="endDate"
              dateFormat="yy-mm-dd"
              appendTo="body"
              [panelStyleClass]="'edit-project-calendar'"
              [locale]="datePickerLocale"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </div>

      <p-footer>
        <div class="buttons-grid p-ai-end">
          <div>
            <label>Kalenderfärg</label>
            <app-color-picker
              [form]="form"
              [controlName]="'color'"
            ></app-color-picker>
          </div>
          <div class="buttons">
            <button
              pButton
              type="submit"
              class="p-button-primary-hyperion button-width-update"
              [label]="!isDataLoading ? 'Uppdatera' : ''"
              [icon]="isDataLoading ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid || isDataLoading"
              [pTooltip]="validationText(validationTextList)"
              (click)="update()"
            ></button>
          </div>
        </div>
      </p-footer>
    </form>
  </div>
</p-dialog>
