<div class="p-grid">
  <div class="p-col-12">
    <h2>Fortnox integration</h2>
  </div>
  <div class="p-col-12">
    <p>Nedan kan du knyta ditt {{ appName }}konto till ditt konto i Fortnox.</p>
    <p>
      Klicka på knappen nedan och logga in i Fortnox. Sedan kommer du tillbaka
      till denna sida och kopplingen skapas.
    </p>
  </div>
  <div class="p-col-12 p-grid p-justify-center">
    <p-messages
      [value]="messages | async"
      [closable]="false"
      [escape]="false"
    ></p-messages>
  </div>
  <div
    class="p-jc-center p-col-12 p-grid"
    *ngIf="!isLoading && canConnect && authenticationUrl"
  >
    <button
      pButton
      type="button"
      class="p-button-primary-hyperion"
      (click)="authenticate()"
      [label]="enableFortnoxReactivation ? 'Återaktivera' : 'Koppla'"
      icon="pi pi-link"
      [disabled]=""
    ></button>
  </div>
</div>
