import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  EventColorsEnum,
  EventTextColorEnum,
  EventTypeEnum,
  MapTypeToDropdown,
  ProjectStatusEnum,
  ProjectSystemTypeCode,
} from '../planner-module-enums';
import {
  LightboxDropdown,
  ResourcesData,
  SchedulerEvent,
  SchedulerEventPlannedTime,
  SchedulerEventProject,
  SchedulerEventWorkTask,
} from '../planner-module-interfaces';
import {
  Project,
  ProjectTodo,
  TodoTopic,
  User,
  UserPlannedWork,
} from './planner-query-types';
import { SplitIoService } from 'app/shared/split-io.service';
import { first, noop } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlannerDataAdapterService {
  public isProjectColorEnabled = false;

  constructor(private splitIoService: SplitIoService) {
    try {
      this.splitIoService
        .getTreatment('project_color')
        .pipe(first())
        .subscribe(treatment => {
          this.isProjectColorEnabled = treatment === 'on';
        });
    } catch (e) {
      noop();
    }
  }

  public formatDateToString = (date: Date): string =>
    moment(date).format('YYYY-MM-DD HH:mm:ss');

  public setTextColor = (color: string | null): string => {
    if (!color) {
      return EventTextColorEnum.White;
    }

    switch (color.toUpperCase()) {
      case EventColorsEnum.LightGreen:
      case EventColorsEnum.Lime:
      case EventColorsEnum.Yellow:
      case EventColorsEnum.Amber:
      case EventColorsEnum.Orange:
        return EventTextColorEnum.Black;
      default:
        return EventTextColorEnum.White;
    }
  };

  public getDuration = (start: string, end: string): number => {
    const s = moment(start);
    const e = moment(end);
    const diff = moment.duration(e.diff(s)).asHours();
    return diff;
  };

  public getEdgeDates = (dates: string[]): { start: string; end: string } => {
    const sorted = dates.sort((a, b) => (a > b ? 1 : -1));
    const start = sorted.shift();
    const end = sorted.pop();

    return { start, end };
  };

  private createSchedulerEventPlannedTime = (
    item: UserPlannedWork,
    parentId: number
  ): SchedulerEventPlannedTime => {
    return {
      duration: this.getDuration(item.startDate, item.endDate),
      id: +item.id,
      progress: 0,
      start_date: moment(item.startDate)
        .set('hours', 0)
        .set('minutes', 0)
        .format('YYYY-MM-DD HH:mm'),
      realStartDate: item.startDate,
      end_date: moment(item.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .format('YYYY-MM-DD HH:mm'),
      realEndDate: item.endDate,
      text: item.messageToUser,
      type: 'task',
      parent: parentId,
      projectId: item.projectId,
      color: item.color ? item.color : EventColorsEnum.Blue,
      textColor: this.setTextColor(item.color),
      todoId: item.todoId,
      userId: item.userId,
      eventType: EventTypeEnum.PlannedTime,
    } as SchedulerEventPlannedTime;
  };

  public createSchedulerResourceEventPlannedTime = (
    item: UserPlannedWork,
    parentId: number
  ): SchedulerEventPlannedTime => {
    return {
      duration: this.getDuration(item.startDate, item.endDate),
      id: +item.id,
      progress: 0,
      start_date: moment(item.startDate)
        .set('hours', 0)
        .set('minutes', 0)
        .format('YYYY-MM-DD HH:mm'),
      realStartDate: item.startDate,
      end_date: moment(item.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .format('YYYY-MM-DD HH:mm'),
      realEndDate: item.endDate,
      text: item.messageToUser,
      type: 'task',
      parent: parentId,
      projectId: item.projectId,
      color: item.color ? item.color : EventColorsEnum.Blue,
      textColor: this.setTextColor(item.color),
      todoId: item.todoId,
      userId: item.userId,
      eventType: EventTypeEnum.PlannedTime,
    } as SchedulerEventPlannedTime;
  };

  public createSchedulerEventPlaceholder = (
    children: UserPlannedWork[],
    placeholderId: number,
    parentId: number,
    userId: number
  ): SchedulerEventPlannedTime => {
    const edgeDates = this.getEdgeDates([
      ...children.map(item => item.startDate),
      ...children.map(item => item.endDate),
    ]);
    return {
      duration: this.getDuration(edgeDates.start, edgeDates.end),
      id: placeholderId,
      start_date: moment(edgeDates.start)
        .set('hours', 0)
        .set('minutes', 0)
        .format('YYYY-MM-DD HH:mm'),
      end_date: moment(edgeDates.end)
        .set('hours', 23)
        .set('minutes', 59)
        .format('YYYY-MM-DD HH:mm'),
      text: this.getUserFullname(children[0].user),
      type: 'task',
      parent: +children[0].todoId || +parentId,
      projectId: +children[0].projectId,
      color: children[0].color ? children[0].color : EventColorsEnum.Blue,
      textColor: this.setTextColor(children[0].color),
      todoId: null,
      userId: userId,
      eventType: EventTypeEnum.Placeholder,
      render: 'split',
      open: false,
    } as SchedulerEventPlannedTime;
  };

  public createScheduledResourcePlaceholder = (
    plannedWork: UserPlannedWork,
    placeholderId: number,
    userFullName?: string
  ): SchedulerEventPlannedTime => {
    const placeholderUserEvent = {
      id: placeholderId,
      type: 'task',
      parent: 0,
      projectId: plannedWork.projectId,
      color: plannedWork.color ? plannedWork.color : EventColorsEnum.Blue,
      textColor: this.setTextColor(plannedWork.color),
      todoId: null,
      userId: plannedWork.userId,
      eventType: EventTypeEnum.Placeholder,
      render: 'split',
      open: false,
    } as SchedulerEventPlannedTime;

    if (userFullName) {
      placeholderUserEvent.text = userFullName;
    } else {
      placeholderUserEvent.text = this.getUserFullname(plannedWork.user) || '';
    }

    return placeholderUserEvent;
  };

  public mapDataToSchedulerEventProject(
    projects: Project[]
  ): SchedulerEventProject[] {
    if (!projects || projects.length === 0) {
      return [];
    }

    const time = '23:59';
    return projects.map(item => ({
      id: item.id ? item.id : null,
      trueId: item.trueId,
      projectColor: this.isProjectColorEnabled ? item.projectColor : null,
      end_date: moment(item.endDate + ' ' + time).format('YYYY-MM-DD HH:mm'),
      start_date: item.startDate,
      color: item.color ? item.color : EventColorsEnum.Blue,
      textColor: this.setTextColor(item.color),
      text: item.mark,
      eventType: EventTypeEnum.Project,
    }));
  }

  public mapDataToSchedulerEventPlannedTime(
    plannedTime: UserPlannedWork[]
  ): SchedulerEventPlannedTime[] {
    if (!plannedTime || plannedTime.length === 0) {
      return [];
    }

    return plannedTime.map(time => ({
      id: time.id ? time.id : null,
      end_date: time.endDate,
      start_date: time.startDate,
      text: time.messageToUser,
      color: time.color,
      textColor: this.setTextColor(time.color),
      projectId: time.projectId,
      todoId: time.todoId,
      userId: time.userId,
      eventType: EventTypeEnum.PlannedTime,
    }));
  }

  public mapTypeToDropdownList<
    T extends Project | User | ProjectTodo | TodoTopic
  >(data: Array<T>, type: MapTypeToDropdown): LightboxDropdown[] {
    if (!data || data.length === 0) {
      return [];
    }

    switch (type) {
      case MapTypeToDropdown.Projects:
        return (data as Project[]).map(item => ({
          label: item.trueId + ', ' + item.mark,
          value: item.id,
        }));
      case MapTypeToDropdown.Coworkers:
        return (data as User[]).map(item => ({
          label: item.firstName + ' ' + item.lastName,
          value: item.id,
        }));
      case MapTypeToDropdown.Todos:
        return (data as ProjectTodo[]).map(item => ({
          label: item.description,
          value: item.id,
        }));
      case MapTypeToDropdown.Todotopics:
        return (data as TodoTopic[]).map(item => ({
          label: item.Name,
          value: item.id,
        }));
    }
  }

  public mapSchedulerEventPlannedTimeToUserPlannedWork(
    event: SchedulerEventPlannedTime
  ): UserPlannedWork {
    if (!event) {
      return null;
    }

    return {
      breakDuration: 60,
      endDate: this.formatDateToString(new Date(event.end_date)),
      messageToUser: event.text,
      onWeekend: 0,
      color: event.color !== null ? event.color : EventColorsEnum.Blue,
      projectId: event.projectId,
      repeat: '',
      repeatEndDate: this.formatDateToString(new Date(event.end_date)),
      startDate: this.formatDateToString(new Date(event.start_date)),
      todoId: event.todoId,
      userId: event.userId,
      id: event.id ? +event.id : null,
    } as UserPlannedWork;
  }

  public mapDataToGanttEvent(projects: Project[]): SchedulerEventProject[] {
    if (!projects || projects.length === 0) {
      return [];
    }

    return projects.map(project => ({
      duration: this.getDuration(project.startDate, project.endDate),
      id: +project.id,
      progress: 0,
      start_date: project.startDate,
      end_date: moment(project.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .format('YYYY-MM-DD HH:mm'),
      text: project.trueId + ' - ' + project.mark,
      type: 'task',
      color: project.color ? project.color : EventColorsEnum.Blue,
      projectColor: this.isProjectColorEnabled ? project.projectColor : null,
      textColor: this.setTextColor(project.color),
      trueId: project.trueId,
      eventType:
        project.systemTypeCode === ProjectSystemTypeCode.PROJ
          ? EventTypeEnum.Project
          : EventTypeEnum.Placeholder,
    }));
  }

  public mapDataToGanttEventTodo(
    plannedWorks: UserPlannedWork[],
    getNewId: () => number
  ): SchedulerEventPlannedTime[] {
    if (!plannedWorks || plannedWorks.length === 0) {
      return [];
    }

    const events = [];
    const uniqueProjectIds = new Set(plannedWorks.map(x => x.projectId));

    uniqueProjectIds.forEach(pi => {
      const projectTodos = plannedWorks.filter(p => +p.projectId === pi);
      const uniqueTodoIds = new Set(
        projectTodos.map(p => p.todoId).filter(Boolean)
      );
      const plannedWorkWithNoTodo = projectTodos.filter(todo => !todo.todoId);
      const uniquieUserIdsNoTodo = new Set(
        plannedWorkWithNoTodo.map(x => +x.userId)
      );
      uniquieUserIdsNoTodo.forEach(userId => {
        const currentUser = plannedWorkWithNoTodo.filter(
          event => +event.userId === +userId
        );
        const placeholder: SchedulerEventPlannedTime =
          this.createSchedulerEventPlaceholder(
            currentUser,
            getNewId(),
            +pi,
            +userId
          );

        events.push(
          placeholder,
          ...currentUser.map(plannedWork =>
            this.createSchedulerEventPlannedTime(plannedWork, +placeholder.id)
          )
        );
      });

      uniqueTodoIds.forEach(todoId => {
        const currentTodo = projectTodos.filter(
          event => +event.todoId === +todoId
        );
        const uniqueUserIds = new Set(currentTodo.map(u => u.userId));
        uniqueUserIds.forEach(userId => {
          const currentUser = currentTodo.filter(
            event => +event.userId === +userId
          );
          const placeholder: SchedulerEventPlannedTime =
            this.createSchedulerEventPlaceholder(
              currentUser,
              getNewId(),
              +todoId,
              +userId
            );

          events.push(
            placeholder,
            ...currentUser.map(plannedWork =>
              this.createSchedulerEventPlannedTime(plannedWork, +placeholder.id)
            )
          );
        });
      });
    });
    return events;
  }

  public mapDataToGanttWorkTask(
    todos: ProjectTodo[]
  ): SchedulerEventWorkTask[] {
    if (!todos || todos.length === 0) {
      return [];
    }

    const data = todos.map(todo => {
      return {
        id: todo.id,
        text: todo.description,
        color: todo.color ? todo.color : EventColorsEnum.Blue,
        textColor: this.setTextColor(todo.color),
        end_date: moment(todo.endDate)
          .set('hours', 23)
          .set('minutes', 59)
          .format('YYYY-MM-DD HH:mm'),
        start_date: moment(todo.startDate).format('YYYY-MM-DD'),
        projectId: +todo.projectId,
        parent: +todo.projectId,
        headerId: +todo.topic?.id,
        done: todo.done,
        estimatedTime: +todo.estimatedTime,
        eventType: EventTypeEnum.WorkTask,
      } as SchedulerEventWorkTask;
    });
    return data;
  }

  public mapSchedulerEventWorkTaskToWorkTask(
    task: SchedulerEventWorkTask
  ): ProjectTodo {
    if (!task) {
      return null;
    }

    const isBeforeMidday = (date: string | Date): boolean =>
      moment(date).format('HH:mm') < '12:00';

    const start = isBeforeMidday(task.start_date)
      ? moment(task.start_date)
      : moment(task.start_date).add(1, 'days');
    const end = isBeforeMidday(task.end_date)
      ? moment(task.end_date).add(-1, 'day')
      : moment(task.end_date);

    return {
      id: +task.id,
      color: task.color,
      description: task.text,
      done: task.done,
      endDate: end.format('YYYY-MM-DD'),
      estimatedTime: task.estimatedTime,
      projectId: task.projectId,
      startDate: start.format('YYYY-MM-DD'),
      type: task.headerId,
    } as ProjectTodo;
  }

  public isSystemTypeProject(project: Project): boolean {
    return project.systemTypeCode !== ProjectSystemTypeCode.PROJ;
  }

  public removeInternalOnleaveProjects(projects: Project[]): Project[] {
    return projects.filter((project: Project) => {
      const internalOnleaveProject =
        project.systemTypeCode !== ProjectSystemTypeCode.PROJ &&
        project.status === ProjectStatusEnum.Leave;
      return !internalOnleaveProject;
    });
  }

  public mapProjectsDataToGanttResources(projectsData: any): any {
    const resourcesData: ResourcesData = {
      projects: [],
      users: [],
      plannedWork: [],
      todos: [],
      data: [],
    };

    projectsData.map(project => {
      resourcesData.projects.push({
        ...project,
        id: +project.id,
        trueId: +project.trueId,
      });

      project.plannedWork.map(pw => {
        if (!resourcesData.users.find(user => +user.id === +pw.user.id)) {
          resourcesData.users.push({ ...pw.user, id: +pw.user.id });
        }

        resourcesData.plannedWork.push({ ...pw, id: +pw.id });
      });

      project.todos?.map(todo => {
        resourcesData.todos.push({
          ...todo,
          id: +todo.id,
        });
      });
    });

    return resourcesData;
  }

  public mapDataWithInternalProjectsToSchedulerEvents(
    projects: Project[],
    projectsInternal: Project[]
  ): SchedulerEvent[] {
    const allProjects = [...projects, ...projectsInternal];

    const projectsPlannedTime = allProjects.flatMap(
      project => project.plannedWork
    );

    return [
      ...this.mapDataToSchedulerEventProject(projects),
      ...this.mapDataToSchedulerEventPlannedTime(projectsPlannedTime),
    ];
  }

  public mapDataWithInternalProjectsToGanttEvents(
    projects: Project[],
    projectsInternal: Project[],
    ganttUid: () => number
  ): SchedulerEvent[] {
    const allProjects = [...projects, ...projectsInternal];

    const projectsTodos = projects.flatMap(project => project.todos);
    const projectsPlannedTime = allProjects.flatMap(
      project => project.plannedWork
    );

    return [
      ...this.mapDataToGanttEvent(allProjects),
      ...this.mapDataToGanttWorkTask(projectsTodos),
      ...this.mapDataToGanttEventTodo(projectsPlannedTime, ganttUid),
    ];
  }

  public getUserFullname(user: User): string {
    return user.firstName + ' ' + user.lastName;
  }

  public mapUserPlannedWorkToResourcesData(
    resourcesData: ResourcesData,
    ganttUid: () => number
  ): ResourcesData {
    const { plannedWork, users } = resourcesData;

    plannedWork.forEach(pw => {
      users.forEach(user => {
        const placeholderUserEvent = this.createPlaceholderResourcesUserEvent(
          pw,
          user,
          ganttUid
        );

        if (placeholderUserEvent) {
          users.find(
            existingUser => +existingUser.id === +user.id
          ).placeholderId = placeholderUserEvent.id;

          resourcesData.data.push(placeholderUserEvent);
        }
      });

      const parentId = users.find(
        user => +user.id === +pw.user.id
      ).placeholderId;

      const plannedTimeEvent = this.createSchedulerResourceEventPlannedTime(
        pw,
        parentId
      );

      resourcesData.data.push(plannedTimeEvent);
    });

    return resourcesData;
  }

  /**
   * This will create a placeholder event which will be top level event
   * where all sub events will use its placeholderId as parent property
   *
   * @param plannedWork
   * @param user
   * @returns UserPlannedWork
   */
  private createPlaceholderResourcesUserEvent(
    plannedWork: UserPlannedWork,
    user: User,
    ganttUid: () => number
  ): SchedulerEventPlannedTime {
    if (!user.placeholderId && +user.id === +plannedWork.user.id) {
      const placeholderId = ganttUid();
      const placeholder: SchedulerEventPlannedTime =
        this.createScheduledResourcePlaceholder(plannedWork, placeholderId);

      return placeholder;
    }
  }
}
