import { gql } from 'apollo-angular';

import { GQLFragment } from 'app/shared/apollo/queries';
import { User } from 'app/shared/apollo/queries/user';
import { ProjectStatusEnum } from '../../planner-module-enums';

const FRAGMENT_PROJECTS_INTERNAL_WITH_PLANNED_TIME = gql`
  fragment ProjectsWithPlannedTime on ProjectConnection {
    totalCount
    edges {
      node {
        id
        trueId
        mark
        startDate
        endDate
        color
        status
        typeId
        systemTypeCode
        plannedWork(fromDate: $fromDate, toDate: $toDate) {
          edges {
            node {
              id
              color
              userId
              breakDuration
              projectId
              todoId
              startDate
              endDate
              messageToUser
              user {
                ...UserNameFullAndColor
              }
            }
          }
        }
      }
    }
  }
  ${User.fragments.fullNameAndColor}
` as unknown as GQLFragment;

const FRAGMENT_PROJECTS = gql`
  fragment Projects on ProjectConnection {
    totalCount
    edges {
      node {
        id
        trueId
        mark
        projectColor
        startDate
        endDate
        color
        status
        typeId
        systemTypeCode
        plannedWork {
          edges {
            node {
              id
              color
              userId
              breakDuration
              projectId
              todoId
              startDate
              endDate
              messageToUser
              user {
                ...UserNameFullAndColor
              }
            }
          }
        }
        todos {
          edges {
            node {
              id
              description
              done
              startDate
              endDate
              estimatedTime
              projectId
              color
              topic {
                id
                Name
              }
            }
          }
        }
      }
    }
  }
  ${User.fragments.fullNameAndColor}
` as unknown as GQLFragment;

const PLANNED_TIME_INTERNAL_PROJECTS_QUERY = {
  query: gql`
    query plannerInternalProjectsWithPlannedTimeQuery(
      $fromDate: String
      $toDate: String
    ) {
      company {
        id
        projectsInternal {
          ...ProjectsWithPlannedTime
        }
      }
    }
    ${FRAGMENT_PROJECTS_INTERNAL_WITH_PLANNED_TIME}
  `,
};

const PLANNED_TIME_QUERY = {
  query: gql`
    query plannerQuery($status: [Int], $fromDate: String, $toDate: String) {
      company {
        id
        projects(status: $status, fromDate: $fromDate, toDate: $toDate) {
          ...Projects
        }
      }
    }
    ${FRAGMENT_PROJECTS}
  `,
  testVariables: {
    status: [
      ProjectStatusEnum.Planned,
      ProjectStatusEnum.Ongoing,
      ProjectStatusEnum.Finished,
      ProjectStatusEnum.Leave,
      ProjectStatusEnum.Inhouse,
      ProjectStatusEnum.Archived,
    ],
    fromDate: '2020-01-01',
    toDate: '2021-01-01',
  },
};

const TODOTOPICS_QUERY = {
  query: gql`
    query todotopicsQuery {
      company {
        id
        todotopics {
          edges {
            node {
              id
              Name
            }
          }
        }
      }
    }
  `,
};

export const plannerQueries = {
  projectsQuery: PLANNED_TIME_QUERY,
  projectsInternalWithPlannedTimeQuery: PLANNED_TIME_INTERNAL_PROJECTS_QUERY,
  todotopicsQuery: TODOTOPICS_QUERY,
};
