import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import PROJECT_STATUS, {
  PROJECT_STATUS_LABELS,
} from 'app/shared/global/project-status.enum';
import { Contact } from '../../../generated/types';
import { FormControl, FormGroup } from '@angular/forms';
import { CreateProjectGQL } from './graphql/project-create.mutation.generated';
import { UpdateProjectGQL } from './graphql/project-update.mutation.generated';
import { GetProjectTypesGQL } from './graphql/project-types.query.generated';
import { first, map, BehaviorSubject } from 'rxjs';
import { ContactSelectGQL } from './graphql/contact-select.query.generated';
import { MessageService } from 'app/shared/message';
import moment from 'moment';
import {
  GetProjectQuery,
  GetProjectGQL,
} from './graphql/project-select.query.generated';

import { ContactFormContext } from '../../contact/contact-form/contact-form.component';

import { ExternalProject } from 'app/shared/company/derome-integration/external-project.interface';
import { ExternalProjectWithLabel } from 'app/shared/company/derome-integration/project-external-dropdown/external-project-with-label.interface';
import { UserFlags, UserFlagsService } from '../../user-flags.service';

import { CompanyInfo, CompanyInfoService } from 'app/shared/company';
import {
  DefaultTodoFragment,
  GetDefaultTodosGQL,
} from './graphql/project-default-todos.generated';

import { DeleteDefaultTodoGQL } from './graphql/project-default-todos.mutation.generated';

import {
  DropdownOfferFragment,
  GetOffersGQL,
} from './graphql/offer-select.query.generated';

import { FetchUsersGQL } from './graphql/user-select.query.generated';
import { ApolloError } from '@apollo/client/core';
import {
  NextProjectColorGQL,
  ProjectColorsGQL,
} from './graphql/project-colors.generated';
import { SplitIoService } from 'app/shared/split-io.service';

interface ProjectTypeDropdownOptions {
  name: string;
  value: number;
}

const ALLOWED_PROJECT_STATUS = [
  PROJECT_STATUS.PLANNED,
  PROJECT_STATUS.ONGOING,
  PROJECT_STATUS.FINISHED,
  PROJECT_STATUS.ARCHIVED,
];

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit, OnChanges {
  @Input() public open: boolean;
  @Input() set project(project: GetProjectQuery['project']) {
    this.setProjectData(project);
    this.initForm();
  }

  @Input() set projectId(id: string) {
    if (!id) {
      return;
    }
    this.id = id;
    this.fetchProject();
  }

  @Input() public set parentId(id: string) {
    this._parentId = id;
    this.setDataFromMainProject();
  }
  public get parentId(): string {
    return this._parentId;
  }

  private _parentId: string = null;

  public id: string;
  public contactFormContext = ContactFormContext;
  public projectForm: FormGroup;
  public projectData: GetProjectQuery['project'] = {
    id: null,
    status: PROJECT_STATUS.ONGOING,
  };
  public projectStatus = PROJECT_STATUS_LABELS.filter(s =>
    ALLOWED_PROJECT_STATUS.includes(s.value)
  );
  public PROJECT_STATUS = PROJECT_STATUS;
  public projectTypesDropdownOptions: ProjectTypeDropdownOptions[];
  public copyCustomer = false;
  public showProjectDialog = false;
  public showCreateClientDialog = false;
  public showCreateEstablishmentDialog = false;

  public userFlags: UserFlags;
  public useProjectTypes = false;
  public showProductExternalConnectInterface = true;
  public currentExternalProject: ExternalProjectWithLabel;
  private externalProjectId: string | null = null;
  private externalProjectSource: string | null = null;

  public defaultTodos: DefaultTodoFragment[] = [];
  public showCreateDefaultTodo = false;
  public showEditDefaultTodo = false;
  public defaultTodoEdit = new BehaviorSubject<boolean>(true);
  public defaultTodoId = new BehaviorSubject<string>('');

  public editDefaultTodoId: number;

  public offers: DropdownOfferFragment[];

  public moreIsExpanded = false;
  public defaultTodoIsExpanded = false;
  public projectColors: { name: string; value: string }[];

  @Output() public projectUpdatedEvent = new EventEmitter<number>();
  @Output() public projectCreatedEvent = new EventEmitter<number>();
  public userOptions: string[];

  public loading = false;
  public get isEdit(): boolean {
    return !!this.projectData?.id;
  }
  public isProjectColorEnabled = false;

  constructor(
    private getProject: GetProjectGQL,
    private createProjectService: CreateProjectGQL,
    private updateProjectService: UpdateProjectGQL,
    private getProjectTypesService: GetProjectTypesGQL,
    private contactSelectService: ContactSelectGQL,
    private messageService: MessageService,
    private userFlagsService: UserFlagsService,
    private companyInfoService: CompanyInfoService,
    private getDefaultTodosService: GetDefaultTodosGQL,
    private getOffersService: GetOffersGQL,
    private deleteDefaultTodosService: DeleteDefaultTodoGQL,
    private fetchUsersService: FetchUsersGQL,
    private getProjectColorsService: ProjectColorsGQL,
    private getNextProjectColorService: NextProjectColorGQL,
    private splitIoService: SplitIoService
  ) {}

  public ngOnInit(): void {
    this.initForm();
    this.getDefaultTodos();
    this.getOffers();
    this.getUsers();
    this.getProjectColors();
    this.splitIoService
      .getTreatment('project_color')
      .pipe(first())
      .subscribe(treatment => {
        this.isProjectColorEnabled = treatment === 'on';
      });

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
        this.useProjectTypes = flags.hasFlag('useProjectTypes');

        this.companyInfoService.companyInfo$.subscribe(
          (companyInfo: CompanyInfo) => {
            this.showProductExternalConnectInterface =
              companyInfo.showProductExternalConnectInterface;
            this.initForm();
          }
        );
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      const opened = changes.open.currentValue;
      if (opened && !this.isEdit && !this.parentId) {
        this.getNextProjectColorService
          .fetch()
          .pipe(first())
          .subscribe(res => {
            this.projectForm.controls.projectColor.setValue(
              res.data.company.nextProjectColor
            );
          });
      }
    }
  }

  public fetchProject(): void {
    this.getProject
      .fetch({ id: Number(this.id) })
      .pipe(first())
      .subscribe(p => {
        this.setProjectData(p.data.project);
        this.initForm();
      });
  }

  private setProjectData(project: GetProjectQuery['project']): void {
    if (project?.parentId) {
      this._parentId = String(project?.parentId);
    }
    this.projectData = {
      ...project,
    };
    if (project?.externalIds?.edges.length > 0) {
      const externalProject = project.externalIds.edges[0].node;
      this.currentExternalProject = {
        label: externalProject.sourceId + ' - ' + externalProject.name,
        value: {
          id: externalProject.id,
          source: externalProject.source,
          name: externalProject.name,
        },
      };
    }
  }

  private initForm(): void {
    if (!this.projectData.startDate) {
      this.projectData.startDate = moment().format('YYYY-MM-DD');
    }
    if (!this.projectData.endDate) {
      this.projectData.endDate = moment().add(1, 'month').format('YYYY-MM-DD');
    }

    this.projectForm = new FormGroup({
      mark: new FormControl(this.projectData.mark),
      projectColor: new FormControl(this.projectData.projectColor),
      projectType: new FormControl(this.projectData.typeId),
      startDate: new FormControl(this.projectData.startDate),
      endDate: new FormControl(this.projectData.endDate),
      status: new FormControl(this.projectData.status),
      madeBy: new FormControl(this.projectData.madeBy),
      orderNumber: new FormControl(this.projectData.orderNumber),
      offertSumWork: new FormControl(this.projectData.offertSumWork),
      offertSum: new FormControl(this.projectData.offertSum),
      constructionSiteNumber: new FormControl(
        this.projectData.constructionSiteNumber
      ),
      clientContact: new FormControl<Contact>(
        this.projectData.clientContact ?? { id: null }
      ),
      establishmentContact: new FormControl<Contact>(
        this.projectData.establishmentContact ?? { id: null }
      ),
      defaultTodos: new FormControl<number[]>([]),
      offerId: new FormControl<string>(String(this.projectData.offerId ?? '')),
      isAta: new FormControl<boolean>(this.projectData.isAta === 1),
    });

    this.setProjectTypesDropdownOptions();
  }

  private setProjectTypesDropdownOptions(): void {
    this.getProjectTypesService
      .fetch()
      .pipe(first())
      .subscribe(projectTypes => {
        this.projectTypesDropdownOptions = [
          { name: 'Ingen typ vald', value: null },
          ...projectTypes.data.company.projectTypes.edges.map(edge => ({
            name: `${edge.node.prefix}:${edge.node.name}`,
            value: Number(edge.node.id),
          })),
        ];
        this.projectForm.controls['projectType'].setValue(
          this.projectData.typeId
        );
      });
  }

  public onSelectedClientContact(contactId: number): void {
    this.setContact(contactId, 'clientContact');
  }

  public onSelectedEstablishmentContact(contactId: number): void {
    this.setContact(contactId, 'establishmentContact');
  }

  public onClientCreated(contactId: number): void {
    this.setContact(contactId, 'clientContact');
    this.showCreateClientDialog = false;
  }

  public onEstablishmentCreated(contactId: number): void {
    this.setContact(contactId, 'establishmentContact');
    this.showCreateEstablishmentDialog = false;
  }

  public onSubmit(): void {
    if (!this.projectForm.valid) {
      return;
    }

    this.loading = true;

    if (this.isEdit) {
      this.updateProject();
    } else {
      this.createProject();
    }
  }

  private setContact(contactId: number, formKey: string): void {
    this.contactSelectService
      .fetch({ contactId })
      .pipe(first())
      .subscribe(({ data }) => {
        const [contact]: Contact[] = data.company.allContacts.edges.map(
          ({ node }) => node
        );

        if (contact) {
          this.projectForm.patchValue({ [formKey]: contact });
        }
      });
  }

  private updateProject(): void {
    const formData = this.projectForm.value;

    this.updateProjectService
      .mutate({
        updateProject: {
          id: Number(this.projectData.id),
          mark: formData.mark,
          projectColor: formData.projectColor,
          madeBy: formData.madeBy,
          status: formData.status,
          offertSum: formData.offertSum,
          offertSumWork: formData.offertSumWork,
          orderNumber: formData.orderNumber,
          typeId: formData.projectType,
          startDate: formData.startDate,
          endDate: formData.endDate,
          constructionSiteNumber: formData.constructionSiteNumber,
          externalProjectId: this.externalProjectId,
          externalProjectSource: this.externalProjectSource,
          offerId: formData.offerId,
          isAta: formData.isAta,
        },
      })
      .pipe(first())
      .subscribe({
        next: ({ data }) => {
          this.loading = false;
          const mutationData = data.projectTypeHyperionMutation;
          const success = mutationData.mutationDetails[0].mutationSucceeded;

          if (success) {
            this.projectUpdatedEvent.emit(
              Number(data.projectTypeHyperionMutation.id)
            );
            this.resetForm();
          } else {
            this.messageService.insertDataFromMutation(mutationData);
          }
        },
        error: (error: ApolloError) => {
          this.loading = false;
          this.messageService.insertData({
            textArray: [error.message],
            type: 'error',
          });
        },
      });
  }

  private createProject(): void {
    const createProjectForm = this.projectForm.value;
    let { clientContact, establishmentContact } = createProjectForm;

    if (this.copyCustomer) {
      establishmentContact = clientContact;
    }

    this.createProjectService
      .mutate({
        createProject: {
          mark: createProjectForm.mark,
          projectColor: createProjectForm.projectColor,
          madeBy: createProjectForm.madeBy,
          status: createProjectForm.status,
          offertSum: createProjectForm.offertSum,
          offertSumWork: createProjectForm.offertSumWork,
          orderNumber: createProjectForm.orderNumber,
          typeId: createProjectForm.projectType,
          startDate: createProjectForm.startDate,
          endDate: createProjectForm.endDate,
          constructionSiteNumber: createProjectForm.constructionSiteNumber,
          externalProjectId: this.externalProjectId,
          externalProjectSource: this.externalProjectSource,
          defaultTodos: createProjectForm.defaultTodos,
          offerId: createProjectForm.offerId,
          parentId: this.parentId ? Number(this.parentId) : null,
          isAta: createProjectForm.isAta,
        },
        createProjectClientContact: {
          betalningsvillkor: clientContact.betalningsvillkor,
          orderBuisnessName: clientContact.orderBuisnessName,
          name: clientContact.name,
          orgNr: clientContact.orgNr,
          address: clientContact.address,
          address2: clientContact.address2,
          cityCode: clientContact.cityCode,
          city: clientContact.city,
          propertyName: clientContact.propertyName,
          housingAssociationOrgNumber:
            clientContact.housingAssociationOrgNumber,
          apartmentDesignation: clientContact.apartmentDesignation,
          phone: clientContact.phone,
          mobilePhone: clientContact.mobilePhone,
          mail: clientContact.mail,
          trueId: clientContact.trueId,
          contact: 0,
          contactType: clientContact.contactType,
        },
        createProjectEstablishmentContact: {
          betalningsvillkor: establishmentContact.betalningsvillkor,
          orderBuisnessName: establishmentContact.orderBuisnessName,
          name: establishmentContact.name,
          orgNr: establishmentContact.orgNr,
          address: establishmentContact.address,
          address2: establishmentContact.address2,
          cityCode: establishmentContact.cityCode,
          city: establishmentContact.city,
          propertyName: establishmentContact.propertyName,
          housingAssociationOrgNumber:
            establishmentContact.housingAssociationOrgNumber,
          apartmentDesignation: establishmentContact.apartmentDesignation,
          phone: establishmentContact.phone,
          mobilePhone: establishmentContact.mobilePhone,
          mail: establishmentContact.mail,
          trueId: establishmentContact.trueId,
          contact: 0,
          contactType: establishmentContact.contactType,
        },
      })
      .pipe(first())
      .subscribe({
        next: ({ data }) => {
          this.loading = false;
          const mutationData = data.projectTypeHyperionMutation;
          const success = mutationData.mutationDetails[0].mutationSucceeded;

          if (success) {
            this.projectCreatedEvent.emit(
              Number(data.projectTypeHyperionMutation.id)
            );
            this.resetForm();
          } else {
            this.messageService.insertDataFromMutation(mutationData);
          }
        },
        error: (error: ApolloError) => {
          this.loading = false;
          this.messageService.insertData({
            textArray: [error.message],
            type: 'error',
          });
        },
      });
  }

  public editTypes(): void {
    this.showProjectDialog = true;
  }

  public createClientDialog(): void {
    this.showCreateClientDialog = true;
  }

  public createEstablishmentDialog(): void {
    this.showCreateEstablishmentDialog = true;
  }

  public onProjectTypesUpdate(event: boolean): void {
    if (event) {
      this.setProjectTypesDropdownOptions();
    }
  }

  public setExternalProject(externalProject: ExternalProject): void {
    let source = null;
    let id = null;
    if (externalProject.id) {
      id = externalProject.id;
      source = externalProject.source;
    }
    this.externalProjectId = id;
    this.externalProjectSource = source;
  }

  public getProjectColors(): void {
    this.getProjectColorsService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        this.projectColors = res.data.company.projectColors.map(c => ({
          name: c,
          value: c,
        }));
      });
  }

  public getDefaultTodos(): void {
    this.getDefaultTodosService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        this.defaultTodos = res.data.company.projectDefualtTodos.edges.map(
          e => ({ ...e.node })
        );
        this.defaultTodos
          .filter(dt => dt.defaultForAllProjects === 1)
          .forEach(dt => {
            this.projectForm.controls.defaultTodos.value.push(dt.id);
          });
      });
  }

  public getOffers(): void {
    this.getOffersService
      .fetch({ status: [1] })
      .pipe(first())
      .subscribe(res => {
        this.offers = res.data.company.offers.edges.map(e => e.node);
        this.offers.push({ id: '', concerning: 'Välj offert' });
        this.offers.reverse();
      });
  }

  public editDefaultTodo(todoId: number): void {
    this.defaultTodoId.next(todoId.toString());
    this.showEditDefaultTodo = true;
  }

  public deleteDefaultTodo(todoId: number): void {
    this.deleteDefaultTodosService
      .mutate({
        id: Number(todoId),
      })
      .pipe(first())
      .subscribe(({ data }) => {
        const mutationData = data.projectDefualtTodoTypeHyperionMutation;
        const success = mutationData.mutationDetails[0].mutationSucceeded;

        this.messageService.insertDataFromMutation(mutationData);

        if (success) {
          this.getDefaultTodos();
        }
      });
  }

  private resetForm(): void {
    if (this.projectForm) {
      this.projectForm.reset();
      this.initForm();
    }

    this.moreIsExpanded = false;
    this.defaultTodoIsExpanded = false;
  }

  private getUsers(): void {
    this.fetchUsersService
      .fetch()
      .pipe(
        first(),
        map(res => res.data.company.users.edges.map(e => e.node))
      )
      .subscribe(
        users =>
          (this.userOptions = users.map(u => `${u.firstName} ${u.lastName}`))
      );
  }

  private setDataFromMainProject(): void {
    this.getProject
      .fetch({ id: Number(this.parentId) })
      .pipe(
        first(),
        map(res => res.data.project)
      )
      .subscribe(project => {
        const data = {
          ...project,
          mark: null,
          id: null,
          constructionSiteNumber: null,
          offerId: null,
          offertSum: null,
          offertSumWork: null,
        };
        this.setProjectData(data);
        this.initForm();
      });
  }
}
