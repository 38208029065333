<app-header [headerTemplate]="headerTemplate">
  <ng-template #headerTemplate>
    <span class="p-d-flex p-ai-center">
      <app-project-color
        *ngIf="isProjectColorEnabled"
        [color]="(projectData | async).projectColor"
        style="height: 1.5rem"
      ></app-project-color>
      {{
        ((projectData | async).trueId ?? '') +
          ' ' +
          ((projectData | async).mark ?? '')
      }}
    </span>
  </ng-template>

  <app-project-select
    [projectId]="(projectData | async).id"
    (selectedProjectEvent)="onSelectedProjectDropdown($event)"
  ></app-project-select>

  <button
    *ngIf="
      (projectData | async).parentId === null &&
      (projectData | async).status !== PROJECT_STATUS.Inhouse
    "
    pButton
    class="p-button-primary-hyperion"
    icon="pi pi-plus"
    label="Skapa underprojekt"
    (click)="openCreateSubproject()"
  ></button>
</app-header>
<p-sidebar [(visible)]="showCreateSubproject" position="right">
  <app-project-form
    [parentId]="projectId"
    [open]="showCreateSubproject"
    (projectCreatedEvent)="onProjectCreatedEvent($event)"
  ></app-project-form>
</p-sidebar>
<router-outlet></router-outlet>
