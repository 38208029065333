import { FormHandlerService } from '../../../shared/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'app/shared/message';
import {
  SetDeletedAttendanceTypeGQL,
  UpdateAttendanceTypeGQL,
} from './graphql/edit-attendance-type.generated';
import { first } from 'rxjs';

@Component({
  selector: 'CompanyAttendanceType-edit',
  templateUrl: 'company-attendance-type-edit.component.html',
  providers: [FormHandlerService],
})
export class EditAttendanceTypeComponent implements OnInit {
  @Input() public attendanceType;
  @Input() public costTypes: {
    id: number;
    name: string;
  }[] = [];
  @Input() public showAdvancedUseCostTypes = false;

  componentMainForm: FormGroup;
  private dataModel = 'companyUserCostType';
  private dataModelCapitalized = 'CompanyUserCostType';
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      id: '',
      code: '',
      name: '',
      description: '',
      selectedCostTypes: [],
      showInApp: true,
    },
  };
  public buttons;

  @Output() updated: EventEmitter<never> = new EventEmitter<never>();

  constructor(
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private deleteAttentanceTypeService: SetDeletedAttendanceTypeGQL,
    private updateAttendanceTypeService: UpdateAttendanceTypeGQL
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      restore: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };
    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler.groupSetLabelsRules(
      this.formFields,
      this.componentMainForm,
      this.attendanceType
    );
    this.componentMainForm.controls.code.disable();
    if (this.attendanceType.isDeleted) {
      this.componentMainForm.controls.showInApp.disable();
      this.componentMainForm.controls.name.disable();
      this.componentMainForm.controls.description.disable();
      this.componentMainForm.controls.selectedCostTypes.disable();
    }
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort närvarotypen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete();
      },
    });
  }

  private actionDelete() {
    this.formHandler.setLoadingClasses('start');
    this.buttons = this.formHandler.lockButtons(this.buttons, true);
    this.deleteAttentanceTypeService
      .mutate({
        id: this.attendanceType['id'],
        isDeleted: true,
      })
      .pipe(first())
      .subscribe(() => {
        this.updated.emit();
      });
  }

  public actionRestore() {
    this.formHandler.setLoadingClasses('start');
    this.buttons = this.formHandler.lockButtons(this.buttons);
    this.deleteAttentanceTypeService
      .mutate({
        id: this.attendanceType['id'],
        isDeleted: false,
      })
      .pipe(first())
      .subscribe(() => {
        this.updated.emit();
      });
  }

  public actionUpdate() {
    this.updateAttendanceTypeService
      .mutate(this.componentMainForm.value)
      .subscribe(res => {
        this.formHandler.unlockButtons(this.buttons);
        this.messageService.insertDataFromMutation(
          res.data.dayAttendanceTypeTypeHyperionMutation
        );
        this.updated.emit();
      });

    return false;
  }
}
