<div>
  <form
    class="p-grid"
    *ngIf="componentMainForm"
    [formGroup]="componentMainForm"
  >
    <div class="p-col-1 col-fixed" style="width: 80px"></div>
    <div
      class="p-col-12"
      [class.p-lg-2]="hasAccountingPlanFunction"
      [class.p-lg-4]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        formControlName="name"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyUserCostType_name'"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-2]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        formControlName="cost"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyUserCostType_cost'"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-2]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        formControlName="internalCost"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyUserCostType_internalCost'"
      />
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-2">
      <p-dropdown
        [filter]="true"
        formControlName="accountingPlanAccountId"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-2">
      <p-dropdown
        [filter]="true"
        formControlName="reverseVatAccountingPlanAccountId"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div class="p-col-6 p-lg-2 p-d-flex p-ai-center">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.create.disabled"
        (click)="actionCreate()"
        label="Lägg till"
        icon="pi pi-plus"
      ></button>
    </div>
  </form>
</div>
