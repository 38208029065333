<div class="header-container">
  <img class="icon" [src]="icon" />
  <h1 class="p-d-flex">
    <span style="margin-right: 0.75rem">{{ title | async }}</span>
    <ng-container *ngIf="headerTemplate; else plainHeader">
      <span style="color: var(--primary-color-500)">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </span>
    </ng-container>
    <ng-template #plainHeader>
      <span
        *ngIf="headerTextAsync | async"
        style="color: var(--primary-color-500)"
      >
        {{ headerTextAsync | async }}
      </span>
    </ng-template>
  </h1>
  <div class="action-section">
    <ng-content> </ng-content>
  </div>
</div>
<app-sub-menu class="p-d-block"></app-sub-menu>
