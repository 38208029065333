<form [formGroup]="dayForm" (ngSubmit)="onSubmit()">
  <div class="p-grid">
    <ng-container *ngIf="(dayData.id && !isFullDay) || !dayData.id">
      <div *ngIf="isEdit && !useOnlyTimestampTimeReport" class="p-col-4">
        <label>Datum</label>
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          dataType="string"
          [showIcon]="true"
          [maxDate]="isSelectedProjectInternal ? null : maxDate"
          inputId="icon"
          formControlName="date"
          [showWeek]="true"
        ></p-calendar>
      </div>
      <div *ngIf="!isEdit && !useOnlyTimestampTimeReport" class="p-col-4">
        <label>Datum</label>
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          dataType="string"
          [showIcon]="true"
          [maxDate]="isSelectedProjectInternal ? null : maxDate"
          inputId="icon"
          formControlName="date"
          selectionMode="multiple"
          (onFocus)="onDateFocus()"
          (onClose)="onDateClose()"
          [showWeek]="true"
        ></p-calendar>
      </div>
      <div *ngIf="useOnlyTimestampTimeReport" class="p-col-6">
        <label>Start</label>
        <p-calendar
          #cal
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          showTime="true"
          showSeconds="true"
          dataType="string"
          [showIcon]="true"
          [maxDate]="isSelectedProjectInternal ? null : maxDate"
          [formControl]="dayForm.controls.startTime"
          (onClose)="onChangeTime()"
          [showWeek]="true"
        ></p-calendar>
      </div>
      <div *ngIf="useOnlyTimestampTimeReport" class="p-col-6">
        <label>Slutdatum</label>
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          showTime="true"
          showSeconds="true"
          dataType="string"
          [showIcon]="true"
          [maxDate]="isSelectedProjectInternal ? null : maxDate"
          formControlName="stopTime"
          (onClose)="onChangeTime()"
          [showWeek]="true"
        ></p-calendar>
      </div>
      <div
        [class.p-col-8]="!useOnlyTimestampTimeReport"
        [class.p-col-12]="useOnlyTimestampTimeReport"
      >
        <label>Rapportera som</label>
        <div class="p-inputgroup">
          <p-dropdown
            name="userId"
            [options]="usersDropDownOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="userId"
            (onChange)="setUserCostTypeFromUserId($event.value)"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-col-4">
        <label>Projekt</label>
        <app-project-select
          class="p-d-block"
          [projectId]="dayForm.controls.projectId.value"
          (selectedProjectEvent)="onChangeProject($event)"
          [defaultAmount]="50"
        ></app-project-select>
      </div>
      <div class="p-col-8">
        <label>Arbetsmoment{{ forceTodo ? '*' : '' }}</label>
        <p-dropdown
          name="todoId"
          [options]="todosDropDownOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="todoId"
        ></p-dropdown>
      </div>

      <div class="p-col-6">
        <label>Utfört arbete*</label>
        <textarea
          type="text"
          placeholder="Beskriv arbetet.."
          formControlName="doneWork"
          [autoResize]="true"
          pInputTextarea
        ></textarea>
      </div>
      <div class="p-col-3">
        <label pTooltip="Löne / kostnadsgrundande timmar" tooltipPosition="top">
          Timmar
        </label>
        <input
          type="text"
          placeholder="0"
          formControlName="hours"
          (change)="onChangeHours()"
          pInputText
        />
      </div>
      <div class="p-col-3">
        <label pTooltip="Fakturerbara timmar" tooltipPosition="top">
          F. timmar
        </label>
        <input
          type="text"
          placeholder="0"
          formControlName="hoursToInvoice"
          pInputText
        />
      </div>

      <div [class]="setPrivMile ? 'p-col-4' : 'p-col-6'" *ngIf="setMile">
        <label pTooltip="Kostnadsgrundande mil" tooltipPosition="top">
          Mil
        </label>
        <input
          type="text"
          placeholder="0"
          formControlName="mile"
          (change)="onChangeMile()"
          pInputText
        />
      </div>
      <div
        [class]="setPrivMile ? 'p-col-4' : 'p-col-6'"
        *ngIf="setMile || (setPrivMile && !dontAddPrivMileToMileToInvoice)"
      >
        <label pTooltip="Fakturerbara mil" tooltipPosition="top">F. mil</label>
        <input
          type="text"
          placeholder="0"
          formControlName="mileToInvoice"
          pInputText
        />
      </div>

      <div class="p-col-4" *ngIf="setPrivMile">
        <label>Privata mil</label>
        <input
          type="text"
          placeholder="0"
          formControlName="privMile"
          (change)="onChangeMile()"
          pInputText
        />
      </div>

      <div *ngIf="useUserCostType" class="p-col-6">
        <label>Yrkestyp</label>
        <p-dropdown
          name="newCostTypeId"
          [options]="usersCostTypesDropDownOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="newCostTypeId"
          [filter]="true"
        ></p-dropdown>
      </div>

      <div *ngIf="usePickOvertimeOnTimereport" class="p-col-6">
        <label>Närvarotyp</label>
        <p-dropdown
          name="attendanceTypeId"
          [options]="attendanceTypesDropDownOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="attendanceTypeId"
          placeholder="Inget valt..."
          autoDisplayFirst="false"
        ></p-dropdown>
      </div>

      <div class="p-col-12">
        <p-checkbox
          label="Extra"
          [binary]="true"
          [formControl]="dayForm.controls.extra"
        ></p-checkbox>
      </div>
      <div class="p-col-12">
        <p-checkbox
          label="Traktamente heldag"
          [binary]="true"
          formControlName="subsistenceDay"
        ></p-checkbox>
      </div>
      <div class="p-col-12">
        <p-checkbox
          label="Traktamente halvdag"
          [binary]="true"
          formControlName="subsistenceHalfDay"
        ></p-checkbox>
      </div>
      <div class="p-col-12">
        <p-checkbox
          label="Traktamente natt"
          [binary]="true"
          formControlName="subsistenceNight"
        ></p-checkbox>
      </div>
    </ng-container>

    <ng-container *ngIf="canAddProducts">
      <p-divider></p-divider>
      <h3>Lägg till kostnader</h3>
      <p-message
        *ngIf="hasMultipleDates"
        severity="info"
        text="Kostnaderna nedan kommer skapas för varje datum som är valt ovan."
      ></p-message>
      <div class="p-col-12">
        <app-project-product-form
          #projectProductForm
          [isEmbedded]="true"
        ></app-project-product-form>
      </div>
      <p-divider></p-divider>
    </ng-container>

    <ng-container *ngIf="dayData.id && isFullDay">
      <div class="p-col-12">
        <label>Utfört arbete*</label>
        <textarea
          pInputTextarea
          placeholder="Beskriv arbetet.."
          formControlName="doneWork"
          rows="6"
        ></textarea>
      </div>
    </ng-container>

    <div class="p-d-flex p-jc-end p-col-12">
      <button
        pButton
        class="p-button-primary-hyperion"
        [label]="isEdit ? 'Uppdatera tidrapport' : 'Skapa tidrapport'"
        [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
        [disabled]="isLoading"
        type="submit"
      ></button>
    </div>
  </div>
</form>
