<app-header> </app-header>

<div class="page-container">
  <div class="p-grid">
    <div class="p-md-10 p-g-nopad">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <input
          type="text"
          pInputText
          [disabled]="loading"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Sök order"
        />
      </div>
    </div>
    <div class="p-inputgroup" class="p-md-2 p-g-nopad align-right">
      <app-button
        [class]="'pull-right'"
        (onClick)="showAdvancedSearch = !showAdvancedSearch"
        [disabled]="loading"
        [type]="'showExtendSearch'"
      ></app-button>
    </div>

    <div class="p-col-12" *ngIf="showAdvancedSearch">
      <p-accordion>
        <p-accordionTab header="Grunduppgifter" [selected]="true">
          <div class="p-grid">
            <div
              class="p-col-12 p-md-3"
              *ngFor="let searchAttribute of searchAttributes"
            >
              <!-- OBS naming-->
              <appForm2-simple-input
                [ngModel]="''"
                [htmlAttributes]="{
                  label: { value: searchAttribute['header'] }
                }"
                (ngModelChange)="
                  orderTable.filter(
                    $event,
                    searchAttribute['field'],
                    searchAttribute['type']
                  )
                "
              ></appForm2-simple-input>
            </div>
            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <label class="form-control-label">Projekt</label>
                <p-multiSelect
                  [options]="projects"
                  defaultLabel="Välj projekt"
                  [style]="{ width: '100%' }"
                  (onChange)="dt.filter($event.value, 'projectId', 'in')"
                  selectedItemsLabel="{0} valda projekt"
                  [overlayOptions]="{ appendTo: 'body' }"
                ></p-multiSelect>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="p-col-12">
      <div class="p-col-12 p-md-3">
        <span
          class="link"
          [style.text-decoration]="
            selectedFastFilter === 'all' ? 'underline' : ''
          "
          (click)="fastFilter('all', dt)"
          >ALLA</span
        >
        /
        <span
          class="link"
          [style.text-decoration]="
            selectedFastFilter === 'saved' ? 'underline' : ''
          "
          (click)="fastFilter('saved', dt)"
          >SPARADE</span
        >
        /
        <span
          class="link"
          [style.text-decoration]="
            selectedFastFilter === 'unsaved' ? 'underline' : ''
          "
          (click)="fastFilter('unsaved', dt)"
          >EJ SPARADE</span
        >
      </div>
      <div class="p-col-12 p-md-9">
        <div style="float: right">
          <span
            class="link"
            (click)="fastSupplierFilter(0, dt)"
            [style.text-decoration]="
              selectedSupplierFilter === 0 ? 'underline' : ''
            "
            >Alla</span
          >
          /
          <span *ngFor="let companyEdi of supplierList; index as i">
            <span
              class="link"
              (click)="fastSupplierFilter(companyEdi['id'], dt)"
              [style.text-decoration]="
                selectedSupplierFilter === companyEdi['id'] ? 'underline' : ''
              "
            >
              {{ companyEdi['supplier']['name'] | capitalize }}
            </span>
            <span *ngIf="i + 1 < supplierList.length">/</span>
          </span>
        </div>
      </div>
    </div>

    <div class="p-col-12"><spinner *ngIf="loading"></spinner></div>

    <div class="p-col-12" [style.display]="loading ? 'none' : 'block'">
      <p-table
        [value]="orders"
        [rowHover]="true"
        #dt
        dataKey="id"
        [columns]="cols"
        [rowTrackBy]="helperService.rowTrackBy"
        styleClass="expand-rows"
        [sortField]="sort.attribute"
        [sortOrder]="sort.ascDesc"
        (onSort)="changeSort($event)"
        paginatorPosition="both"
        [paginator]="true"
        [rowsPerPageOptions]="[50, 100, 250, 500]"
        [rows]="50"
        [responsive]="true"
        [(selection)]="selectedOrders"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="paginatorleft">
          <appForm2-list-dropDown
            ngModel="null"
            (ngModelChange)="confirmSaveOrderToProject($event)"
            [htmlAttributes]="{ label: { value: 'Välj projekt' } }"
            [dropDownParams]="{ options: projects, filter: true }"
          ></appForm2-list-dropDown>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 40px"></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template
          pTemplate="body"
          let-rowData
          let-expanded="expanded"
          let-columns="columns"
        >
          <tr>
            <td>
              <p-tableCheckbox
                *ngIf="!rowData['projectId']"
                [value]="rowData"
              ></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [pRowToggler]="rowData">
              <span class="p-column-title">{{ col.header }}</span>

              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr class="p-table-expanded-row">
            <td
              [attr.colspan]="cols.length + 1"
              style="background-color: white"
            >
              <app-order [rowData]="rowData" [projects]="projects"></app-order>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="emptyStateTR">
            <td [attr.colspan]="columns.length + 1">
              <app-emptyState></app-emptyState>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
