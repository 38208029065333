import { Apollo } from 'apollo-angular';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subscription, first } from 'rxjs';

import { getRefetchQuerieOf, queries } from 'app/shared/apollo/queries/index';
import { ApolloMutationService } from 'app/shared/index';
import { MessageService } from 'app/shared/message';
import { FormHandlerService } from 'app/shared/forms';
import { SingleOfferService } from '../single-offer.service';
import { HtmlModalService } from 'app/shared/html-modal';
import { GlobalService } from 'app/shared/global';
import { HttpService } from 'app/shared/http';
import { SortService } from 'app/store/sort.service';
import { CompanyFunctionsService } from 'app/shared/company';
import { OfferRowHeaderInfo } from 'app/offer/single/offer-row-header-info';
import { OfferSignDialogComponent } from '../offer-sign-dialog/offer-sign-dialog.component';
import { AppDialogService } from 'app/shared/dialogs';
import { FeaturePreviewDigitalSignatureComponent } from 'app/feature-preview/feature-preview-digital-signature/feature-preview-digital-signature.component';
import { UserFlagsService } from 'app/user-flags.service';
import { CustomSort } from 'app/store/custom-sort';
import { CopyOfferGQL } from '../graphql/single-offer.generated';
import { FetchSingleOfferForListGQL } from 'app/offer/index/graphql/offer-list.generated';

const OFFER_TYPE_ATA = 'ata';

@Component({
  selector: 'app-offer-side-nav',
  templateUrl: './offer-side-nav.component.html',
  styleUrls: ['./offer-side-nav.component.scss'],
  providers: [FormHandlerService, HttpService],
})
export class OfferSideNavComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public offerMode:
    | 'create'
    | 'created'
    | 'createdFromTemplate'
    | 'template';
  @Input() public offer;
  @Input() public projectInfo;
  @Input() public activeSection;
  @Input() public offerRowHeaderInfo: OfferRowHeaderInfo;
  @Input() public hasDeromeWebshopProducts: boolean;
  @Output() public showPreview = new EventEmitter(false);
  @Output() public sideNavActions = new EventEmitter();
  @Output() public updatedAttr = new EventEmitter();
  @Output() public scrollToSection: EventEmitter<number> = new EventEmitter();

  public showInput = false;
  public hideButtons: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public btnlockedAsyn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showBtnsForTemplate: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public showEditTemplateBtns: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public updateBtnItems: {
    label: string;
    icon: string;
    command: () => void;
  }[];
  public createProjectBtn: {
    label: string;
    icon: string;
    command: () => void;
  }[] = [];
  public PDFbtnItems: {
    label: string;
    icon: string;
    command: () => void;
  }[];

  public componentMainForm: FormGroup;
  private formFields = {
    model: 'Offer',
    attributes: {
      templateName: '',
    },
  };
  public autoSaveOptions: CustomSort = {
    attribute: 'null',
    ascDesc: 1,
    object: 'offer_object_autoSave',
  };
  private autoSaveInterval: number;
  private routeSub: Subscription;
  private offerUpdateSub: Subscription;
  private variables;
  private projectFromOffer: boolean;
  private offerCopied: boolean;
  private selectedVersionOfferId: string;
  private offerTypeName: 'Offert' | 'ÄTA';

  public useDigitalSignature: boolean;

  constructor(
    public router: Router,
    private angularApollo: Apollo,
    private httpService: HttpService,
    private sortService: SortService,
    private activeRoute: ActivatedRoute,
    private globalService: GlobalService,
    private messageService: MessageService,
    private formHandler: FormHandlerService,
    private htmlModalService: HtmlModalService,
    private singleOfferService: SingleOfferService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private companyFunctionsService: CompanyFunctionsService,
    private dialogService: AppDialogService,
    private userFlagsService: UserFlagsService,
    private copyOfferService: CopyOfferGQL,
    private fetchSingleOfferForListService: FetchSingleOfferForListGQL
  ) {
    this.initForm();
  }

  public ngOnInit() {
    this.setOfferTypeNameAndSplitBtns();

    this.autoSaveOptions = this.sortService.getSort(this.autoSaveOptions);
    this.autoSaveOptions.ascDesc = this.autoSaveOptions.ascDesc === 1 ? 1 : 0;

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.useDigitalSignature = flags.hasFlag('useDigitalSignature');
      });

    if (this.offer['type'] === 'offer') {
      this.subscribeToRouteParams();
    }
    if (this.offerMode === 'created') {
      this.startAutoSaveInterval();
    }

    this.subscribeToOfferMethodsRights();

    if (this.offer?.offerAttr) {
      this.offer.offerAttr = this.renameOfferAttributeLabels(
        this.offer.offerAttr
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['offerMode'] !== 'undefined') {
      this.showBtnsForTemplate.next(false);
      this.showEditTemplateBtns.next(false);
      if (
        this.offerMode === 'create' ||
        this.offerMode === 'template' ||
        this.offerMode === 'createdFromTemplate'
      ) {
        this.showBtnsForTemplate.next(true);
        this.offerMode === 'template' && this.showEditTemplateBtns.next(true);
      }
    }

    if (changes.offer) {
      this.offer.typeOffer = changes.offer.currentValue.typeOffer;
    }
  }

  private renameOfferAttributeLabels(offerAttr: any[]): any[] {
    return offerAttr.map(row => {
      if (row.label === 'Timkostnad') {
        row.label = 'Övriga kostnader ÄTA';
      }

      return row;
    });
  }

  private setOfferTypeNameAndSplitBtns() {
    this.offerTypeName = 'Offert';
    if (this.offer['type'] === OFFER_TYPE_ATA) {
      this.offerTypeName = 'ÄTA';
    }
  }

  private subscribeToOfferMethodsRights() {
    // listen if you are granted to update and change status
    this.offerUpdateSub = this.singleOfferService.allowOfferUpdate$.subscribe(
      permissionGranted => {
        if (permissionGranted) {
          !this.projectFromOffer &&
            !this.offerCopied &&
            this.variables &&
            this.actionUpdate(this.variables);
          !this.offerCopied &&
            this.projectFromOffer &&
            this.confirmCreateProjectFromOffer();
          !this.projectFromOffer &&
            this.offerCopied &&
            this.copyOfferAfterUpdate();
        }
      }
    );
  }

  private subscribeToRouteParams() {
    this.routeSub = this.activeRoute.params.subscribe(routeParam => {
      this.hideButtons.next(false);
      let setBtn = {
        label: 'Arkivera offerten',
        _func: 2,
      };
      if (routeParam['status'] === '2') {
        setBtn = {
          label: 'Flytta till skapade',
          _func: 1,
        };
      }
      routeParam['status'] === '0' && this.hideButtons.next(true);

      this.createProjectBtn.push({
        label: setBtn['label'],
        icon: 'fa fa-angle-double-right',
        command: () => {
          this.changeOfferStatus(setBtn['_func']);
        },
      });
    });
  }

  private initForm(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  public createTemplate() {
    const templateName =
      this.componentMainForm['controls']['templateName']['value'];
    if (!this.showInput) {
      this.showInput = true;
    } else {
      if (this.formHandler.formValid([this.componentMainForm])) {
        this.variables = {
          status: 1,
          id: +this.offer['id'],
          type: 'template',
          templateName,
        };
        this.setVariablesAndEmitNavAction(
          'updateAndTriggerCreate',
          {},
          null,
          true
        ); // empty object is needed for listening to changes
      }
    }
  }

  public updateTemplate(status) {
    const type = this.offer['type'] === OFFER_TYPE_ATA ? 'ata' : 'offer';
    const shouldNavigateAfterQuery =
      this.offer['type'] === OFFER_TYPE_ATA ? false : true;
    this.variables = { status, id: +this.offer['id'], type };
    this.setVariablesAndEmitNavAction(
      'update',
      this.variables,
      shouldNavigateAfterQuery
    );
  }

  public setVariablesAndEmitNavAction(
    action,
    variables = null,
    shouldNavigateAfterQuery = null,
    callCreate = false
  ) {
    variables = shouldNavigateAfterQuery
      ? { ...variables, navigate: true }
      : variables;
    this.sideNavActions.emit({
      value: this.offer['id'],
      name: action,
      variables,
      callCreate: callCreate,
    });
  }

  public onDragAndDropMenu(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.offer['offerAttr'],
      event.previousIndex,
      event.currentIndex
    );
    // reset offer order of object by mapping through with index + 1
    const newOrder = this.offer['offerAttr'].map((offer, index) => ({
      ...offer,
      order: index + 1,
    }));
    // update old offerAttr, send the new offer attr with order reset to parent
    setTimeout(() => {
      this.updatedAttr.emit(newOrder);
    }, 0);
  }

  public updateRowActiveState(rowOrderAsId: number, event: any): void {
    const newAction = this.offer['offerAttr'].map((offer, index) => {
      if (offer['order'] !== rowOrderAsId) {
        return offer;
      }
      return { ...offer, active: +event.checked };
    });
    setTimeout(() => {
      this.updatedAttr.emit(newAction);
    }, 0);
  }

  public downloadPdf(): void {
    const urlParam =
      this.globalService.getUrlPrefix() +
      '/offer/Print/type/getPDF/id/' +
      this.offer['id'];
    location.href = urlParam;
  }

  public showPdfInModal(): void {
    this.setVariablesAndEmitNavAction('update');
    setTimeout(() => {
      const urlParam =
        this.globalService.getUrlPrefix() +
        '/offer/Print/type/showPDF/id/' +
        this.offer['id'];
      this.htmlModalService.ny_sida(urlParam, 900, 800);
    }, 500);
  }

  public showMailModule(): void {
    this.sideNavActions.emit({ name: 'mailModule' });
  }

  public copyOffer() {
    this.offerCopied = true;
    this.setVariablesAndEmitNavAction('update', {}); // empty object is needed for listening to changes
  }

  public createProjectFromOffer(): void {
    this.projectFromOffer = true;
    this.setVariablesAndEmitNavAction('update', {}); // empty object is needed for listening to changes
  }

  private copyOfferAfterUpdate() {
    this.offerCopied = false;

    this.copyOfferService
      .mutate({
        offerId: this.offer.id,
      })
      .subscribe(res => {
        if (res.errors) {
          this.messageService.insertData({
            textArray: res.errors,
            time: 2000,
            type: 'error',
          });
        } else {
          this.fetchSingleOfferForListService
            .fetch({
              id: Number(res.data.copyOfferMutation.id),
            })
            .pipe(first())
            .subscribe(res => {
              this.sideNavActions.emit({
                value: res.data.offer,
                name: 'copiedOffer',
              });
            });
        }
      });
  }

  public changeOfferStatus(status) {
    this.confirmationService.confirm({
      message:
        status === 1
          ? 'Vill du flytta offerten till skapade?'
          : 'Vill du arkivera offerten?',
      header: 'Bekräfta val',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.updateTemplate(status);
      },
      reject: () => {
        this.btnlockedAsyn.next(false);
      },
    });
  }

  public confirmCopyOffer() {
    this.confirmationService.confirm({
      message: `Vill du kopiera ${this.getNameToDisplay()} ?`,
      header: 'Bekräfta val',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.copyOffer();
      },
      reject: () => {},
    });
  }

  private confirmCreateProjectFromOffer() {
    const pickProducts = this.companyFunctionsService.companyFunctionIsSet(
      'pickProductsFromOffer'
    );
    if (pickProducts) {
      this.confirmationService.confirm({
        message: `Spara produkter till projekt? ?`,
        header: 'Bekräfta val',
        icon: 'fa fa-floppy-o',
        accept: () => {
          this.CreateProjectFromOffer(true);
        },
        reject: () => {
          this.CreateProjectFromOffer(false);
        },
      });
    } else {
      this.CreateProjectFromOffer(false);
    }
  }

  public CreateProjectFromOffer(value: boolean) {
    this.createProjectHttpCall(value);
  }

  private createProjectHttpCall(pickProducts = false) {
    this.projectFromOffer = true;
    this.btnlockedAsyn.next(true);

    this.messageService.insertData({
      summary: 'Skapar projekt...',
      type: 'info',
    });

    const url = this.router
      .createUrlTree(['offer', 'createProject'])
      .toString();
    const data = {
      id: this.offer['id'],
      pickProducts: pickProducts,
    };

    this.httpService.makeHttpPostRequest(url, data).subscribe(response => {
      if (response['success']) {
        const projectUrl = this.router
          .createUrlTree(['v2', 'project', response['project_id']], {})
          .toString();
        location.href = projectUrl;
        return;
      }

      this.messageService.insertData({
        textArray: response['errors'],
        type: 'error',
      });

      this.btnlockedAsyn.next(false);
      this.projectFromOffer = false;
    });
  }

  private getNameToDisplay() {
    return this.offer['type'] === OFFER_TYPE_ATA ? 'ÄTA' : 'offerten';
  }

  public saveFromDraft(): void {
    this.updateTemplate(1);
  }

  private actionUpdate(vars): void {
    this.singleOfferService.changeUpdateRights(false);
    this.btnlockedAsyn.next(true);
    const name =
      vars['type'] === 'offer' ? 'companyOffers' : 'companyOfferTemplates';
    const refetchArr = getRefetchQuerieOf(name);
    const query = queries[name];

    this.mutationService
      .constructQueryAndExecuteMutation('Offer', 'update', false, vars, [
        refetchArr,
      ])
      .subscribe(executedData => {
        this.variables = {}; // reset global variables
        this.btnlockedAsyn.next(false);

        if (executedData['mutationSucceededAllArguments']) {
          const variables =
            vars['type'] === 'offer' ? { status: [vars['status']] } : {};
          const model = vars['type'] === 'offer' ? 'offers' : 'offerTemplates';
          const objToAppend = { ...executedData };
          delete objToAppend['mutationDetails'];
          delete objToAppend['mutationSucceededAllArguments'];

          this.addDataToApolloCache(query, variables, model, objToAppend);

          this.showInput = false;
          this.initForm();
          if (vars['type'] === 'template') {
            const el = document.getElementById('offerTemplateButtons');
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  // Delete Offer

  public actionDelete(): void {
    const dataToMutation = { id: Number(this.offer['id']) };

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'offer',
        'delete',
        false,
        dataToMutation
      )
      .subscribe(
        () => {
          this.btnlockedAsyn.next(false);
          executeMutationSub.unsubscribe();
          this.sideNavActions.emit({ name: 'reload' });
        },
        err => {
          console.log(err);
          this.btnlockedAsyn.next(false);
        }
      );
  }

  public autoSaveCheckboxClicked() {
    this.sortService.setSort(
      {
        field: this.autoSaveOptions.attribute,
        order: this.autoSaveOptions.ascDesc === 1 ? 0 : 1,
      },
      this.autoSaveOptions
    );

    this.autoSaveOptions.ascDesc = this.autoSaveOptions.ascDesc === 1 ? 0 : 1;
  }

  private startAutoSaveInterval() {
    this.autoSaveInterval = setInterval(() => {
      if (this.autoSaveOptions.ascDesc) {
        this.setVariablesAndEmitNavAction('update');
      }
    }, 120 * 1000);
  }

  private stopAutoSaveInterval() {
    clearInterval(this.autoSaveInterval);
  }

  private addDataToApolloCache(query, variables, model, newData) {
    try {
      const storedDataByQuery = this.angularApollo.getClient().readQuery({
        query,
        variables,
      });

      storedDataByQuery['company'][model]['totalCount'] =
        storedDataByQuery['company'][model]['totalCount'] + 1;

      storedDataByQuery['company'][model]['edges'].push({
        node: newData,
        __typename: 'OfferEdge',
      });

      this.angularApollo.getClient().writeQuery({
        query,
        variables,
        data: storedDataByQuery,
      });
    } catch {
      this.btnlockedAsyn.next(false);
    }
  }

  public actionShowDigitalSignDialog(): void {
    this.dialogService.data = { offerId: this.offer['id'] };
    this.dialogService.layout = 'wide';

    if (!this.useDigitalSignature) {
      this.dialogService.openComponent(
        FeaturePreviewDigitalSignatureComponent,
        'Förhandsgranska digital signering'
      );
      return;
    }

    this.dialogService.openComponent(
      OfferSignDialogComponent,
      'Skicka offert till digital signering'
    );
  }

  public ngOnDestroy() {
    this.offerUpdateSub && this.offerUpdateSub.unsubscribe();
    this.stopAutoSaveInterval();
  }
}
