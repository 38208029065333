import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { CompanySuperService } from './company.super.service';
import { ApolloErrorHandler } from 'app/shared/apollo';

@Injectable()
export class CompanyUserCostTypeService extends CompanySuperService {
  dataModel = 'userCostType';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list', showInactive = false) =>
    mode === 'labels'
      ? this.makeLabelsArray(data, showInactive)
      : this.makeObjectsForForms(data, showInactive);

  makeLabelsArray(data, showInactive) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    for (const i in dataArray) {
      if (showInactive || this.isActive(dataArray[i].node)) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.name,
          value: +dataArray[i].node.id,
          parentId: +dataArray[i].node.parentId || null,
          childCount: +dataArray[i].node.childCount || null,
          showInApp: dataArray[i].node.showInApp,
          accountingPlanAccountId:
            +dataArray[i].node.accountingPlanAccount?.id || null,
          reverseVatAccountingPlanAccountId:
            +dataArray[i].node.reverseVatAccountingPlanAccountId || null,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data, showInactive) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      if (showInactive || this.isActive(dataArray[i].node)) {
        const dataObject = {
          type: dataArray[i].node.type,
          cost: dataArray[i].node.cost,
          name: dataArray[i].node.name,
          internalCost: dataArray[i].node.internalCost,
          id: +dataArray[i].node.id,
          parentId: +dataArray[i].node.parentId || null,
          childCount: +dataArray[i].node.childCount || null,
          showInApp: dataArray[i].node.showInApp,
          accountingPlanAccountId:
            +dataArray[i].node.accountingPlanAccount?.id || null,
          reverseVatAccountingPlanAccountId:
            +dataArray[i].node.reverseVatAccountingPlanAccountId || null,
          active: dataArray[i].node.active,
        };
        dataObjectsForForms.push(dataObject);
      }
    }
    return dataObjectsForForms;
  }

  private isActive(node: any): boolean {
    // eslint-disable-next-line eqeqeq
    return node.active == 1;
  }
}
