<div
  [class.p-pl-3]="false && userCostType.parentId"
  [class.parent-separator]="
    showAdvancedUseCostTypes &&
    userCostType.parentId === null &&
    userCostType.type !== 'isWork'
  "
>
  <form
    class="p-grid"
    [formGroup]="componentMainForm"
    (keyup.enter)="actionUpdate()"
  >
    <div
      class="p-col-12 col-fixed p-as-center"
      style="width: 80px"
      *ngIf="userCostType.active"
    >
      <p-checkbox
        [binary]="true"
        formControlName="showInApp"
        [id]="componentPrefix + 'companyUserCostType_name_' + userCostType.id"
      ></p-checkbox>
    </div>
    <div
      class="p-col-12"
      [class.p-lg-2]="hasAccountingPlanFunction"
      [class.p-lg-4]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        formControlName="name"
        [disabled]="!userCostType.active"
        [id]="componentPrefix + 'companyUserCostType_name_' + userCostType.id"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-2]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        [disabled]="!userCostType.active"
        formControlName="cost"
        [id]="componentPrefix + 'companyUserCostType_cost_' + userCostType.id"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-2]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        [disabled]="!userCostType.active"
        formControlName="internalCost"
        [id]="
          componentPrefix +
          'companyUserCostType_internalCost_' +
          userCostType.id
        "
      />
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-2">
      <p-dropdown
        [filter]="true"
        [disabled]="!userCostType.active"
        [ngModel]="userCostType.accountingPlanAccountId"
        formControlName="accountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-2">
      <p-dropdown
        [filter]="true"
        [disabled]="!userCostType.active"
        [ngModel]="userCostType.reverseVatAccountingPlanAccountId"
        formControlName="reverseVatAccountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div class="p-col-12 p-lg-2 p-as-center" *ngIf="showAdvancedUseCostTypes">
      <p-dropdown
        [autoDisplayFirst]="false"
        [options]="parentTypes"
        optionLabel="name"
        optionValue="id"
        formControlName="parentId"
        placeholder="-"
        [id]="
          componentPrefix + 'companyUserCostType_parentId_' + userCostType.id
        "
      ></p-dropdown>
    </div>
    <div
      class="p-col-6 p-lg-1 p-d-flex p-ai-center"
      *ngIf="userCostType.active"
    >
      <div class="p-mr-2 p-order-1">
        <button
          pButton
          class="p-button-icon-danger"
          [disabled]="buttons.delete.disabled || userCostType.type === 'isWork'"
          (click)="confirmActionDelete()"
          icon="pi pi-trash"
        ></button>
      </div>
      <div class="p-mr-2 p-order-2">
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="buttons.update.disabled"
          (click)="actionUpdate()"
          icon="pi pi-save"
        ></button>
      </div>
    </div>
    <div
      class="p-col-6 p-lg-2 p-d-flex p-ai-center"
      *ngIf="!userCostType.active"
    >
      <button
        pButton
        [disabled]="buttons.update.disabled"
        (click)="actionActivate()"
        label="Aktivera"
      ></button>
    </div>
  </form>
</div>
