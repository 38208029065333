import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateDayMutationGQL } from 'app/day/day-form/graphql/day.mutation.generated';
import {
  FetchAttendanceTypesGQL,
  FetchAttendanceTypesQuery,
  FetchCompanyUsersCostTypesGQL,
  FetchCompanyUsersCostTypesQuery,
} from 'app/day/day-form/graphql/day.query.generated';
import {
  GetTodosGQL,
  TodoFragment,
} from 'app/project/project-detail/project-todo/graphql/project-todo.generated';
import { CompanyFunctionsService } from 'app/shared/company';
import { MessageService } from 'app/shared/message';
import { UserLocalStorageService } from 'app/shared/user';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { CompanyUserCostType, Day } from 'generated/types';
import { MenuItem } from 'primeng/api';
import { TreeTable } from 'primeng/treetable';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  first,
  Observable,
  Subject,
  Subscription,
  takeWhile,
} from 'rxjs';
import { FetchProjectQuery } from '../../graphql/project.generated';
import {
  DeleteDayGQL,
  ProjectDayFragment,
  SetNotarizedGQL,
  UpdateTextGQL,
  FetchTimeReportHeadersGQL,
  FetchTimeReportsForDateGQL,
  FetchSingleTimeReportHeaderGQL,
  FetchSingleTimeReportGQL,
  ProjectTimeReportHeaderFragment,
  FetchTimeReportsForBetweenDatesGQL,
  SetDayExtraGQL,
  RemoveTimeReportInvoiceConnectionGQL,
} from './graphql/day.generated';

type FullSummaryCalculations = {
  totalDays: number;
  hoursInvoiced: number;
  hoursNotInvoiced: number;
  milesInvoiced: number;
  milesNotInvoiced: number;
  subsistenceDays: number;
  subsistenceHalfDays: number;
  subsistenceNights: number;
};

type FieldsSummary = {
  hours: number;
  hoursToInvoice: number;
  privMile: number;
  mileToInvoice: number;
  mile: number;
};

type Column = {
  header: string;
  field: string;
  width?: string;
  icon?: string;
  tooltip?: string;
  editable?: boolean;
};

type DaySummary = {
  id: number;
  day: ProjectDayFragment;
  userCostType: CompanyUserCostType;
  attendanceType: {
    id?: number;
    code?: string;
    name?: string;
  };
  isFullDayEmpty: boolean;
  isFullDayInvoiced: boolean;
  parentId: number;
  isSaving?: boolean;
  editDisabled: boolean;
};

type UserCostType = {
  id: string;
  name?: string;
  active?: number;
};

type AttendanceType = {
  id?: number;
  code?: string;
  name?: string;
};

type TreeNode<T> = {
  data?: T;
  children?: TreeNode<T>[];
  leaf?: boolean;
  expanded?: boolean;
};

type TimeReport = {
  id: number;
  isHeader: boolean;
  date: string;
  doneWork: string;
  hours: number;
  hoursToInvoice: number;
  mile: number;
  privMile: number;
  mileToInvoice: number;
  extra: boolean;
  attendanceTypeId?: number;
  attendanceType?: AttendanceType;
  companyCostTypeId?: number;
  companyCostType?: UserCostType;
  isDayInvoiced: boolean;
  notarizedBy: string;
  editDisabled: boolean;
  parentId?: number;
  invoiceId?: number;
  todoId?: number;
};

@Component({
  selector: 'app-project-work-performed-lazy',
  templateUrl: './project-work-performed-lazy.component.html',
  styleUrls: ['./project-work-performed-lazy.component.scss'],
})
export class ProjectWorkPerformedLazyComponent implements OnChanges, OnDestroy {
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Input() public isExpanded = false;
  @Input() public isExtra = false;
  @Output() public dayUpdatedEvent = new EventEmitter<number>();

  @ViewChild('treeTable')
  private treeTable: TreeTable;

  private projectDataSubscription: Subscription;

  public days = new BehaviorSubject<DaySummary[]>([]);
  public timeReports: TreeNode<TimeReport>[] = [];
  private companyUsersCostTypesData = new BehaviorSubject<
    FetchCompanyUsersCostTypesQuery['company']['userCostTypes']
  >({});
  private userCostTypes: UserCostType[];
  private attendanceTypesData = new BehaviorSubject<
    FetchAttendanceTypesQuery['company']['dayAttendanceTypes']
  >({});
  private attendanceTypes: AttendanceType[];

  public areTimereportsExpanded = false;
  public loadingTimeReports = false;
  public isDayFormSidebarVisible = false;
  public projectId: number;
  public selectedDayEditId: number | null;
  public selectedDayEdit: Day | null;
  public showFullSummary = false;
  public actionMenus: MenuItem[];
  public fullCalculationSummary: FullSummaryCalculations;
  public fieldsSummary: FieldsSummary = {
    hours: 0,
    hoursToInvoice: 0,
    privMile: 0,
    mileToInvoice: 0,
    mile: 0,
  };
  public pageSize = 50;
  public totalCount = 0;
  private currentPagingEvent = { first: 0 };

  public columns: Column[] = [
    {
      header: 'Datum',
      field: 'date',
      width: '9rem',
    },
    {
      header: 'Utfört arbete',
      field: 'doneWork',
      editable: true,
    },
    {
      header: 'F. timmar',
      field: 'hoursToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. mil',
      field: 'mileToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];

  public extendedColumns: Column[] = [
    {
      header: 'Datum',
      field: 'date',
      width: '9rem',
    },
    {
      header: 'Utfört arbete',
      field: 'doneWork',
      editable: true,
    },
    {
      header: 'Timmar',
      field: 'hours',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. timmar',
      field: 'hoursToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'Priv. mil',
      field: 'privMile',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. mil',
      field: 'mileToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'Närvarotyp',
      field: 'overtimeHardCoded',
      width: '10rem',
      editable: true,
    },
    {
      header: 'Yrkestyp',
      field: 'companyCostTypeId',
      width: '10rem',
      editable: true,
    },
    {
      header: '',
      icon: 'pi pi-check',
      field: 'notarized',
      tooltip: 'Attesterad',
      width: '3.5rem',
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'todoId',
      width: '3rem',
      icon: 'pi pi-clock',
      tooltip: 'Arbetsmoment',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];
  private userFlags: UserFlags;
  public usePickOvertimeOnTimereport: boolean;
  public useUserCostType: boolean;
  public setMile: boolean;
  public setPrivMile: boolean;
  public useNotarized: boolean;
  public afterNotarizedNoUpdateAbility: boolean;

  public editing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userCosttypeDropdown: { label: string; value: number }[];
  public attendanceTypeDropdown: { label: string; value: string }[];
  public isEditHeader: boolean;
  private dayToUpdate: Subject<TimeReport> = new Subject();
  public todoMap: { [key: string]: TodoFragment } = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private deleteDayGQL: DeleteDayGQL,
    private setNotarizedGQL: SetNotarizedGQL,
    private fetchCompanyUsersCostTypesGQL: FetchCompanyUsersCostTypesGQL,
    private fetchAttendanceTypesGQL: FetchAttendanceTypesGQL,
    private userFlagsService: UserFlagsService,
    private userLocalStorageService: UserLocalStorageService,
    private companyFunctionService: CompanyFunctionsService,
    private updateTextService: UpdateTextGQL,
    private updateDayGQL: UpdateDayMutationGQL,
    private fetchTimeReportHeadersService: FetchTimeReportHeadersGQL,
    private fetchTimeReportsForDateService: FetchTimeReportsForDateGQL,
    private fetchSingleTimeReportHeaderService: FetchSingleTimeReportHeaderGQL,
    private fetchSingleTimeReportService: FetchSingleTimeReportGQL,
    private fetchTimeReportsBetweenDatesService: FetchTimeReportsForBetweenDatesGQL,
    private setDayExtraGQL: SetDayExtraGQL,
    private messageService: MessageService,
    private getTodosGQL: GetTodosGQL,
    private removeInvoiceConnectionService: RemoveTimeReportInvoiceConnectionGQL
  ) {
    this.activatedRoute.parent.params.subscribe(params => {
      this.projectId = params.id;
    });

    this.getTodosGQL
      .fetch({ projectId: this.projectId })
      .pipe(
        first(),
        map(res => res.data.project.todos.edges.map(e => e.node))
      )
      .subscribe(todos => {
        todos.forEach(todo => (this.todoMap[todo.id] = todo));
      });

    this.fetchAttendanceTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(result => {
        this.attendanceTypesData.next(result.data.company.dayAttendanceTypes);

        this.attendanceTypeDropdown =
          result.data.company.dayAttendanceTypes.edges
            .map(e => e.node)
            .map(ct => ({ label: ct.name, value: ct.id }));
      });

    this.fetchCompanyUsersCostTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(result => {
        this.companyUsersCostTypesData.next(result.data.company.userCostTypes);

        this.userCosttypeDropdown = result.data.company.userCostTypes.edges
          .map(e => e.node)
          .filter(ct => ct.active === 1)
          .map(ct => ({ label: ct.name, value: Number(ct.id) }));
        this.userCosttypeDropdown.unshift({
          label: 'Inget valt...',
          value: null,
        });
      });

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
        this.usePickOvertimeOnTimereport = flags.hasFlag(
          'usePickOvertimeOnTimereport'
        );
        this.useUserCostType = flags.hasFlag('useUserCostType');
        this.setMile = flags.hasFlag('setMile');
        this.setPrivMile = flags.hasFlag('setPrivMile');
        this.useNotarized = flags.hasFlag('useNotarized');
        this.afterNotarizedNoUpdateAbility = flags.hasFlag(
          'afterNotarizedNoUpdateAbility'
        );
      });

    this.dayToUpdate
      .pipe(debounceTime(1000))
      .subscribe(day => this.updateDay(day));
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (!('projectData' in changes)) {
      return;
    }

    this.projectDataSubscription?.unsubscribe();
    this.projectDataSubscription = this.projectData.subscribe(projectData => {
      this.fetchTimeReportHeaders();
      this.setSummaryData(projectData);
    });
  }

  public ngOnDestroy(): void {
    this.projectDataSubscription?.unsubscribe();
  }

  private getOpenedNodes(): number[] {
    const nodes = this.treeTable?.value as TreeNode<TimeReport>[];
    return nodes !== undefined
      ? nodes.filter(n => n.expanded).map(n => n.data.id)
      : [];
  }

  public fetchTimeReportHeaders($event?: { first: number }): void {
    const openedNodes = this.getOpenedNodes();

    this.currentPagingEvent = $event ?? this.currentPagingEvent;
    this.fetchTimeReportHeadersService
      .fetch({
        projectId: this.projectId,
        extra: this.isExtra,
        first: this.pageSize,
        offset: this.currentPagingEvent.first,
      })
      .pipe(first())
      .subscribe(timeReportsResult => {
        this.totalCount =
          timeReportsResult.data.project.timeReportHeaders.totalCount;
        this.timeReports =
          timeReportsResult.data.project.timeReportHeaders.edges.map(e => {
            const shouldExpand = openedNodes.includes(Number(e.node.id));
            const node = {
              data: {
                id: Number(e.node.id),
                isHeader: true,
                date: e.node.date,
                doneWork: e.node.message,
                hours: e.node.hours,
                hoursToInvoice: e.node.hoursToInvoice,
                mile: e.node.miles,
                privMile: e.node.milesPrivate,
                mileToInvoice: e.node.milesToInvoice,
                invoiceId: null,
                notarizedBy: '',
                isDayInvoiced: e.node.isFullyInvoiced,
                editDisabled: false,
                extra: e.node.extra,
              },
              children: [],
              leaf: false,
              expanded: shouldExpand,
            };

            if (shouldExpand) {
              this.loadTimeReportsForDate({ node });
            }

            return node;
          });
        setTimeout(() => {
          this.recheckOpenedNodes();
        });
      });
  }

  public loadTimeReportsForDate(event: any): void {
    if (event.node.children.length > 0) {
      this.recheckOpenedNodes();
      return;
    }
    const timeReportsResults = this.fetchTimeReportsForDateService
      .fetch({
        projectId: this.projectId,
        date: event.node.data.date,
        extra: this.isExtra ? 1 : 0,
      })
      .pipe(first());
    combineLatest({
      timeReportsResult: timeReportsResults,
      companyUsersCostTypesData: this.companyUsersCostTypesData,
      attendanceTypesData: this.attendanceTypesData,
    }).subscribe(
      ({
        timeReportsResult,
        companyUsersCostTypesData,
        attendanceTypesData,
      }) => {
        this.userCostTypes = companyUsersCostTypesData.edges?.map(
          ({ node }) => node
        );

        this.attendanceTypes = attendanceTypesData.edges?.map(({ node }) => {
          return {
            id: +node.id,
            code: node.code,
            name: node.name,
          };
        });

        const timeReports = timeReportsResult.data.project.days.edges
          .map(e => e.node)
          .filter(n => n.userId !== 0)
          .map(n => {
            return {
              data: this.parseServerTimeReport(n, Number(event.node.data.id)),
            };
          });
        event.node.children = timeReports;
        this.timeReports = [...this.timeReports];
        this.recheckOpenedNodes();
      }
    );
  }

  private setSummaryData(projectData: FetchProjectQuery['project']): void {
    this.fullCalculationSummary = this.isExtra
      ? {
          totalDays: projectData.totalDaysExtra,
          hoursInvoiced: projectData.hoursInvoicedExtra,
          hoursNotInvoiced: projectData.hoursNotInvoicedExtra,
          milesInvoiced: projectData.milesInvoicedExtra,
          milesNotInvoiced: projectData.milesNotInvoicedExtra,
          subsistenceDays: projectData.subsistenceDaysExtra,
          subsistenceHalfDays: projectData.subsistenceHalfDaysExtra,
          subsistenceNights: projectData.subsistenceNightsExtra,
        }
      : {
          totalDays: projectData.totalDays,
          hoursInvoiced: projectData.hoursInvoiced,
          hoursNotInvoiced: projectData.hoursNotInvoiced,
          milesInvoiced: projectData.milesInvoiced,
          milesNotInvoiced: projectData.milesNotInvoiced,
          subsistenceDays: projectData.subsistenceDays,
          subsistenceHalfDays: projectData.subsistenceHalfDays,
          subsistenceNights: projectData.subsistenceNights,
        };
    this.fieldsSummary = this.isExtra
      ? {
          hours: projectData.totalHoursExtra,
          hoursToInvoice:
            projectData.hoursInvoicedExtra + projectData.hoursNotInvoicedExtra,
          mile:
            projectData.milesInvoicedExtra + projectData.milesNotInvoicedExtra,
          privMile: projectData.milesPrivateExtra,
          mileToInvoice: projectData.milesNotInvoicedExtra,
        }
      : {
          hours: projectData.totalHours,
          hoursToInvoice:
            projectData.hoursInvoiced + projectData.hoursNotInvoiced,
          mile: projectData.milesInvoiced + projectData.milesNotInvoiced,
          privMile: projectData.milesPrivate,
          mileToInvoice:
            projectData.milesInvoiced + projectData.milesNotInvoiced,
        };
  }

  public recheckOpenedNodes(): void {
    const nodes = this.treeTable.value as TreeNode<TimeReport>[];
    this.areTimereportsExpanded = nodes.some(x => x.expanded);
  }

  public toggleAll(event: Event): void {
    const nodes = this.treeTable.value as TreeNode<TimeReport>[];
    const shouldExpand = !this.areTimereportsExpanded;

    if (shouldExpand) {
      const dates = nodes.map(n => n.data.date).sort();
      if (dates.length === 0) {
        return;
      }
      const minDate = dates[0];
      const maxDate = dates[dates.length - 1];
      this.loadingTimeReports = true;

      const timeReportsResults = this.fetchTimeReportsBetweenDatesService
        .fetch({
          projectId: this.projectId,
          extra: this.isExtra ? 1 : 0,
          fromDate: minDate,
          toDate: maxDate,
        })
        .pipe(first());
      combineLatest({
        timeReportsResult: timeReportsResults,
        companyUsersCostTypesData: this.companyUsersCostTypesData,
        attendanceTypesData: this.attendanceTypesData,
      }).subscribe(
        ({
          timeReportsResult,
          companyUsersCostTypesData,
          attendanceTypesData,
        }) => {
          this.userCostTypes = companyUsersCostTypesData.edges?.map(
            ({ node }) => node
          );

          this.attendanceTypes = attendanceTypesData.edges?.map(({ node }) => {
            return {
              id: +node.id,
              code: node.code,
              name: node.name,
            };
          });

          const timereports = timeReportsResult.data.project?.days?.edges
            ?.map(e => e.node)
            .filter((n: ProjectDayFragment) => n.userId !== 0);
          if (timereports === undefined || timereports.length === 0) {
            return;
          }

          const timeReportsByDate = Object.fromEntries(dates.map(d => [d, []]));

          timereports.forEach(item => {
            timeReportsByDate[item.date].push(item);
          });

          for (const node of nodes) {
            node.expanded = shouldExpand;
            const timereports = timeReportsByDate[node.data.date];
            node.children = timereports.map(t => ({
              data: this.parseServerTimeReport(t, node.data.id),
            }));
          }

          this.timeReports = [...this.timeReports];
          this.recheckOpenedNodes();
          this.loadingTimeReports = false;
        }
      );
    } else {
      for (const node of nodes) {
        node.expanded = shouldExpand;
      }
      this.timeReports = [...this.timeReports];
      this.recheckOpenedNodes();
    }
    event.preventDefault();
  }

  public showDayFormSidebar(isEditHeader = false): void {
    this.isDayFormSidebarVisible = !this.isDayFormSidebarVisible;
    this.isEditHeader = isEditHeader;
  }

  public onDayUpdatedEvent(dayId: number): void {
    this.isDayFormSidebarVisible = false;
    this.selectedDayEditId = null;
    this.selectedDayEdit = null;
    this.isEditHeader = false;
    this.refetchSingleTimeReport(dayId);
    this.dayUpdatedEvent.emit(dayId);
  }

  public onHideDayFormSidebar(): void {
    this.selectedDayEditId = null;
    this.selectedDayEdit = null;
  }

  public toggleShowFullSummary(): void {
    this.showFullSummary = !this.showFullSummary;
  }

  public generateActionsMenu(timeReport: TreeNode<TimeReport>): void {
    if (timeReport.data.isHeader) {
      this.generateActionsMenuForHeader(timeReport);
    } else {
      this.generateActionsMenuForTimeReport(timeReport);
    }
  }

  private generateActionsMenuForTimeReport(
    timeReport: TreeNode<TimeReport>
  ): void {
    this.actionMenus = [
      {
        label: `Redigera`,
        icon: 'pi pi-pencil',
        disabled: timeReport.data.editDisabled,
        command: () => {
          this.showDayFormSidebar(timeReport.data.isHeader);
        },
      },
      {
        label: timeReport.data.extra
          ? 'Flytta till normal'
          : 'Flytta till extra',
        icon: 'pi pi-plus',
        disabled: timeReport.data.editDisabled,
        command: () => {
          this.setDayExtraGQL
            .mutate({ id: timeReport.data.id, extra: !timeReport.data.extra })
            .pipe(first())
            .subscribe(() => {
              this.dayUpdatedEvent.emit(Number(timeReport.data.id));
            });
        },
      },
    ];
    if (this.canNotarize()) {
      this.actionMenus.push(
        timeReport.data.notarizedBy
          ? {
              label: 'Ta bort attestering',
              icon: 'pi pi-times',
              command: () => this.setNotarized(timeReport.data, false),
            }
          : {
              label: 'Attestera',
              icon: 'pi pi-check',
              command: () => this.setNotarized(timeReport.data, true),
            }
      );
    }
    if (this.userFlags.isSuperAdmin && timeReport.data.isDayInvoiced) {
      this.actionMenus.push({
        label: 'Ta bort fakturakoppling',
        icon: 'pi pi-times',
        command: () => this.removeInvoiceConnection(timeReport.data.id),
      });
    }
  }

  private removeInvoiceConnection(dayId: number) {
    this.removeInvoiceConnectionService
      .mutate({
        timeReportId: dayId,
      })
      .pipe(first())
      .subscribe(result => {
        if (
          result.data.removeTimeReportInvoiceConnectionMutation
            .mutationDetails[0].mutationSucceeded
        ) {
          this.dayUpdatedEvent.emit(
            Number(result.data.removeTimeReportInvoiceConnectionMutation.id)
          );
        }
      });
  }

  private canNotarize(): boolean {
    const isAdmin = this.userFlags.isAdmin;
    const isForeman = this.userFlags.isForeman;

    const foremanCanNotarize = this.companyFunctionService.companyFunctionIsSet(
      'advancedUserCanNotarizeTimereports'
    );
    const isforemanNotarizer = foremanCanNotarize && isForeman;

    const userCanNotarize = isAdmin || isforemanNotarizer;
    const companyCanNotarize = this.userFlags.hasFlag('useNotarized');

    return userCanNotarize && companyCanNotarize;
  }

  public generateActionsMenuForHeader(timeReport: TreeNode<TimeReport>): void {
    this.actionMenus = [
      {
        label: 'Redigera',
        icon: 'pi pi-pencil',
        command: () => {
          this.showDayFormSidebar(timeReport.data.isHeader);
        },
      },
      {
        label: 'Ta bort',
        icon: 'pi pi-trash',
        disabled: timeReport.children.length > 0,
        command: () => {
          this.deleteDayGQL
            .mutate({ deleteDay: { id: Number(timeReport.data.id) } })
            .pipe(first())
            .subscribe(() => {
              this.dayUpdatedEvent.emit(Number(timeReport.data.id));
              this.fetchTimeReportHeaders();
            });
        },
      },
    ];
  }

  private setNotarized(timeReport: TimeReport, notarized: boolean): void {
    this.setNotarizedGQL
      .mutate({
        day: {
          id: timeReport.id,
          notarized: notarized
            ? Number(this.userLocalStorageService.getMEUser().id)
            : 0,
        },
      })
      .pipe(first())
      .subscribe(() => {
        this.dayUpdatedEvent.emit(timeReport.id);
        this.refetchSingleTimeReport(timeReport.id);
      });
  }

  public showCreateNewDayForm(): void {
    this.selectedDayEdit = null;
    this.selectedDayEditId = null;

    this.showDayFormSidebar();
  }

  public getColumns(): Column[] {
    const columns = this.isExpanded ? this.extendedColumns : this.columns;
    const disabledColumns = [];

    if (!this.usePickOvertimeOnTimereport) {
      disabledColumns.push('attendanceTypeId');
    }
    if (!this.useUserCostType) {
      disabledColumns.push('companyCostTypeId');
    }
    if (!this.setMile) {
      disabledColumns.push('mileToInvoice');
    }
    if (!this.setMile || !this.setPrivMile) {
      disabledColumns.push('privMile');
    }
    if (!this.useNotarized) {
      disabledColumns.push('notarized');
    }
    return columns.filter(col => !disabledColumns.includes(col.field));
  }

  public getCompanyCostTypeName(costTypeId: number): BehaviorSubject<string> {
    const companyCostTypeNameSubject = new BehaviorSubject<string>('');

    this.companyUsersCostTypesData.pipe(first()).subscribe(result => {
      const companyCostType = result.edges
        ?.filter(({ node }) => Number(node.id) === costTypeId && node.active)
        .shift()?.node;

      if (companyCostType) {
        companyCostTypeNameSubject.next(companyCostType.name);
      }
    });

    return companyCostTypeNameSubject;
  }

  public getAttendanceTypeName(
    attendanceCode: string
  ): BehaviorSubject<string> {
    const attendanceTypeNameSubject = new BehaviorSubject<string>('');

    this.attendanceTypesData.pipe(first()).subscribe(result => {
      const attendanceType = result.edges
        ?.filter(({ node }) => node.code === attendanceCode)
        .shift()?.node;

      if (attendanceType) {
        attendanceTypeNameSubject.next(attendanceType.name);
      }
    });

    return attendanceTypeNameSubject;
  }

  public addTextToParent(text: string, parentId: number): void {
    const oldText = this.timeReports.find(t => t.data.id === parentId).data
      .doneWork;
    const newText = oldText + '. ' + text;
    this.updateTextService
      .mutate({ id: parentId, text: oldText ? newText : text })
      .pipe(first())
      .subscribe(() => {
        this.dayUpdatedEvent.emit(Number(parentId));
        this.refetchSingleTimeReport(Number(parentId));
      });
  }

  public setEditing(isEditing: boolean): void {
    this.editing.next(isEditing);
  }

  public actionUpdateDay(timeReport: TimeReport): void {
    this.dayToUpdate.next(timeReport);
  }

  private updateDay(timeReport: TimeReport): void {
    this.updateDayGQL
      .mutate({
        updateDay: {
          id: Number(timeReport.id),
          doneWork: timeReport.doneWork,
          hours: String(timeReport.hours),
          hoursToInvoice: String(timeReport.hoursToInvoice),
          mile: String(timeReport.mile),
          privMile: String(timeReport.privMile),
          mileToInvoice: String(timeReport.mileToInvoice),
          newCostTypeId: timeReport.companyCostTypeId,
          attendanceTypeId: timeReport.attendanceTypeId,
        },
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.dayTypeHyperionMutation
        );
        this.editing
          .pipe(
            debounceTime(100),
            takeWhile(e => e, true)
          )
          .subscribe(editing => {
            if (!editing) {
              this.dayUpdatedEvent.emit(Number(timeReport.id));
            }
          });
      });
  }

  private updateHeader(id: number, headerNode: TreeNode<TimeReport>) {
    this.fetchSingleTimeReportHeaderService
      .fetch({ projectId: this.projectId, id: `${id}`, date: null })
      .pipe(first())
      .subscribe(result => {
        const data = result.data.project.timeReportHeaders.edges.map(e =>
          this.parseServerTimeReportHeader(e.node)
        )[0];
        headerNode.data = data;
      });
  }

  private updateChild(id: number, headerNode: TreeNode<TimeReport>): void {
    this.fetchSingleTimeReportService
      .fetch({ projectId: this.projectId, id: id })
      .pipe(
        first(),
        map(res => res.data.project.days.edges.map(e => e.node))
      )
      .subscribe(days => {
        const data = days.map(e =>
          this.parseServerTimeReport(e, headerNode.data.id)
        )[0];
        if (headerNode) {
          let timeReport = headerNode.children.find(c => c.data.id === id);
          if (timeReport) {
            timeReport.data = data;
          } else {
            timeReport = {
              data: data,
              expanded: true,
            };
            headerNode.children.push(timeReport);
          }
          this.updateHeader(data.parentId, headerNode);
        } else {
          headerNode = this.timeReports.find(t => t.data.date === data.date);
          if (!headerNode) {
            this.fetchSingleTimeReportHeaderService
              .fetch({
                projectId: this.projectId,
                date: data.date,
              })
              .pipe(first())
              .subscribe(result => {
                const headerData =
                  result.data.project.timeReportHeaders.edges.map(e =>
                    this.parseServerTimeReportHeader(e.node)
                  )[0];
                this.timeReports.push({
                  data: headerData,
                  expanded: true,
                  children: [
                    {
                      data: data,
                    },
                  ],
                });
              });
          } else {
            headerNode.children.push({
              data: data,
              expanded: true,
            });
          }
        }
      });
  }

  private refetchSingleTimeReport(timeReportId: number): void {
    const timeReportHeaderNode = this.timeReports.find(
      t =>
        t.data.id === timeReportId ||
        t.children.find(c => c.data.id === timeReportId) !== undefined
    );

    const isHeaderUpdate = timeReportHeaderNode?.data.id === timeReportId;
    if (isHeaderUpdate) {
      this.updateHeader(timeReportId, timeReportHeaderNode);
    } else {
      this.updateChild(timeReportId, timeReportHeaderNode);
    }
  }

  public editDisabled(day: ProjectDayFragment): boolean {
    return (
      day.invoiceId !== null ||
      (this.afterNotarizedNoUpdateAbility && day.notarized !== 0)
    );
  }

  private parseServerTimeReportHeader(
    serverTimeReportHeader: ProjectTimeReportHeaderFragment
  ): TimeReport {
    return {
      id: Number(serverTimeReportHeader.id),
      isHeader: true,
      date: serverTimeReportHeader.date,
      doneWork: serverTimeReportHeader.message,
      hours: serverTimeReportHeader.hours,
      hoursToInvoice: serverTimeReportHeader.hoursToInvoice,
      mile: serverTimeReportHeader.miles,
      privMile: serverTimeReportHeader.milesPrivate,
      mileToInvoice: serverTimeReportHeader.milesToInvoice,
      notarizedBy: '',
      isDayInvoiced: serverTimeReportHeader.isFullyInvoiced,
      editDisabled: false,
      extra: null,
    };
  }

  private parseServerTimeReport(
    serverTimeReport: ProjectDayFragment,
    parentId: number
  ): TimeReport {
    const notarizedUserInitials = serverTimeReport.notarized
      ? this.getUserInitials({
          firstName: serverTimeReport.notarizedUser.firstName,
          lastName: serverTimeReport.notarizedUser.lastName,
        })
      : '';

    const userCostType = this.userCostTypes?.find(
      uct =>
        Number(uct.id) ===
        Number(serverTimeReport.costTypeHyperion?.companyCostTypeId)
    );

    return {
      id: Number(serverTimeReport.id),
      isHeader: false,
      date: `${serverTimeReport.user.firstName} ${serverTimeReport.user.lastName}`,
      extra: serverTimeReport.extra === 1,
      doneWork: serverTimeReport.doneWork,
      hours: serverTimeReport.hours,
      hoursToInvoice: serverTimeReport.hoursToInvoice,
      mile: serverTimeReport.mile,
      privMile: serverTimeReport.privMile,
      mileToInvoice: serverTimeReport.mileToInvoice,
      attendanceType: serverTimeReport.attendanceType
        ? {
            id: +serverTimeReport.attendanceType.id,
            code: serverTimeReport.attendanceType.code,
            name: serverTimeReport.attendanceType.name,
          }
        : null,
      companyCostTypeId: Number(userCostType.id),
      companyCostType: userCostType,
      notarizedBy: notarizedUserInitials,
      isDayInvoiced: serverTimeReport.invoiceId !== null,
      parentId: parentId,
      editDisabled: this.editDisabled(serverTimeReport),
      invoiceId: serverTimeReport.invoiceId,
      todoId:
        serverTimeReport.todoId ??
        serverTimeReport.todoRelation?.todo_id ??
        null,
    };
  }

  private getUserInitials({ firstName, lastName }): string {
    return (
      '' + this.returnFirstChar(firstName) + this.returnFirstChar(lastName)
    );
  }

  private returnFirstChar(value: string): string {
    return value ? value.charAt(0).toUpperCase() : '';
  }
}
