import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetDeletedAttendanceTypeMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  isDeleted?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SetDeletedAttendanceTypeMutation = { __typename?: 'HyperionSchemaMutation', dayAttendanceTypeTypeHyperionMutation?: { __typename?: 'DayAttendanceType', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, errors?: Array<string | null> | null, msg?: string | null } | null> | null } | null };

export type UpdateAttendanceTypeMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  showInApp?: Types.InputMaybe<Types.Scalars['Boolean']>;
  selectedCostTypes?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
}>;


export type UpdateAttendanceTypeMutation = { __typename?: 'HyperionSchemaMutation', dayAttendanceTypeTypeHyperionMutation?: { __typename?: 'DayAttendanceType', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, errors?: Array<string | null> | null, msg?: string | null } | null> | null } | null };

export type AddAttendanceTypeMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
  code?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddAttendanceTypeMutation = { __typename?: 'HyperionSchemaMutation', dayAttendanceTypeTypeHyperionMutation?: { __typename?: 'DayAttendanceType', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, errors?: Array<string | null> | null, msg?: string | null } | null> | null } | null };

export const SetDeletedAttendanceTypeDocument = gql`
    mutation setDeletedAttendanceType($id: Int, $isDeleted: Boolean) {
  dayAttendanceTypeTypeHyperionMutation(
    updateDayAttendanceType: {id: $id, isDeleted: $isDeleted}
  ) {
    mutationDetails {
      mutationSucceeded
      errors
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDeletedAttendanceTypeGQL extends Apollo.Mutation<SetDeletedAttendanceTypeMutation, SetDeletedAttendanceTypeMutationVariables> {
    document = SetDeletedAttendanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttendanceTypeDocument = gql`
    mutation updateAttendanceType($id: Int, $description: String, $name: String, $showInApp: Boolean, $selectedCostTypes: [Int]) {
  dayAttendanceTypeTypeHyperionMutation(
    updateDayAttendanceType: {id: $id, description: $description, name: $name, showInApp: $showInApp, selectedCostTypes: $selectedCostTypes}
  ) {
    mutationDetails {
      mutationSucceeded
      errors
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttendanceTypeGQL extends Apollo.Mutation<UpdateAttendanceTypeMutation, UpdateAttendanceTypeMutationVariables> {
    document = UpdateAttendanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAttendanceTypeDocument = gql`
    mutation addAttendanceType($name: String, $code: String) {
  dayAttendanceTypeTypeHyperionMutation(
    createDayAttendanceType: {code: $code, name: $name, showInApp: true}
  ) {
    mutationDetails {
      mutationSucceeded
      errors
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAttendanceTypeGQL extends Apollo.Mutation<AddAttendanceTypeMutation, AddAttendanceTypeMutationVariables> {
    document = AddAttendanceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }